import React from 'react';
import { Mutation } from 'react-apollo';
import { sharePostMutation } from '../../../graphql';
import Log from '../../../Log';
import userInThreadHeader from '../../helpers/userInThreadHeader';
import { convertToMarkdown, EditorState } from '../../UI/Editor';
import Modal from '../../UI/Modal/Modal';
import {
  EMPTY_SHARE_TO_FIELD,
  LONG_SHARE_COMMENT,
  SHARE_COMMENT_MAX_LENGTH
} from '../constants';
import { SelectedContactItem } from '../CreatePost.types';
import { CreateSharedPostModalContent } from './CreateSharedPostModalContent';

interface Props {
  post: any;
  postView: any;
  isSharedPostModalOpen: boolean;
  currentUserId: string;
  currentWorkspaceId: string;
  toggleShareModal(): void;
}

interface State {
  shareComment: any;
  selectedContacts: SelectedContactItem[];
  error: string;
}

class CreateSharedPost extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getEmptyState();
  }

  public getEmptyState = () => ({
    shareComment: EditorState.createEmpty(),
    selectedContacts: [],
    error: ''
  });

  public onChangeShareComment = (shareComment: string) => {
    this.setState({ shareComment }, this.validateShareComment);
  };

  public onCloseModal = () => {
    const { toggleShareModal } = this.props;
    toggleShareModal();

    this.setState(this.getEmptyState());
  };

  public onAddContact = (selectedContacts: any) => {
    const filteredContacts = selectedContacts.filter((item: any) => item.node);

    this.setState({
      selectedContacts: filteredContacts
    });
  };

  public onToggleContact = (e: any, contact: any) => {
    const { selectedContacts } = this.state;

    const filteredContacts = selectedContacts.filter(
      (item: any) => item.node.id !== contact.node.id
    );

    if (e.target.checked && selectedContacts.length < 10) {
      this.setState({
        selectedContacts: [...filteredContacts, contact]
      });
    } else {
      this.setState({
        selectedContacts: [...filteredContacts]
      });
    }
  };

  public onSubmit = (sharePost: any) => {
    if (!this.validate()) {
      return false;
    }

    const { post, currentWorkspaceId } = this.props;
    const { selectedContacts, shareComment } = this.state;

    const userIds = selectedContacts
      .filter((item: any) => item.node.__typename === 'User')
      .map((item: any) => item.node.id);
    const groupIds = selectedContacts
      .filter((item: any) => item.node.__typename === 'Group')
      .map((item: any) => item.node.id);
    const comment = convertToMarkdown(shareComment);

    return sharePost({
      variables: {
        workspaceId: currentWorkspaceId,
        userIds,
        groupIds,
        postId: post.id,
        shareComment: comment
      }
    })
      .then((res: any) => {
        const {
          data: {
            sharePost: { error }
          }
        } = res;

        if (error) {
          Log.graphQLError(error, 'CreateSharedPost');
        }

        this.onCloseModal();
      })
      .catch((err: any) => {
        Log.error(`sharePost: ${err}`);
      });
  };

  public validateShareComment = () => {
    const { shareComment } = this.state;

    const comment = convertToMarkdown(shareComment);

    if (comment.length > SHARE_COMMENT_MAX_LENGTH) {
      this.setState({
        error: LONG_SHARE_COMMENT
      });

      return false;
    }

    this.setState({
      error: ''
    });

    return true;
  };

  public validate = () => {
    const { selectedContacts } = this.state;

    if (selectedContacts.length === 0) {
      this.setState({
        error: EMPTY_SHARE_TO_FIELD
      });

      return false;
    }

    if (!this.validateShareComment()) {
      return false;
    }

    this.setState({
      error: ''
    });

    return true;
  };

  public render() {
    const { shareComment, selectedContacts, error } = this.state;
    const {
      post,
      postView,
      isSharedPostModalOpen,
      currentUserId,
      currentWorkspaceId
    } = this.props;

    const contactsException = post.commentThreads.edges.map(
      (threadItem: any) => {
        const { group } = threadItem && threadItem.node;
        let exceptionId = group && group.id;

        if (group && group.isInstantMessage) {
          // if isInstantMessage === true it means that this group is
          // a conversation between two users. one of them is current.
          // need to find another user
          const theadUser = userInThreadHeader(group, currentUserId);
          exceptionId = theadUser && theadUser.id;
        }

        return exceptionId;
      }
    );

    return (
      <Mutation mutation={sharePostMutation}>
        {(sharePost: any, data: any) => (
          <Modal
            isModalOpen={isSharedPostModalOpen}
            onBtnClose={this.onCloseModal}
            modalWidth="md"
            content={
              <CreateSharedPostModalContent
                shareComment={shareComment}
                currentWorkspaceId={currentWorkspaceId}
                selectedContacts={selectedContacts}
                postView={postView}
                error={error}
                isDisabled={data.loading}
                contactsException={contactsException}
                onAddContact={this.onAddContact}
                onToggleContact={this.onToggleContact}
                onChangeShareComment={this.onChangeShareComment}
                onSubmit={() => this.onSubmit(sharePost)}
              />
            }
          />
        )}
      </Mutation>
    );
  }
}

export { CreateSharedPost };
