import React from 'react';
import { Mutation } from 'react-apollo';
import { editPostMutation } from '../../../graphql';
import { Pencil } from '../../UI/Icons';
import Modal from '../../UI/Modal/Modal';
import CreationForm from '../Components/CreationForm';
// @ts-ignore
import styles from './editPost.module.scss';

interface Props {
  post: any;
  currentWorkspaceId: string;
  attachedFiles: any;
  attachedImages: any;
}

interface State {
  isModalOpen: boolean;
}

class EditPost extends React.Component<Props, State> {
  public state = {
    isModalOpen: false
  };

  public closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  public openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  public render() {
    const {
      post,
      currentWorkspaceId,
      attachedFiles,
      attachedImages
    } = this.props;
    const { isModalOpen } = this.state;

    const savedTags = post.tags.map((item: any) => item.tag);
    const savedImages = attachedImages.map((item: any) => ({
      fileId: item.fileId,
      name: item.name
    }));
    const savedFiles = attachedFiles.map((item: any) => ({
      fileId: item.fileId,
      name: item.name
    }));

    return (
      <React.Fragment>
        <button
          type="button"
          onClick={this.openModal}
          className={styles.openModalBtn}
        >
          <Pencil width="9px" />
          Edit
        </button>

        <Modal
          isModalOpen={isModalOpen}
          onBtnClose={this.closeModal}
          modalWidth="md"
          content={
            <Mutation mutation={editPostMutation}>
              {(submitPostFunc: any, submitData: any) => (
                <CreationForm
                  isEditMode={true}
                  currentWorkspaceId={currentWorkspaceId}
                  submitPostFunc={submitPostFunc}
                  submitData={submitData}
                  savedPostTitle={post.title}
                  savedPostDescription={post.rawDescription}
                  savedTags={savedTags}
                  savedImages={savedImages}
                  savedFiles={savedFiles}
                  postId={post.id}
                  closeModal={this.closeModal}
                />
              )}
            </Mutation>
          }
        />
      </React.Fragment>
    );
  }
}

export { EditPost };
