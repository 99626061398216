import gql from 'graphql-tag';
import { GroupFragment, GroupMemberFragment } from './fragments';

export const createGroupMutation = gql`
  mutation CreateGroup(
    $workspaceId: UUID!
    $userIds: [UUID]!
    $name: String!
    $topic: String!
    $purpose: String!
    $avatarId: UUID
    $groupType: GroupType
  ) {
    createGroup(
      createGroupRequest: {
        name: $name
        workspaceId: $workspaceId
        topic: $topic
        purpose: $purpose
        userIds: $userIds
        avatarId: $avatarId
        groupType: $groupType
      }
    ) {
      __typename
      error {
        __typename
        errorCode
        errorMessage
      }
    }
  }
`;

export const addUserToGroupMutation = gql`
  mutation AddUserToGroup(
    $groupId: UUID!
    $workspaceId: UUID!
    $userId: UUID!
  ) {
    addUserToGroup(
      addUserToGroupRequest: {
        groupId: $groupId
        workspaceId: $workspaceId
        userId: $userId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const removeUserFromGroupMutation = gql`
  mutation RemoveUserFromGroup(
    $groupId: UUID!
    $workspaceId: UUID!
    $userId: UUID!
  ) {
    removeUserFromGroup(
      removeUserFromGroupRequest: {
        groupId: $groupId
        workspaceId: $workspaceId
        userId: $userId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const getGroupsListQuery = gql`
  query Groups(
    $workspaceId: UUID!
    $after: String
    $groupFilterType: GroupFilterType!
    $groupNameFilter: GroupNameFilterInput
  ) {
    groups(
      first: 10
      after: $after
      workspaceId: $workspaceId
      groupCompositeFilter: {
        groupFilterType: $groupFilterType
        groupNameFilter: $groupNameFilter
      }
      sortOrder: ASC
    ) {
      __typename
      edges {
        node {
          ...GroupFragment
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${GroupFragment}
`;

export const getGroupQuery = gql`
  query Group(
    $workspaceId: UUID!
    $groupId: UUID!
    $membersAfter: String
    $nonMembersAfter: String
    $membersFilter: UserFilterInput
    $nonMembersFilter: UserFilterInput
  ) {
    group(workspaceId: $workspaceId, groupId: $groupId) {
      ...GroupFragment
      membersCount
      members(first: 10, after: $membersAfter, userFilter: $membersFilter) {
        edges {
          node {
            ...GroupMemberFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      nonMembers(
        first: 10
        after: $nonMembersAfter
        userFilter: $nonMembersFilter
      ) {
        edges {
          node {
            ...GroupMemberFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }

  ${GroupFragment}
  ${GroupMemberFragment}
`;

export const groupsSubscription = gql`
  subscription GroupsSubscription($workspaceId: UUID!) {
    groups(workspaceId: $workspaceId) {
      ... on Group {
        ...GroupFragment
      }
      ... on AddUserToGroup {
        groupId
        userId
      }
      ... on RemoveUserFromGroup {
        groupId
        userId
      }
    }
  }

  ${GroupFragment}
`;

export const groupMembershipSubscription = gql`
  subscription Notification($workspaceId: UUID!) {
    groups(workspaceId: $workspaceId) {
      actor {
        userId
      }
      group {
        groupId
      }
      user {
        userId
      }
      workspace {
        workspaceId
      }
    }
  }
`;

export const changeGroupAvatarMutation = gql`
  mutation changeGroupAvatar(
    $groupId: UUID!
    $fileId: UUID!
    $workspaceId: UUID!
  ) {
    changeGroupAvatar(
      changeGroupAvatarRequest: {
        groupId: $groupId
        fileId: $fileId
        workspaceId: $workspaceId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
