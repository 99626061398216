import ApolloClient from 'apollo-client';
import { debounce } from 'throttle-debounce';
import {
  groupToQuery,
  ratingToQuery,
  textToQuery,
  userToQuery
} from '../../Components/MainPage/feedQueryHelpers';
import {
  FILTER_BY_GROUP,
  FILTER_BY_RATING,
  FILTER_BY_TEXT,
  FILTER_BY_USER,
  POST_FILTER_TYPE_NAME,
  POST_FILTER_TYPES
} from '../../constants';
import { setLoadingState } from '../../graphql/local';
import Log from '../../Log';
import { FilterService, getFilters } from '../FilterService';

class FeedApi {
  private store: any = {};
  private client: any;
  private fetchFeed: any;

  public init(
    client?: ApolloClient<any>,
    fetchFeed?: (ctx: any) => Promise<any>
  ) {
    this.client = client;
    this.fetchFeed = fetchFeed;
  }

  public subscribe(id: string, cmp: string, cb: (v?: any) => any) {
    if (!this.store[id]) {
      this.store[id] = [{ cmp, cb }];
    } else {
      this.store[id].push({ cmp, cb });
    }
  }

  public unsubscribe(id: string, cmp: string) {
    if (this.store[id]) {
      this.store[id] = this.store[id].filter((item: any) => item.cmp !== cmp);
    }
  }

  public notify(id: string, v: any) {
    if (this.store[id]) {
      this.store[id].forEach((item: any) => item.cb(v));
    }
  }

  public openPost = (data: any) => {
    this.notify('SINGLE_POST_SELECTED', data);
  };

  public closePost = () => {
    this.notify('SINGLE_POST_SELECTED', null);
  };

  public onAllFeedClick = () => {
    this.resetFilters();
    this.setLoading(true);
    this.closePost();

    this.fetchFeed({
      variables: {
        after: '',
        postFilterType: POST_FILTER_TYPES.USER_FEED,
        ratingFilter: null,
        groupFilter: null,
        textSearchFilter: null,
        actorFilter: null
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        this.setLoading(false);

        return fetchMoreResult;
      }
    }).catch((err: string) => {
      this.setLoading(false);

      Log.error(
        `Error fetching the feed for a group: ${err}`,
        'MainPageContent'
      );
    });
  };

  public fetchFeedByRating = (rating: number) => {
    const filterArr = getFilters();

    this.setFilterByRating(rating);

    this.fetchFeedWithNewVariables({
      after: '',
      postFilterType: filterArr.postFilterState,
      ratingFilter: ratingToQuery(rating),
      textSearchFilter: textToQuery(filterArr.filterByTextState),
      groupFilter: groupToQuery(filterArr.filterByGroupState),
      actorFilter: userToQuery(filterArr.filterByUserState)
    });
  };

  public fetchFeedByText = (searchQuery: string, delay: boolean = false) => {
    this.setFilterByText(searchQuery);

    if (delay) {
      this.fetchFeedByTextWithDelay(searchQuery);
    } else {
      this.fetchFeedByTextNormal(searchQuery);
    }
  };

  public fetchFeedByUser = (userId: string) => {
    this.setFilterByUser(userId);

    this.fetchFeedWithNewVariables({
      after: '',
      postFilterType: POST_FILTER_TYPES.USER_FEED,
      ratingFilter: null,
      textSearchFilter: null,
      groupFilter: null,
      actorFilter: userToQuery(userId)
    });
  };

  public fetchFeedByGroup = (groupId: string, isMemberOfGroup: boolean) => {
    const { USER_FEED, PUBLIC_GROUP_POSTS } = POST_FILTER_TYPES;
    const postFilterType = isMemberOfGroup ? USER_FEED : PUBLIC_GROUP_POSTS;

    this.setFilterByGroup(groupId, postFilterType);
    this.notify('GROUP', groupId);

    this.fetchFeedWithNewVariables({
      after: '',
      postFilterType,
      ratingFilter: null,
      textSearchFilter: null,
      groupFilter: groupToQuery(groupId),
      actorFilter: null
    });
  };

  public fetchFeedWithNewVariables = async (variables: any) => {
    await this.setLoading(true);
    this.closePost();

    // @ts-ignore
    this.fetchFeed({
      variables,
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        this.setLoading(false);
        return fetchMoreResult;
      }
    }).catch((err: string) => {
      this.setLoading(false);
      Log.error(`Error fetching the feed: ${err}`, 'FeedApi');
    });
  };

  public setFilterByRating = (value: any) => {
    this.setFilterTokens({
      rating: value
    });
  };

  public setFilterByText = (value: any) => {
    this.setFilterTokens({
      text: value
    });
  };

  public setFilterByGroup = (value: any, postFilterType: string) => {
    this.setFilterTokens({
      postFilterType,
      group: value,
      user: null,
      rating: 0,
      text: ''
    });
  };

  public setFilterByUser = (value: any) => {
    this.setFilterTokens({
      postFilterType: POST_FILTER_TYPES.USER_FEED,
      group: null,
      user: value,
      rating: 0,
      text: ''
    });
  };

  public resetFilters = () => {
    this.setFilterTokens({
      postFilterType: POST_FILTER_TYPES.USER_FEED,
      group: null,
      user: null,
      rating: 0,
      text: ''
    });
  };

  public setLoading = async (bool: boolean) => {
    // @ts-ignore
    return await this.client.mutate({
      mutation: setLoadingState,
      variables: {
        isMainFeedLoaderActive: bool
      }
    });
  };

  private fetchFeedByTextNormal = (searchQuery: string) => {
    const filterArr = getFilters();

    this.fetchFeedWithNewVariables({
      after: '',
      postFilterType: filterArr.postFilterState,
      ratingFilter: ratingToQuery(filterArr.filterByRatingState),
      textSearchFilter: textToQuery(searchQuery),
      groupFilter: groupToQuery(filterArr.filterByGroupState),
      actorFilter: userToQuery(filterArr.filterByUserState)
    });
  };

  // tslint moves this function above fetchFeedByTextNormal()
  // tslint:disable-next-line
  private fetchFeedByTextWithDelay = debounce(500, this.fetchFeedByTextNormal);

  private setFilterTokens = (data: any) => {
    const { postFilterType, group, user, rating, text } = data;

    if (postFilterType) {
      FilterService.setFilter(POST_FILTER_TYPE_NAME, postFilterType);
    }
    if (group || group === null) {
      FilterService.setFilter(FILTER_BY_GROUP, group);
    }
    if (user || user === null) {
      FilterService.setFilter(FILTER_BY_USER, user);
    }
    if (rating || rating === 0) {
      FilterService.setFilter(FILTER_BY_RATING, rating);
    }
    if (text || text === '') {
      FilterService.setFilter(FILTER_BY_TEXT, text);
    }
  };
}

export default new FeedApi();
