import React, { PureComponent } from 'react';
// @ts-ignore
import bellImg from '../../../assets/img/bell.png';
import './NotificationInfo.scss';

interface Props {
  onToggleNotificationDropdown: () => void;
  notSeenNotificationAmount: number;
  isDropdownVisible: boolean;
}

class NotificationInfo extends PureComponent<Props, {}> {
  public render() {
    const {
      onToggleNotificationDropdown,
      notSeenNotificationAmount
    } = this.props;
    return (
      <div
        className="notifications-counter"
        onClick={onToggleNotificationDropdown}
      >
        <img className="bell-img" src={bellImg} alt="" />
        {notSeenNotificationAmount > 0 && (
          <div className="n-count">{notSeenNotificationAmount}</div>
        )}
      </div>
    );
  }
}
export { NotificationInfo };
