import React from 'react';
import { compose, Mutation, withQuery } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../../apollo/decorators';
import { changeUserAvatar, getCurrentUser } from '../../../../graphql';
import { Modal } from '../../../UI';
// @ts-ignore
import styles from './changeUserAvatar.module.scss';
import ChangeUserAvatarView from './ChangeUserAvatarView';

interface Props {
  // gql
  workspaceId: string;
  user: {
    id: string;
    avatar: string;
  };
  refetchUser(): void;
}

interface State {
  isModalOpen: boolean;
}

class ChangeUserAvatar extends React.Component<Props, State> {
  public state = {
    isModalOpen: false
  };

  public render() {
    const { isModalOpen } = this.state;
    const { user } = this.props;

    return (
      <React.Fragment>
        <button type="button" onClick={this.toggleModal}>
          Change avatar
        </button>
        <Modal
          isModalOpen={isModalOpen}
          onBtnClose={this.toggleModal}
          modalWidth="sm"
        >
          <div className={styles.modalContent}>
            <p className={styles.title}>Your avatar</p>

            <Mutation mutation={changeUserAvatar}>
              {(changeAvatar: any, data: any) => (
                <ChangeUserAvatarView
                  avatar={user.avatar}
                  loading={data.loading}
                  onSubmitAvatar={(fileId: string) =>
                    this.onSubmitAvatar(changeAvatar, fileId)
                  }
                  onSubmitAvatarSuccess={this.onSubmitAvatarSuccess}
                />
              )}
            </Mutation>
          </div>
        </Modal>
      </React.Fragment>
    );
  }

  private toggleModal = () => {
    const { isModalOpen } = this.state;

    this.setState({
      isModalOpen: !isModalOpen
    });
  };

  private onSubmitAvatar = (changeAvatar: any, fileId: string) => {
    const { workspaceId, user } = this.props;

    return changeAvatar({
      variables: {
        userId: user.id,
        fileId,
        workspaceId
      }
    });
  };

  private onSubmitAvatarSuccess = () => {
    const { refetchUser } = this.props;

    refetchUser();
    this.toggleModal();
  };
}

export default compose(
  withWorkspaceAndUser,
  withQuery(getCurrentUser, {
    options: (props: any) => ({
      variables: {
        workspaceId: props.workspaceId
      }
    }),
    props: ({ data }: any) => ({
      user: data.currentUser,
      refetchUser: data.refetch
    })
  })
)(ChangeUserAvatar);
