import React from 'react';
import { IAction } from '../../CreatePost.types';
import { CreatingActionsView } from './CreatingActionsView';

interface Props {
  isActionBtnDisabled: boolean;
  position?: 'top' | 'left';
  toggleButton: any;
  onAddAction(action: IAction): void;
}

interface State {
  fields: {
    [key: string]: string;
  };
  isFormVisible: boolean;
}

class CreatingActions extends React.Component<Props, State> {
  public wrapperRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      fields: {
        url: '',
        label: ''
      },
      isFormVisible: false
    };

    this.wrapperRef = React.createRef();
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const { fields, isFormVisible } = this.state;
    const { position = 'top', isActionBtnDisabled, toggleButton } = this.props;

    return (
      <CreatingActionsView
        fields={fields}
        wrapperRef={this.wrapperRef}
        position={position}
        isFormVisible={isFormVisible}
        onChange={this.onChange}
        onAdd={this.onAdd}
        toggleButton={toggleButton({
          onClick: this.toggleForm,
          disabled: isActionBtnDisabled
        })}
      />
    );
  }

  private handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.hideForm();
    }
  };

  private toggleForm = () => {
    this.setState(prevState => ({
      isFormVisible: !prevState.isFormVisible
    }));
  };

  private hideForm = () => {
    this.setState({
      isFormVisible: false
    });
  };

  private onChange = (e: any) => {
    const { value, name } = e.target;

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value
      }
    }));
  };

  private clearInputs = () => {
    this.setState({
      fields: {
        label: '',
        url: ''
      }
    });
  };

  private onAdd = () => {
    const {
      fields: { url, label }
    } = this.state;
    const { onAddAction } = this.props;

    if (!url || !label) {
      return null;
    }

    this.clearInputs();
    this.hideForm();

    onAddAction({ label, url });
  };
}

export { CreatingActions };
