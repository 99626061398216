import React from 'react';

const Emoji = ({ width = '13px' }: { width?: string }) => (
  <svg
    width={width}
    viewBox="0 0 13 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1358.000000, -1873.000000)">
        <g transform="translate(1263.000000, 1866.000000)">
          <g transform="translate(95.000000, 7.000000)">
            <path
              d="M6.5,13 C2.91014913,13 0,10.0898509 0,6.5 C0,2.91014913 2.91014913,0 6.5,0 C10.0898509,0 13,2.91014913 13,6.5 C13,10.0898509 10.0898509,13 6.5,13 Z M4.33333333,6.19047619 C5.01711445,6.19047619 5.57142857,5.63616207 5.57142857,4.95238095 C5.57142857,4.26859983 5.01711445,3.71428571 4.33333333,3.71428571 C3.64955221,3.71428571 3.0952381,4.26859983 3.0952381,4.95238095 C3.0952381,5.63616207 3.64955221,6.19047619 4.33333333,6.19047619 Z M9.28571429,6.19047619 C9.9694954,6.19047619 10.5238095,5.63616207 10.5238095,4.95238095 C10.5238095,4.26859983 9.9694954,3.71428571 9.28571429,3.71428571 C8.60193317,3.71428571 8.04761905,4.26859983 8.04761905,4.95238095 C8.04761905,5.63616207 8.60193317,6.19047619 9.28571429,6.19047619 Z"
              fill="currentColor"
            />
            <path
              d="M3,8 L9.92911114,8 C9.68649631,9.69614707 8.22779153,11 6.46455557,11 C4.70131962,11 3.24261483,9.69614707 3,8 Z"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { Emoji };
