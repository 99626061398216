import React from 'react';
import { Mutation } from 'react-apollo';
import { editCommentMutation } from '../../../../graphql';

import Log from '../../../../Log';
import { Loader } from '../../../UI';
import {
  ContentState,
  convertToMarkdown,
  EditorState
} from '../../../UI/Editor/index';
import { COMMENT_MAX_LENGTH, LONG_COMMENT } from '../constants';
import CommentEditingFormView from './CommentEditingFormView';

interface Props {
  currentWorkspaceId: string;
  threadId: string;
  commentId: string;
  previousComment: string;
  currentUserId: string;
  mentions: Array<{}>;
  fetchMentions(): void;
  hideEditForm(): null;
}

interface State {
  commentText: any;
  error: string;
}

class CommentEditingForm extends React.Component<Props, State> {
  public static defaultProps = {
    currentWorkspaceId: ''
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      commentText: EditorState.createWithContent(
        ContentState.createFromText(props.previousComment)
      ),
      error: ''
    };
  }

  public setCommentState = (commentText: any) => {
    this.setState({
      commentText
    });

    this.validate();
  };

  public validate = () => {
    const { commentText } = this.state;

    const comment = convertToMarkdown(commentText);

    if (comment.length > COMMENT_MAX_LENGTH) {
      this.setState({
        error: LONG_COMMENT
      });

      return false;
    }

    if (!comment.trim()) {
      this.setState({
        error: ''
      });

      return null;
    }

    this.setState({
      error: ''
    });

    return true;
  };

  public onSubmit = (editCommentOnPost: any, loading: boolean) => {
    const { commentText } = this.state;

    if (!this.validate()) {
      return null;
    }

    if (loading) {
      return null;
    }

    const {
      threadId,
      currentWorkspaceId,
      commentId,
      currentUserId,
      hideEditForm
    } = this.props;

    const comment = convertToMarkdown(commentText);

    return editCommentOnPost({
      variables: {
        comment,
        workspaceId: currentWorkspaceId,
        commentThreadId: threadId,
        commentId,
        userId: currentUserId
      }
    })
      .then(() => {
        hideEditForm();
      })
      .catch((err: any) => {
        Log.error(`Error while editing comment: ${err}`);
      });
  };

  public render() {
    const { hideEditForm, mentions, fetchMentions } = this.props;

    const { commentText, error } = this.state;

    return (
      <Mutation mutation={editCommentMutation}>
        {(editCommentOnPost: any, data: any) => (
          <CommentEditingFormView
            // tslint:disable-next-line
            onSubmit={() => this.onSubmit(editCommentOnPost, data.loading)}
            commentText={commentText}
            setCommentState={this.setCommentState}
            loading={data.loading}
            hideEditForm={hideEditForm}
            hasCommentText={!!convertToMarkdown(commentText).trim()}
            error={error}
            mentions={mentions}
            fetchMentions={fetchMentions}
          />
        )}
      </Mutation>
    );
  }
}

export { CommentEditingForm };
