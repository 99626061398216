import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withApollo, withQuery, compose } from 'react-apollo';
import { pathOr } from 'ramda';
import { throttle } from 'throttle-debounce';
import { CommentForm } from './CommentForm';
import './Comments.scss';
import {
  commentsQuery,
  commentsSubscription,
  getGroupQuery
} from '../../../graphql';
import Log from '../../../Log';
import CommentsList from './CommentsList/index';
import { gatherCommentsByUserIdAndTime } from './utills';
import {
  withNetworkStateQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import hereIcon from '../../../assets/img/hereIcon.svg';

const hereMention = {
  name: 'here',
  userName: 'here',
  avatar: hereIcon,
  description: 'Notify everyone in this conversation'
};

class Comments extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisibleAllComments: false,
      mentions: []
    };

    this.fetchMentions = throttle(800, this.fetchMentions);
  }

  componentDidUpdate(prevProps) {
    const { isOnline, refetch } = this.props;

    if (isOnline && prevProps.isOnline !== isOnline) {
      refetch();
    }
  }

  showComments = e => {
    e.stopPropagation();
    const { isVisibleAllComments } = this.state;
    this.setState({
      isVisibleAllComments: !isVisibleAllComments
    });
  };

  fetchMoreComments = (fetchMore, endCursor) => {
    fetchMore({
      variables: {
        after: endCursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          !fetchMoreResult.comments ||
          !fetchMoreResult.comments.edges ||
          !fetchMoreResult.comments.pageInfo
        ) {
          return prev;
        }

        return {
          comments: {
            edges: [...prev.comments.edges, ...fetchMoreResult.comments.edges],
            pageInfo: {
              ...fetchMoreResult.comments.pageInfo
            },
            __typename: prev.comments.__typename
          }
        };
      }
    }).catch(err => {
      Log.error(err, 'comments');
    });

    return null;
  };

  subscribeToMoreComments = subscribeToMore => {
    const {
      threadId,
      postId,
      showErrorOnSubscription,
      workspaceId
    } = this.props;

    subscribeToMore({
      document: commentsSubscription,
      variables: {
        workspaceId,
        postId,
        commentThreadId: threadId
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.comments) {
          return prev;
        }

        if (subscriptionData.data.comments.__typename === 'DeletedComment') {
          const commentsListAfterDeleting = prev.comments.edges.filter(
            item =>
              item &&
              item.node &&
              item.node.id !== subscriptionData.data.comments.id
          );

          return {
            comments: {
              ...prev.comments,
              edges: commentsListAfterDeleting
            }
          };
        }

        if (
          subscriptionData.data.comments.__typename ===
          'CommentReactionsUpdatedSubscription'
        ) {
          const reactionCommentId = subscriptionData.data.comments.id;
          const commentReactions = subscriptionData.data.comments.reactions;

          const newEdgesArr = prev.comments.edges.map(item => {
            if (item.node.id === reactionCommentId) {
              return {
                ...item,
                node: {
                  ...item.node,
                  reactions: commentReactions
                }
              };
            }
            return item;
          });

          return {
            ...prev,
            comments: {
              ...prev.comments,
              edges: newEdgesArr
            }
          };
        }

        const currentComment =
          prev &&
          prev.comments &&
          prev.comments.edges &&
          prev.comments.edges.find(item => {
            if (!item || !item.node || !item.node.id) {
              return null;
            }

            return item.node.id === subscriptionData.data.comments.id;
          });

        if (currentComment) {
          currentComment.node = {
            ...subscriptionData.data.comments,
            editedAt: subscriptionData.data.comments.editedAt || null
          };

          return prev;
        }

        return {
          comments: {
            edges: [
              {
                node: {
                  ...subscriptionData.data.comments,
                  editedAt: subscriptionData.data.comments.editedAt || null
                },
                __typename: 'CommentEdge'
              },
              ...prev.comments.edges
            ],
            pageInfo: prev.comments.pageInfo,
            __typename: prev.comments.__typename
          }
        };
      },
      onError: err => {
        showErrorOnSubscription();
        Log.error(`Error retrieving subscription: ${err}`, 'Comments');
      }
    });
  };

  fetchMentions = value => {
    const { client, groupId, workspaceId } = this.props;

    client
      .query({
        query: getGroupQuery,
        variables: {
          workspaceId,
          groupId,
          membersFilter: {
            nameFilter: {
              searchQuery: value
            }
          }
        }
      })
      .then(res => {
        const members = pathOr([], ['data', 'group', 'members', 'edges'], res);

        const mentions = members.map(item => ({
          name: item.node.login,
          userName: item.node.name,
          avatar: item.node.avatar
        }));

        this.setState({
          mentions: [...mentions, hereMention]
        });
      })
      .catch(err => {
        Log.error(err, 'Group in Comments');
      });
  };

  render() {
    const {
      threadId,
      loading,
      comments,
      fetchMore,
      subscribeToMore,
      workspaceId,
      userId
    } = this.props;
    const { isVisibleAllComments, mentions } = this.state;
    // console.log(workspaceId, userId);

    const pageInfo = comments && comments.pageInfo;
    const _comments = (comments && comments.edges) || [];

    if (!pageInfo) {
      return null;
    }

    const mappedComments = gatherCommentsByUserIdAndTime(_comments, 5);

    return (
      <div className="Comments">
        <CommentsList
          showComments={this.showComments}
          isVisibleAllComments={isVisibleAllComments}
          comments={mappedComments}
          fetchMoreComments={() =>
            this.fetchMoreComments(fetchMore, pageInfo.endCursor)
          }
          loading={loading}
          subscribeToMoreComments={() =>
            this.subscribeToMoreComments(subscribeToMore)
          }
          hasNextPage={pageInfo.hasNextPage}
          threadId={threadId}
          currentWorkspaceId={workspaceId}
          currentUserId={userId}
          mentions={mentions}
          fetchMentions={this.fetchMentions}
        />
        <CommentForm
          threadId={threadId}
          currentWorkspaceId={workspaceId}
          mentions={mentions}
          fetchMentions={this.fetchMentions}
        />
      </div>
    );
  }
}

Comments.propTypes = {
  threadId: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  showErrorOnSubscription: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired
};

export default compose(
  withApollo,
  withWorkspaceAndUser,
  withNetworkStateQuery,
  withQuery(commentsQuery, {
    options: ({ workspaceId, threadId }) => ({
      variables: {
        workspaceId,
        commentThreadId: threadId
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network'
    }),
    skip: props => !props.workspaceId && !props.threadId,
    props: ({ data }) => {
      const { loading, comments, fetchMore, refetch, subscribeToMore } = data;
      return { loading, comments, fetchMore, refetch, subscribeToMore };
    }
  })
)(Comments);
