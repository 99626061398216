import React from 'react';

const MentionItem = mentionProps => {
  return (
    <span className={mentionProps.className}>@{mentionProps.mention.name}</span>
  );
};

// eslint-disable-next-line
export { MentionItem };
