import CardsFeed from './CardsFeed';
import CardPopup from './CardPopup';
import { Comments, Comment, CommentForm } from './Comments';
import { CompressedCard } from './CompressedCard';
import { ExpandedCard } from './ExpandedCard';
import { IndexCard } from './IndexCard';
import { SharedCard } from './SharedCard';
// import { StarRating } from './StarRating';

export default CardsFeed;
export {
  CardsFeed,
  CardPopup,
  Comments,
  Comment,
  CommentForm,
  CompressedCard,
  ExpandedCard,
  IndexCard,
  SharedCard
  // StarRating
};
