import React from 'react';
import { FeedApi, getFilters } from '../../../services';

class FilterByText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchView: false
    };
  }

  onSearchView = () => {
    const { searchView } = this.state;
    this.setState({
      searchView: !searchView
    });
  };

  onChangeInput = e => {
    this.searchPhrase(e.target.value, true);
  };

  onClearSearch = () => {
    this.searchPhrase('', false);
  };

  searchPhrase = (searchQuery, delay) => {
    this.forceUpdate();
    FeedApi.fetchFeedByText(searchQuery, delay);
  };

  render() {
    const { searchView } = this.state;
    const { filterByTextState: value } = getFilters();

    return (
      <div className="header-search-wrapper">
        <div
          className={searchView ? 'header-search expanded' : 'header-search'}
        >
          <input
            type="text"
            placeholder="Search"
            className="channels-search header-search-input"
            value={value}
            onChange={this.onChangeInput}
            onBlur={this.onSearchView}
            onFocus={this.onSearchView}
            maxLength={80}
          />

          {value ? (
            <span
              onClick={this.onClearSearch}
              className="search-img search-img-clear"
            />
          ) : (
            <span className="search-img search-img-find" />
          )}
        </div>
      </div>
    );
  }
}

export default FilterByText;
