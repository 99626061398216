import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';

import classNames from 'classnames';
import { getCurrentUser } from '../../../graphql';
import Logout from './Logout/index';
import { ChangeUserAvatar } from './ChangeUserAvatar';
import { InnerInviteToWorkspace } from '../../InviteUserToWorkspace';
import styles from './navigation.module.scss';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavHidden: true
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onNavigationToggle = () => {
    const { isNavHidden } = this.state;
    this.setState({
      isNavHidden: !isNavHidden
    });
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        isNavHidden: true
      });
    }
  };

  render() {
    const {
      data: { currentUser }
    } = this.props;

    const { isNavHidden } = this.state;

    const boxClassName = classNames(styles.listBox, {
      [styles.hidden]: isNavHidden
    });

    return (
      <div ref={this.wrapperRef} className={styles.wrapper}>
        <img
          src={currentUser.avatar}
          alt=""
          className={styles.avatar}
          onClick={this.onNavigationToggle}
        />

        <div className={boxClassName}>
          <ul className={styles.list}>
            <li>
              <ChangeUserAvatar />
            </li>
            <li>
              <InnerInviteToWorkspace />
            </li>
            <li>
              <Logout />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  // eslint-disable-next-line
  currentWorkspaceId: PropTypes.string.isRequired
};

const withCurrentUser = graphql(getCurrentUser, {
  options: props => {
    return {
      variables: {
        workspaceId: props.currentWorkspaceId
      }
    };
  }
});
export default withCurrentUser(Navigation);
