// Post View

import gql from 'graphql-tag';
import { POST_VIEW } from '../../../constants';
import { FilterService } from '../../../services/FilterService';
import { getPostView as getStoredPostView } from '../../../services/FilterService';

const isCardsViewExpanded = getStoredPostView();

export interface IPostView {
  cardsView: {
    isCardsViewExpanded: boolean;
    __typename: string;
  };
}

export const postViewDefaults = {
  cardsView: {
    isCardsViewExpanded,
    __typename: 'CardsViewState'
  }
};

export const getPostView = gql`
  query getPostView {
    cardsView @client {
      isCardsViewExpanded
    }
  }
`;

export const setPostView = gql`
  mutation setPostView($isCardsViewExpanded: Boolean!) {
    postView(isCardsViewExpanded: $isCardsViewExpanded) @client
  }
`;

export const postView = (_: any, variables: any, { cache }: any) => {
  cache.writeData({
    data: {
      cardsView: {
        isCardsViewExpanded: variables.isCardsViewExpanded,
        __typename: 'CardsViewState'
      }
    }
  });
  setTimeout(() => {
    FilterService.setFilter(POST_VIEW, variables.isCardsViewExpanded);
  }, 5);
  return null;
};
