import React from 'react';
import { withQuery } from 'react-apollo';
import { getWorkspaceAndUser } from '../../../graphql/local';
import { ApiPath } from '../../../services/ApiPath';
import { getIdentityTokenAsync } from '../../../utils/getIdentityTokenAsync';

interface IDataValue {
  workspaceId: string;
}

interface IImageProps {
  fileId: string;
}

interface IImageState {
  src: string;
}

class Image extends React.Component<IImageProps & IDataValue, IImageState> {
  public state = {
    src: ''
  };
  private isMountedComp: boolean = false;

  public async componentDidMount() {
    this.isMountedComp = true;
    const { workspaceId, fileId } = this.props;

    if (workspaceId && fileId) {
      this.setImageSrc();
    }
  }

  public async setImageSrc() {
    const { workspaceId, fileId } = this.props;
    const identityToken = await getIdentityTokenAsync();
    this.setState({
      src: `${
        ApiPath.url
      }/v1/workspaces/${workspaceId}/files/${fileId}?token=${identityToken}`
    });
  }

  public componentWillUpdate(nextProps: IImageProps) {
    const { fileId } = this.props;
    if (this.isMountedComp && fileId !== nextProps.fileId) {
      this.setImageSrc();
      return true;
    }
    return false;
  }

  public componentWillUnmount(): void {
    this.isMountedComp = false;
  }

  public render() {
    const { src } = this.state;

    if (src) {
      return <img src={src} alt="" />;
    }
    return null;
  }
}

export default withQuery<any, IDataValue, any, any>(getWorkspaceAndUser, {
  props: ({ data }) => ({
    workspaceId: (data && data.workspaceId) || null
  })
})(Image);
