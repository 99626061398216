import React from 'react';
import { IMAGE_EXTENSIONS } from '../../../../constants';
import { LinkIcon } from '../../../UI';
import DropZone from '../../../UI/DropZone';
import { IMG } from '../../constants';
import { CreatingActions } from '../ProposedActions';
// @ts-ignore
import styles from './actionButtons.module.scss';

interface Props {
  isEditMode: boolean;
  isActionBtnDisabled: boolean;
  onDropAccepted(files: any): void;
  onAddAction(action: any): void;
  openFileDialog(): void;
}

function ActionButtons(props: Props) {
  const {
    isEditMode,
    onDropAccepted,
    onAddAction,
    isActionBtnDisabled,
    openFileDialog
  } = props;

  const imageExtensions = IMAGE_EXTENSIONS.map(ext => `.${ext}`).join(',');

  return (
    <div className={styles.box}>
      <button
        type="button"
        className={styles.attachFileBtn}
        onClick={openFileDialog}
      />

      {!isEditMode && (
        <CreatingActions
          isActionBtnDisabled={isActionBtnDisabled}
          onAddAction={onAddAction}
          toggleButton={(buttonProps: any) => (
            <button type="button" className={styles.actionBtn} {...buttonProps}>
              <LinkIcon width="18px" />
            </button>
          )}
        />
      )}

      <DropZone
        onDropAccepted={onDropAccepted}
        config={{
          multiple: true,
          noDrag: true,
          accept: imageExtensions
        }}
      >
        <button type="button" className={styles.attachImageBtn} />
      </DropZone>
    </div>
  );
}

export { ActionButtons };
