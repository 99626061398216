import React from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import Comment from '../Comment/index';

class CommentsList extends React.Component {
  componentDidMount() {
    const { subscribeToMoreComments } = this.props;

    subscribeToMoreComments();
  }

  showLatestWithAvatar = (comments, count) => {
    return comments
      .slice(-count)
      .map((item, index) => (index === 0 ? { ...item, showUser: true } : item));
  };

  render() {
    const {
      threadId,
      currentWorkspaceId,
      showComments,
      isVisibleAllComments,
      comments,
      fetchMoreComments,
      loading,
      hasNextPage,
      currentUserId,
      mentions,
      fetchMentions
    } = this.props;

    if (!comments) {
      return null;
    }
    const commentsRaw = comments.reverse();

    const allComments =
      (isVisibleAllComments || commentsRaw.length < 4
        ? commentsRaw
        : this.showLatestWithAvatar(commentsRaw, 3)) || [];

    return (
      <React.Fragment>
        {comments.length > 3 && (
          <button className="show-comments-btn" onClick={showComments}>
            {isVisibleAllComments ? `Hide comments` : `Other comments`}
          </button>
        )}

        {allComments.length > 0 && (
          <div
            className={
              !isVisibleAllComments
                ? 'all-ic-comments'
                : 'all-ic-comments all-ic-comments-full'
            }
          >
            {allComments.map(comment => (
              <Comment
                key={comment.id}
                comment={comment}
                threadId={threadId}
                currentWorkspaceId={currentWorkspaceId}
                currentUserId={currentUserId}
                mentions={mentions}
                fetchMentions={fetchMentions}
              />
            ))}

            {hasNextPage && !loading && isVisibleAllComments && (
              <Waypoint onEnter={fetchMoreComments} />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

CommentsList.propTypes = {
  threadId: PropTypes.string.isRequired,
  currentWorkspaceId: PropTypes.string.isRequired,
  showComments: PropTypes.func.isRequired,
  isVisibleAllComments: PropTypes.bool.isRequired,
  // eslint-disable-next-line
  comments: PropTypes.array.isRequired,
  fetchMoreComments: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  currentUserId: PropTypes.string.isRequired,
  mentions: PropTypes.array.isRequired,
  fetchMentions: PropTypes.func.isRequired
};

export default CommentsList;
