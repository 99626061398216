import gql from 'graphql-tag';
import { getFilters as getStoredFilters } from '../../../services/FilterService';

const {
  filterByGroupState,
  filterByUserState,
  filterByRatingState,
  filterByTextState
} = getStoredFilters();

export interface IFilters {
  filters: any;
}

export const filtersDefaults = {
  filters: {
    __typename: 'Filters',
    actorFilter: filterByUserState,
    groupFilter: filterByGroupState,
    ratingFilter: filterByRatingState,
    textSearchFilter: filterByTextState
  }
};

export const getFilters = gql`
  query GetFilters {
    filters @client {
      actorFilter
      groupFilter
      ratingFilter
      textSearchFilter
    }
  }
`;

export const setFilters = gql`
  mutation SetFilters(
    $actorFilter: ID
    $groupFilter: ID
    $ratingFilter: Int
    $textSearchFilter: String
  ) {
    filters(
      actorFilter: $actorFilter
      groupFilter: $groupFilter
      ratingFilter: $ratingFilter
      textSearchFilter: $textSearchFilter
    ) @client {
      actorFilter
      groupFilter
      ratingFilter
      textSearchFilter
    }
  }
`;

export const filters = (
  _: any,
  { actorFilter, groupFilter, ratingFilter, textSearchFilter }: any,
  { cache }: any
) => {
  const data = {
    filters: {
      __typename: 'Filters',
      actorFilter,
      groupFilter,
      ratingFilter,
      textSearchFilter
    }
  };
  cache.writeData({ data });
  return null;
};
