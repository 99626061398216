import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, Mutation } from 'react-apollo';
import GroupAddMembers from './GroupAddMembers';
import Modal from '../../UI/Modal/Modal';
import './AddGroup.scss';
import { createGroupMutation, getCurrentUser } from '../../../graphql';
import Log from '../../../Log';
import AddAvatarView from './AddAvatarView';
import uploadFile from '../../helpers/uploadFile';

class AddGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorCreateGroup: '',
      showNextScreen: false,
      groupName: '',
      addedMembersIds: [props.currentUser.id],
      file: null,
      avatarPreview: null,
      savedImgInfo: {},
      isFileValid: true,
      errorUploadFile: false,
      fileLoading: false,
      isGroupPublic: true
    };
  }

  onCloseModal = () => {
    const { toggleModal } = this.props;

    toggleModal();
    this.clearState();
  };

  clearState = () => {
    const { currentUser } = this.props;

    this.setState({
      errorCreateGroup: '',
      showNextScreen: false,
      addedMembersIds: [currentUser.id],
      groupName: '',
      file: null,
      avatarPreview: null,
      savedImgInfo: {},
      isFileValid: true,
      errorUploadFile: false,
      isGroupPublic: true
    });
  };

  showNextScreen = () => {
    const { showNextScreen } = this.state;
    this.setState({
      errorCreateGroup: '',
      showNextScreen: !showNextScreen
    });
  };

  onInputChange = e => {
    this.setState({
      groupName: e.target.value
    });
  };

  onChangeGroupType = e => {
    this.setState({
      isGroupPublic: e.target.checked
    });
  };

  onCheckMember = (e, id) => {
    const { addedMembersIds } = this.state;

    const membersIds = addedMembersIds.filter(item => item !== id);

    if (e.target.checked) {
      this.setState({
        addedMembersIds: [...membersIds, id]
      });
    } else {
      this.setState({
        addedMembersIds: [...membersIds]
      });
    }
  };

  onDropAccepted = validFiles => {
    const file = validFiles[0];
    const avatarPreview = URL.createObjectURL(file);

    this.setState({
      avatarPreview,
      file,
      isFileValid: true
    });
  };

  onDropRejected = () => {
    this.setState({
      isFileValid: false
    });
  };

  onCrop = cropper => {
    if (!cropper) {
      return null;
    }

    const { file } = this.state;
    const { name, type } = file;

    const canvas = cropper.getCroppedCanvas();

    const onBlobReadyCallback = blob => {
      this.setState({
        savedImgInfo: {
          name,
          blob
        }
      });
    };

    canvas.toBlob(onBlobReadyCallback, type);

    return null;
  };

  onUploadFileSuccess = (res, addGroupMutation) => {
    this.setState({
      fileLoading: false
    });

    const fileId = res[0] && res[0].file_id;

    if (!fileId) {
      return this.setState({
        errorUploadFile: true
      });
    }

    this.createGroup(addGroupMutation, fileId);

    return null;
  };

  onUploadFileError = err => {
    this.setState({
      errorUploadFile: true,
      fileLoading: false
    });

    Log.error(err, 'uploadAvatar');
  };

  createGroup = (addGroupMutation, avatarId) => {
    const { addedMembersIds, groupName, isGroupPublic } = this.state;
    const { currentWorkspaceId } = this.props;

    return addGroupMutation({
      variables: {
        workspaceId: currentWorkspaceId,
        userIds: addedMembersIds,
        name: groupName.trim(),
        topic: '',
        purpose: '',
        avatarId,
        groupType: isGroupPublic ? 'PUBLIC' : 'PRIVATE'
      }
    })
      .then(res => {
        Log.trace(res, 'AddGroup res');

        if (
          res.data &&
          res.data.createGroup &&
          res.data.createGroup.error &&
          res.data.createGroup.error.errorMessage
        ) {
          this.setState({
            errorCreateGroup: res.data.createGroup.error.errorMessage
          });
        } else {
          this.onCloseModal();
        }
      })
      .catch(err => {
        Log.error(err, 'AddGroup err');
      });
  };

  onSubmit = (e, addGroupMutation) => {
    e.preventDefault();

    const {
      addedMembersIds,
      groupName,
      savedImgInfo: { name, blob }
    } = this.state;
    const { currentWorkspaceId } = this.props;

    if (addedMembersIds.length === 0 || groupName.trim().length === 0) {
      return null;
    }

    if (!name) {
      this.createGroup(addGroupMutation, null);

      return null;
    }

    this.setState({
      fileLoading: true
    });

    const formData = new FormData();
    formData.append('file', blob, name);

    uploadFile(
      currentWorkspaceId,
      formData,
      res => this.onUploadFileSuccess(res, addGroupMutation),
      this.onUploadFileError
    );

    return null;
  };

  render() {
    const {
      showNextScreen,
      groupName,
      addedMembersIds,
      errorCreateGroup,
      avatarPreview,
      isFileValid,
      errorUploadFile,
      fileLoading,
      isGroupPublic
    } = this.state;

    const {
      currentUser,
      isCreateGroupModalShown,
      currentWorkspaceId
    } = this.props;

    return (
      <Mutation mutation={createGroupMutation}>
        {(addGroup, data) => (
          <React.Fragment>
            <Modal
              isModalOpen={isCreateGroupModalShown}
              onRequestClose={this.onCloseModal}
            >
              <div className="new-group-modal-wrapper">
                {!showNextScreen ? (
                  <div className="new-group-name-block">
                    <h2>Create a Team</h2>
                    <p className="new-group-description">
                      This is the best way to share news with friends and
                      colleagues
                    </p>
                    <AddAvatarView
                      onDropAccepted={this.onDropAccepted}
                      onDropRejected={this.onDropRejected}
                      onCrop={this.onCrop}
                      avatarPreview={avatarPreview}
                      isFileValid={isFileValid}
                    />
                    <input
                      type="text"
                      placeholder="Name of your team"
                      className="new-group-name-input"
                      onChange={this.onInputChange}
                      value={groupName}
                      maxLength={22}
                    />
                    <button
                      type="button"
                      className="new-group-name-btn"
                      onClick={this.showNextScreen}
                      disabled={!groupName.trim()}
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <GroupAddMembers
                    showNextScreen={this.showNextScreen}
                    onCheckMember={this.onCheckMember}
                    createGroupLoading={data.loading}
                    memberIds={addedMembersIds}
                    currentUserId={currentUser.id}
                    currentWorkspaceId={currentWorkspaceId}
                    onSubmit={e => this.onSubmit(e, addGroup)}
                    fileLoading={fileLoading}
                    isGroupPublic={isGroupPublic}
                    onChangeGroupType={this.onChangeGroupType}
                  />
                )}
                {!!errorCreateGroup && (
                  <div className="cntr error">{errorCreateGroup}</div>
                )}
                {errorUploadFile && (
                  <div className="error cntr pb10">Error on upload</div>
                )}
                {data.error && <div className="error pb10">Error</div>}
              </div>
            </Modal>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

AddGroup.propTypes = {
  isCreateGroupModalShown: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  currentWorkspaceId: PropTypes.string.isRequired
};

const withCurrentUser = graphql(getCurrentUser, {
  options: props => {
    return {
      variables: {
        workspaceId: props.currentWorkspaceId
      }
    };
  },
  props: ({ data }) => {
    const { currentUser } = data;
    return { currentUser };
  }
});
export default withCurrentUser(AddGroup);
