function ratingToQuery(rating) {
  if (rating) {
    return {
      ratingGreaterThanOrEqualTo: rating
    };
  }

  return null;
}

function textToQuery(text) {
  const searchQuery = text.trim().replace(/^#/, '');

  if (searchQuery) {
    return {
      searchQuery
    };
  }

  return null;
}

function groupToQuery(groupId) {
  if (groupId) {
    return {
      groupId
    };
  }
  return null;
}

function userToQuery(userId) {
  if (userId) {
    return {
      actorId: userId,
      actorType: 'USER'
    };
  }
  return null;
}

export { groupToQuery, userToQuery, ratingToQuery, textToQuery };
