import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';

export default function TextareaField(props) {
  return <Textarea {...props} />;
}

TextareaField.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number
};

TextareaField.defaultProps = {
  name: '',
  onChange: () => null,
  value: '',
  className: '',
  type: 'text',
  placeholder: '',
  minRows: 3,
  maxRows: 6,
  maxLength: null
};
