import React from 'react';
import PropTypes from 'prop-types';
import GroupItem from './GroupItem';
import ItemView from '../ItemView';
import { Loader } from '../../UI';
import JoinGroupModal from '../../JoinGroupModal';
import styles from './groups.module.scss';
import { FeedApi } from '../../../services';

class Groups extends React.Component {
  state = {
    currentGroup: null
  };

  setGroupData = currentGroup => {
    this.setState({
      currentGroup
    });
  };

  fetchFeedForGroup = (groupId, isMemberOfGroup) => {
    const { setActiveId } = this.props;
    setActiveId(groupId || '');
    FeedApi.fetchFeedByGroup(groupId, isMemberOfGroup);
  };

  fetchAllFeed = () => {
    const { setActiveId } = this.props;
    setActiveId('');
    FeedApi.onAllFeedClick();
  };

  onSelectGroup = group => {
    this.fetchFeedForGroup(group.node.id, group.node.isMemberOfGroup);
    this.setGroupData(group.node);
  };

  render() {
    const {
      activeId,
      loadingGroups,
      workspaceId,
      isSearchMode,
      groups,
      foundGroups
    } = this.props;
    const { currentGroup } = this.state;

    const isModalOpen =
      currentGroup &&
      (activeId === currentGroup.id && !currentGroup.isMemberOfGroup);

    const userGroups = isSearchMode ? foundGroups.own : groups;
    const publicGroups = foundGroups.public;

    return (
      <React.Fragment>
        {!isSearchMode && (
          <div onClick={this.fetchAllFeed}>
            <ItemView
              item={{
                id: 'all',
                name: 'All teams'
              }}
              isAllFeed
              isActive={activeId === ''}
            />
          </div>
        )}

        {userGroups.length > 0 && (
          <React.Fragment>
            {isSearchMode && <div className={styles.caption}>Your teams</div>}

            {userGroups.map(group => {
              if (!group.node) {
                return null;
              }

              return (
                <GroupItem
                  key={group.node.id}
                  group={group.node}
                  onSelectGroup={() => {
                    this.onSelectGroup(group);
                  }}
                  isActive={activeId === group.node.id}
                  workspaceId={workspaceId}
                />
              );
            })}
          </React.Fragment>
        )}

        {publicGroups.length > 0 && (
          <React.Fragment>
            <div className={styles.caption}>Other teams</div>

            {publicGroups.map(group => {
              if (!group.node) {
                return null;
              }

              return (
                <GroupItem
                  key={group.node.id}
                  group={group.node}
                  onSelectGroup={() => this.onSelectGroup(group)}
                  isActive={activeId === group.node.id}
                  workspaceId={workspaceId}
                />
              );
            })}
          </React.Fragment>
        )}

        {loadingGroups && (
          <div className="cntr">
            <Loader width="50px" />
          </div>
        )}

        {isModalOpen && <JoinGroupModal group={currentGroup} />}
      </React.Fragment>
    );
  }
}

Groups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  foundGroups: PropTypes.shape({
    own: PropTypes.arrayOf(PropTypes.shape({})),
    public: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  loadingGroups: PropTypes.bool,
  activeId: PropTypes.string.isRequired,
  setActiveId: PropTypes.func.isRequired,
  workspaceId: PropTypes.string.isRequired,
  isSearchMode: PropTypes.bool.isRequired
};

Groups.defaultProps = {
  groups: [],
  foundGroups: {
    own: [],
    public: []
  },
  loadingGroups: false
};

export default Groups;
