import React from 'react';
import { withRouter } from 'react-router-dom';
// @ts-ignore
import workspaceFrame from '../../assets/img/workspace_frame3.png';
import { ButtonPrimary } from '../UI';
// @ts-ignore
import styles from '../UI/styles/externalPages.module.scss';

interface RouterProps {
  history: any;
}

const WelcomeComponent = ({ history }: RouterProps) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.containerCol}>
        <div className={styles.content}>
          <p className={styles.title}>Welcome to Buj!</p>
          <p className={styles.welcomeDescription}>
            From daily conversations to business discussions and teams event
            announcements: everything is in Buj. Get work done with the best
            collaboration hub for your team and data.
          </p>
          <div className={styles.welcomeBtnBox}>
            <ButtonPrimary onClick={() => history.push('/')}>Go!</ButtonPrimary>
          </div>
        </div>
      </div>

      <div className={styles.containerCol}>
        <div className={styles.imageBox}>
          <img src={workspaceFrame} alt="" />
        </div>
      </div>
    </div>
  </div>
);

const Welcome = withRouter(WelcomeComponent);

export { Welcome };
