import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';

import {
  // Editor,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil
} from 'draft-js';

import Editor from 'draft-js-plugins-editor';
import 'draft-js-mention-plugin/lib/plugin.css';
import { createMention, MentionEntryItem } from './Mention';

import InlineToolbar from './InlineToolbar';
import './styles.scss';
import './Mention/mentionsStyles.css';

const customStyleMap = {
  HIGHLIGHT: {
    backgroundColor: 'palegreen'
  }
};

const getSelectionRange = () => {
  const selection = window.getSelection();
  if (selection.rangeCount === 0) return null;
  return selection.getRangeAt(0);
};

const getSelectionCoords = () => {
  // const getSelectionCoords = selectionRange => {
  // const editorBounds = document
  //   .getElementById('editor-container')
  //   .getBoundingClientRect();
  // const rangeBounds = selectionRange.getBoundingClientRect();
  // const rangeWidth = rangeBounds.right - rangeBounds.left;
  // // 107px is width of inline toolbar
  // const offsetLeft =
  //   rangeBounds.left - editorBounds.left + rangeWidth / 2 - 107 / 2;
  // // 42px is height of inline toolbar
  // const offsetTop = rangeBounds.top - editorBounds.top - 42;
  return { offsetLeft: 0, offsetTop: -40 };
};

export default class DraftEditor extends Component {
  static propTypes = {
    // eslint-disable-next-line
    editorState: PropTypes.any.isRequired,
    setEditorState: PropTypes.func.isRequired,
    fieldClassName: PropTypes.string,
    placeholder: PropTypes.string,
    isToolbar: PropTypes.bool,
    onEnterFunc: PropTypes.func,
    mentions: PropTypes.array,
    fetchMentions: PropTypes.func
  };

  static defaultProps = {
    // editorState: EditorState.createEmpty(),
    fieldClassName: '',
    placeholder: '',
    isToolbar: true,
    onEnterFunc: null,
    mentions: [],
    fetchMentions: () => {}
  };

  constructor(props) {
    super(props);

    const { mentions } = props;

    this.state = {
      inlineToolbar: { show: false }
    };

    this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.myKeyBindingFn = this.myKeyBindingFn.bind(this);

    this.editorRef = React.createRef();

    this.mentionPlugin = createMention(mentions);
  }

  onChange(editorState) {
    const { setEditorState } = this.props;

    if (!editorState.getSelection().isCollapsed()) {
      const selectionRange = getSelectionRange();

      if (!selectionRange) {
        this.setState({ inlineToolbar: { show: false } });

        return;
      }

      const selectionCoords = getSelectionCoords(selectionRange);

      this.setState({
        inlineToolbar: {
          show: true,
          position: {
            top: selectionCoords.offsetTop,
            left: selectionCoords.offsetLeft
          }
        }
      });
    } else {
      this.setState({ inlineToolbar: { show: false } });
    }

    setEditorState(editorState);
  }

  onSearchChange = ({ value }) => {
    const { fetchMentions } = this.props;

    fetchMentions(value);
  };

  myKeyBindingFn(e) {
    const { onEnterFunc } = this.props;
    const { hasCommandModifier } = KeyBindingUtil;

    // enter
    if (e.keyCode === 13 && !hasCommandModifier(e) && onEnterFunc) {
      return 'myeditor-enter';
    }

    // cmd/ctrl + enter
    // if (e.keyCode === 13 && hasCommandModifier(e)) {
    //   return 'myeditor-cmd-enter';
    // }

    return getDefaultKeyBinding(e);
  }

  toggleInlineStyle(inlineStyle) {
    const { editorState } = this.props;

    this.onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  }

  // logMarkup() {
  //   const markup = converter(this.state.editorState.getCurrentContent());
  //
  //   console.log('markup ==> ', markup);
  // }

  handleKeyCommand(command) {
    const { editorState, onEnterFunc } = this.props;
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (command === 'myeditor-enter') {
      onEnterFunc();

      const editorRefCurrent = this.editorRef.current;

      editorRefCurrent.blur();

      setTimeout(editorRefCurrent.focus, 500);

      return 'handled';
    }

    if (newState) {
      this.onChange(newState);

      return 'handled';
    }

    return 'not-handled';
  }

  handleReturn(e) {
    const { editorState } = this.props;

    if (isSoftNewlineEvent(e)) {
      this.onChange(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }

    return 'not-handled';
  }

  render() {
    const { inlineToolbar } = this.state;
    const {
      editorState,
      fieldClassName,
      placeholder,
      isToolbar,
      mentions
    } = this.props;

    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];

    return (
      <div className="text-editor">
        {isToolbar && inlineToolbar.show && (
          <InlineToolbar
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
            position={inlineToolbar.position}
          />
        )}

        <div className={fieldClassName}>
          <Editor
            editorState={editorState}
            onChange={this.onChange}
            handleKeyCommand={this.handleKeyCommand}
            customStyleMap={customStyleMap}
            handleReturn={this.handleReturn}
            placeholder={placeholder}
            keyBindingFn={this.myKeyBindingFn}
            ref={this.editorRef}
            plugins={plugins}
          />
          <MentionSuggestions
            onSearchChange={this.onSearchChange}
            suggestions={mentions}
            entryComponent={MentionEntryItem}
          />
        </div>
      </div>
    );
  }
}
