import { compose, withMutation, withQuery } from 'react-apollo';
import {
  withNetworkStateQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import {
  notificationsCount,
  notificationsMutation,
  notificationsQuery
} from '../../graphql';

const withNotifications = compose(
  withWorkspaceAndUser,
  withQuery(notificationsQuery, {
    options: ({ workspaceId }: any) => ({
      variables: {
        workspaceId
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      notifications: data.notifications,
      notificationsLoading: data.loading,
      notificationsError: data.error,
      notificationsUpdate: data.updateQuery,
      notificationsMore: data.fetchMore,
      notificationsRefetch: data.refetch,
      notificationsSubscribe: data.subscribeToMore,
      workspaceId: data.variables.workspaceId
    }),
    skip: props => !props.workspaceId
  }),
  withQuery(notificationsCount, {
    options: ({ workspaceId }: any) => ({
      variables: {
        workspaceId
      }
    }),
    props: ({ data }: any) => ({
      notificationsCount: Number(data.numberOfNotifications),
      notificationsCountRefetch: data.refetch
    }),
    skip: props => !props.workspaceId
  }),
  withMutation(notificationsMutation),
  withNetworkStateQuery
);

export { withNotifications };
