import React from 'react';
import PageHeader from '../../Components/PageHeader';
import { VerifyUser } from '../../Components/VerifyUser';
// @ts-ignore
import styles from '../pages.module.scss';

const VerifyUserPage = () => (
  <div className={styles.wrapper}>
    <PageHeader />

    <div className={styles.containerFlex}>
      <VerifyUser />
    </div>
  </div>
);

export { VerifyUserPage };
