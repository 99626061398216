import React from 'react';
import { Plus } from '../../../UI/Icons';
// @ts-ignore
import styles from './plusBtn.module.scss';

interface Props {
  disabled?: boolean;
  onClick?(): void;
}

const PlusBtn = ({ disabled = false, onClick = () => null }: Props) => (
  <button
    type="button"
    className={styles.btn}
    disabled={disabled}
    onClick={onClick}
  >
    <Plus width="16px" />
  </button>
);

export { PlusBtn };
