import React from 'react';
import Dropzone from 'react-dropzone';
import { getErrorText } from '../../../../services';
import TextStylingInfo from '../../../TextStylingInfo';
import Editor from '../../../UI/Editor';
import TextareaField from '../../../UI/Textarea/TextareaField';
import { IAction, IErrors, SelectedContactItem } from '../../CreatePost.types';
import ActionButtons from '../ActionButtons';
import { ActiveDrag } from '../ActiveDrag';
import PostIn from '../PostIn';
import PostModalFooter from '../PostModalFooter';
import PostModalHeader from '../PostModalHeader';
import PostTags from '../PostTags';
import { ProposedActions } from '../ProposedActions';
import UploadedFiles from '../UploadedFiles';
import UploadedImages from '../UploadedImages';
// @ts-ignore
import styles from './creationForm.module.scss';

interface Props {
  isEditMode: boolean;
  postTitle: string;
  postDescription: string;
  postDescriptionLength: number;
  currentWorkspaceId: string;
  selectedContacts: SelectedContactItem[];
  uploadedFiles: any;
  uploadedImages: any;
  loading: boolean;
  tags: string[];
  actions: IAction[];
  errors: IErrors;
  onChangeTitle(e: any): void;
  onChangeDescription(val: any): void;
  onSubmit(): void;
  onAddContact(contacts: any): void;
  onToggleContact(e: any, val: any): void;
  onDropAccepted(files: any): void;
  onRemoveFile(fileName: string, fileType?: string): void;
  onAddTag(tags: any): void;
  onAddAction(action: IAction): void;
  onRemoveAction(action: IAction): void;
}

function CreationFormMainScreen(props: Props) {
  const {
    isEditMode,
    postTitle,
    onChangeTitle,
    postDescription,
    postDescriptionLength,
    onChangeDescription,
    onSubmit,
    currentWorkspaceId,
    selectedContacts,
    onAddContact,
    onToggleContact,
    uploadedFiles,
    uploadedImages,
    loading,
    onDropAccepted,
    onRemoveFile,
    tags,
    onAddTag,
    actions,
    onAddAction,
    onRemoveAction,
    errors
  } = props;

  const modalTitle = isEditMode ? 'Edit Post' : 'Create new post';
  const isActionBtnDisabled = actions.length === 10;

  return (
    <Dropzone
      onDrop={() => {}}
      onDropAccepted={onDropAccepted}
      onDropRejected={() => {}}
      multiple={true}
      noClick={true}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        open: openFileDialog
      }) => (
        <div {...getRootProps()} className={styles.mainScreenWrapper}>
          <input {...getInputProps()} />

          {isDragActive && <ActiveDrag />}
          <div className={styles.modalContent}>
            <PostModalHeader title={modalTitle} />
            <hr />
            {!isEditMode && (
              <React.Fragment>
                <PostIn
                  currentWorkspaceId={currentWorkspaceId}
                  selectedContacts={selectedContacts}
                  onAddContact={onAddContact}
                  onToggleContact={onToggleContact}
                />
                <hr />
              </React.Fragment>
            )}
            <div className={styles.postTitleBox}>
              <TextareaField
                name="postTitle"
                onChange={onChangeTitle}
                value={postTitle}
                className={styles.postTitle}
                placeholder="Title"
                minRows={1}
                maxRows={3}
              />
            </div>
            <Editor
              editorState={postDescription}
              setEditorState={onChangeDescription}
              fieldClassName={styles.postDescription}
              placeholder="Description"
              isToolbar={false}
            />
            <div className={styles.textStylingInfo}>
              {postDescriptionLength > 0 && <TextStylingInfo />}
            </div>
            <hr />
            <PostTags tags={tags} onAddTag={onAddTag} />
            <hr />
            {actions.length > 0 && (
              <React.Fragment>
                <ProposedActions
                  actions={actions}
                  isActionBtnDisabled={isActionBtnDisabled}
                  onAddAction={onAddAction}
                  onRemoveAction={onRemoveAction}
                />
                <hr />
              </React.Fragment>
            )}
            {uploadedFiles.length > 0 && (
              <React.Fragment>
                <UploadedFiles
                  uploadedFiles={uploadedFiles}
                  onDropAccepted={onDropAccepted}
                  onRemoveFile={onRemoveFile}
                />
                <hr />
              </React.Fragment>
            )}
            {uploadedImages.length > 0 && (
              <React.Fragment>
                <UploadedImages
                  uploadedImages={uploadedImages}
                  onRemoveFile={onRemoveFile}
                />
                <hr />
              </React.Fragment>
            )}
            <div className="error cntr pt10">{getErrorText(errors)}</div>
          </div>

          <PostModalFooter
            btnCaption="Post"
            disabled={loading}
            onClick={onSubmit}
            additionalBlock={
              <ActionButtons
                isEditMode={isEditMode}
                onDropAccepted={onDropAccepted}
                onAddAction={onAddAction}
                isActionBtnDisabled={isActionBtnDisabled}
                openFileDialog={openFileDialog}
              />
            }
          />
        </div>
      )}
    </Dropzone>
  );
}

export { CreationFormMainScreen };
