import gql from 'graphql-tag';
import { notificationsFragment } from './fragments';

export const notificationsQuery = gql`
  query NotificationsQuery($workspaceId: UUID!, $first: Int, $after: String) {
    notifications(workspaceId: $workspaceId, first: $first, after: $after) {
      edges {
        node {
          ...NotificationFragment
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${notificationsFragment}
`;

export const notificationsCount = gql`
  query NotificationsCountQuery($workspaceId: UUID!) {
    numberOfNotifications(
      workspaceId: $workspaceId
      notificationFilter: { seenFilter: ONLY_UNSEEN }
    )
  }
`;

export const notificationsMutation = gql`
  mutation MarkNotificationAsSeen(
    $workspaceId: UUID!
    $markAllAsSeen: Boolean
    $notificationIds: [UUID]!
  ) {
    markNotificationAsSeen(
      markNotificationAsSeenRequest: {
        workspaceId: $workspaceId
        markAllAsSeen: $markAllAsSeen
        notificationIds: $notificationIds
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const notificationsSubscription = gql`
  subscription NotificationsQuery($workspaceId: UUID!) {
    notifications(workspaceId: $workspaceId) {
      ...NotificationFragment
    }
  }
  ${notificationsFragment}
`;
