import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { compose, withApollo } from 'react-apollo';
import Sidebar from '../Sidebar';
import Header from '../Header';
import CardsFeed from '../CardsFeed';
import Feedback from './Feedback/Feedback';
import {
  commentThreadsQuery,
  updateFeedSubscription,
  postSubscription
} from '../../graphql';
import {
  groupToQuery,
  userToQuery,
  ratingToQuery,
  textToQuery
} from './feedQueryHelpers';
import { FeedApi, getFilters } from '../../services';
import { arrayUniqueByNodeId } from '../helpers/arrayUnique';
import Log from '../../Log';
import { withNetworkStateQuery } from '../../apollo/decorators';

class MainPageContent extends React.Component {
  state = {
    errorSubscription: false,
    isNewPostAppeared: false
  };

  componentDidMount() {
    const {
      subscribeToMore,
      currentWorkspaceId,
      fetchFeed,
      client
    } = this.props;
    FeedApi.init(client, fetchFeed);

    subscribeToMore({
      document: updateFeedSubscription,
      variables: {
        workspaceId: currentWorkspaceId
      },
      updateQuery: (prev, { subscriptionData }) => {
        const filterArr = getFilters();

        if (
          !subscriptionData.data ||
          !subscriptionData.data.posts ||
          !subscriptionData.data.posts.__typename
        ) {
          return prev;
        }

        if (subscriptionData.data.posts.__typename === 'Post') {
          const currentPost =
            prev &&
            prev.posts &&
            prev.posts.edges &&
            prev.posts.edges.find(item => {
              if (!item || !item.node || !item.node.id) {
                return null;
              }
              return item.node.id === subscriptionData.data.posts.id;
            });

          if (currentPost) {
            currentPost.node = {
              ...subscriptionData.data.posts,
              sharedBy: pathOr(
                null,
                ['data', 'posts', 'sharedBy'],
                subscriptionData
              ),
              sharedAt: pathOr(
                null,
                ['data', 'posts', 'sharedAt'],
                subscriptionData
              ),
              editedAt: pathOr(
                null,
                ['data', 'posts', 'editedAt'],
                subscriptionData
              )
            };

            return prev;
          }

          this.setState({
            isNewPostAppeared: true
          });

          if (
            filterArr.filterByUserState &&
            filterArr.filterByUserState !==
              subscriptionData.data.posts.createdBy.id
          ) {
            // don't add post from another groups/users when feed is filtered by specific user
            return prev;
          }

          if (filterArr.filterByGroupState) {
            const isCreatedByFilteredGroup = subscriptionData.data.posts.commentThreads.edges.some(
              item => item.node.group.id === filterArr.filterByGroupState
            );

            if (!isCreatedByFilteredGroup) {
              // don't add post from another groups/users when feed is filtered by specific group
              return prev;
            }
          }

          return {
            posts: {
              ...prev.posts,
              edges: [
                {
                  node: {
                    ...subscriptionData.data.posts,
                    sharedBy: pathOr(
                      null,
                      ['data', 'posts', 'sharedBy'],
                      subscriptionData
                    ),
                    sharedAt: pathOr(
                      null,
                      ['data', 'posts', 'sharedAt'],
                      subscriptionData
                    ),
                    editedAt: pathOr(
                      null,
                      ['data', 'posts', 'editedAt'],
                      subscriptionData
                    )
                  },
                  __typename: 'PostEdge'
                },
                ...prev.posts.edges
              ]
            }
          };
        }

        return prev;
      },
      onError: err => {
        this.showErrorOnSubscription();
        Log.error(`Error retrieving subscription: ${err}`, 'MainPageContent');
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { isOnline, refetchFeed } = this.props;

    if (isOnline && prevProps.isOnline !== isOnline) {
      refetchFeed();
    }
  }

  subscribeToPost = postId => {
    const { subscribeToMore, currentWorkspaceId } = this.props;

    subscribeToMore({
      document: postSubscription,
      variables: {
        postId,
        workspaceId: currentWorkspaceId
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (
          !subscriptionData.data ||
          !subscriptionData.data.post ||
          !subscriptionData.data.post.__typename
        ) {
          return prev;
        }

        if (subscriptionData.data.post.__typename === 'RatedPost') {
          const currentPost =
            prev &&
            prev.posts &&
            prev.posts.edges &&
            prev.posts.edges.find(item => {
              if (!item || !item.node || !item.node.id) {
                return null;
              }
              return item.node.id === subscriptionData.data.post.id;
            });

          if (currentPost) {
            currentPost.node = {
              ...currentPost.node,
              rating: subscriptionData.data.post.rating
            };
          }
        }

        if (
          subscriptionData.data.post.__typename ===
          'PostReactionUpdatedSubscription'
        ) {
          const reactionPostId = subscriptionData.data.post.id;
          const postReactions = subscriptionData.data.post.reactions;

          const newEdgesArr = prev.posts.edges.map(item => {
            if (item.node.id === reactionPostId) {
              return {
                ...item,
                node: {
                  ...item.node,
                  reactions: postReactions
                }
              };
            }
            return item;
          });

          return {
            ...prev,
            posts: {
              ...prev.posts,
              edges: newEdgesArr
            }
          };
        }

        if (
          subscriptionData.data.post.__typename ===
          'PostReactionDeletedSubscription'
        ) {
          Log.info('PostReactionDeletedSubscription');
          return prev;
        }

        return prev;
      },
      onError: err => {
        Log.error(`Error retrieving subscription: ${err}`, 'Post');
      }
    });
  };

  showErrorOnSubscription = () => {
    this.setState({
      errorSubscription: true
    });
  };

  hideNewPostNotification = () => {
    this.setState({
      isNewPostAppeared: false
    });
  };

  fetchMoreFeed = () => {
    const { loadingFeed, fetchFeed, postsPageInfo } = this.props;
    const filterArr = getFilters();
    const {
      filterByGroupState,
      filterByUserState,
      filterByRatingState,
      filterByTextState
    } = filterArr;

    if (loadingFeed || !postsPageInfo.hasNextPage || !postsPageInfo.endCursor) {
      return null;
    }

    return fetchFeed({
      variables: {
        after: postsPageInfo.endCursor,
        ratingFilter: ratingToQuery(filterByRatingState),
        groupFilter: groupToQuery(filterByGroupState),
        textSearchFilter: textToQuery(filterByTextState),
        actorFilter: userToQuery(filterByUserState)
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          !fetchMoreResult.posts ||
          !fetchMoreResult.posts.edges ||
          !fetchMoreResult.posts.pageInfo
        ) {
          return prev;
        }

        return {
          posts: {
            edges: [...prev.posts.edges, ...fetchMoreResult.posts.edges],
            pageInfo: {
              ...fetchMoreResult.posts.pageInfo
            },
            __typename: prev.posts.__typename
          }
        };
      }
    }).catch(err => {
      Log.error(`Error fetching feed: ${err}`, 'MainPageContent');
    });
  };

  fetchMoreThreads = (postId, after) => {
    const { clientQuery, updateQueryFeed, currentWorkspaceId } = this.props;

    clientQuery({
      query: commentThreadsQuery,
      variables: {
        workspaceId: currentWorkspaceId,
        postId,
        after
      }
    })
      .then(res => {
        const newThreads = res && res.data && res.data.commentThreads;

        if (!newThreads) {
          return null;
        }

        updateQueryFeed(currentFeed => {
          const currentPost = currentFeed.posts.edges.find(
            item => item && item.node && item.node.id === postId
          );
          const currentThreads =
            currentPost && currentPost.node && currentPost.node.commentThreads;

          if (!currentThreads) {
            return currentFeed;
          }

          currentPost.node.commentThreads = {
            edges: arrayUniqueByNodeId([
              ...currentThreads.edges,
              ...newThreads.edges
            ]),
            pageInfo: newThreads.pageInfo,
            __typename: currentThreads.__typename
          };

          return currentFeed;
        });

        return null;
      })
      .catch(err => {
        Log.error(`Error fetching more threads: ${err}`, 'MainPageContent');
      });
  };

  render() {
    const { errorSubscription, isNewPostAppeared } = this.state;

    const {
      feedList,
      loadingFeed,
      postsPageInfo,
      currentWorkspaceId
    } = this.props;

    return (
      <React.Fragment>
        <div className="MainPage">
          <div className="main-content-wrapper">
            <Sidebar />

            <div className="main-context">
              <Header currentWorkspaceId={currentWorkspaceId} />
              <CardsFeed
                feedList={feedList}
                loadingFeed={loadingFeed}
                fetchMoreFeed={this.fetchMoreFeed}
                loadAllFeed={postsPageInfo && !postsPageInfo.hasNextPage}
                fetchMoreThreads={this.fetchMoreThreads}
                currentWorkspaceId={currentWorkspaceId}
                isNewPostAppeared={isNewPostAppeared}
                hideNewPostNotification={this.hideNewPostNotification}
                showErrorOnSubscription={this.showErrorOnSubscription}
                subscribeToPost={this.subscribeToPost}
              />
            </div>
          </div>
        </div>

        {errorSubscription && (
          <div className="error-subscription-box">
            Could not connect to Buj.
            <br />
            <button
              type="button"
              className="reload-btn"
              onClick={() => window.location.reload()}
            >
              Reload page
            </button>
          </div>
        )}
        <Feedback currentWorkspaceId={currentWorkspaceId} />
      </React.Fragment>
    );
  }
}

MainPageContent.propTypes = {
  feedList: PropTypes.arrayOf(PropTypes.shape({})),
  loadingFeed: PropTypes.bool,
  fetchFeed: PropTypes.func.isRequired,
  updateQueryFeed: PropTypes.func.isRequired,
  clientQuery: PropTypes.func.isRequired,
  postsPageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    endCursor: PropTypes.string
  })
};

MainPageContent.defaultProps = {
  feedList: [],
  loadingFeed: false,
  postsPageInfo: {
    hasNextPage: false,
    endCursor: null
  }
};

export default compose(
  withApollo,
  withNetworkStateQuery
)(MainPageContent);
