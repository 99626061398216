import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import { withWorkspaceAndUser } from '../../apollo/decorators';
import { getPostQuery } from '../../graphql';
import Log from '../../Log';
import IndexCard from '../CardsFeed/IndexCard';
import { Loader } from '../UI';

interface SelectedPost {
  postId: string;
  commentThreadId?: string;
  commentId?: string;
}

interface Props {
  clear: () => void;
  selectedPost: SelectedPost | null;
  workspaceId: string;
}
// No operation empty function
const NOOP = (): void => {};

class SinglePost extends PureComponent<Props & any, {}> {
  public render() {
    const {
      workspaceId,
      selectedPost: { postId, commentThreadId }
    } = this.props;
    return (
      <Query
        query={getPostQuery}
        variables={{
          workspaceId,
          postId
        }}
      >
        {({ loading, error, data }: any) => {
          if (loading) {
            return (
              <div className="loader">
                <Loader />
              </div>
            );
          }
          if (error) {
            Log.error(error, 'SinglePost');
            return null;
          }
          return (
            <IndexCard
              item={data.post}
              key={data.post.id}
              isCardsViewExpanded={true}
              fetchFeedByText={NOOP}
              fetchMoreThreads={NOOP}
              currentWorkspaceId={workspaceId}
              showErrorOnSubscription={NOOP}
              subscribeToPost={NOOP}
              selectedTabId={commentThreadId}
            />
          );
        }}
      </Query>
    );
  }
}

export default withWorkspaceAndUser(SinglePost);
