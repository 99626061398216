import React from 'react';
import { withWorkspaceAndUser } from '../../apollo/decorators';
import Log from '../../Log';
import uploadFile from '../helpers/uploadFile';

interface Props {
  // gql
  workspaceId: string;
  onSubmitAvatar(fileId: string): any;
  onSubmitAvatarSuccess?(): void;
  onDropSuccess?(): void;
}

interface State {
  file: {
    name: string;
    type: string;
  };
  avatarPreview: string;
  sendingInfo: {
    name: string;
    blob: any;
  };
  isFileValid: boolean;
  error: boolean;
  fileLoading: boolean;
}

export default (OriginalComponent: any) =>
  withWorkspaceAndUser(
    class AvatarUploader extends React.PureComponent<Props & any, State> {
      public state = {
        file: {
          name: '',
          type: ''
        },
        avatarPreview: '',
        sendingInfo: {
          name: '',
          blob: ''
        },
        isFileValid: true,
        error: false,
        fileLoading: false
      };

      public render() {
        const { avatarPreview } = this.state;

        const configDropZone = {
          accept: '.jpeg, .jpg, .png, .svg',
          multiple: false
        };

        const configCropper = {
          aspectRatio: 1,
          src: avatarPreview
        };

        return (
          <React.Fragment>
            <OriginalComponent
              {...this.state}
              {...this.props}
              onDropAccepted={this.onDropAccepted}
              onDropRejected={this.onDropRejected}
              onCrop={this.onCrop}
              onSubmit={this.onSubmit}
              configDropZone={configDropZone}
              configCropper={configCropper}
            />
          </React.Fragment>
        );
      }

      private onDropAccepted = (validFiles: string[]) => {
        const { onDropSuccess = () => {} } = this.props;

        const file: any = validFiles[0];
        const avatarPreview = URL.createObjectURL(file);

        this.setState({
          avatarPreview,
          file,
          isFileValid: true
        });

        onDropSuccess();
      };

      private onDropRejected = () => {
        this.setState({
          isFileValid: false
        });
      };

      private onCrop = (cropper: any) => {
        const { file } = this.state;
        const { name, type } = file;

        const canvas = cropper.getCroppedCanvas();

        const onBlobReadyCallback = (blob: any) => {
          this.setState({
            sendingInfo: {
              name,
              blob
            }
          });
        };

        canvas.toBlob(onBlobReadyCallback, type);
      };

      private onUploadFileSuccess = (res: any) => {
        this.setState({
          fileLoading: false
        });

        const { onSubmitAvatar, onSubmitAvatarSuccess = () => {} } = this.props;

        const fileId = res[0] && res[0].file_id;

        if (!fileId) {
          return this.setState({
            error: true
          });
        }

        onSubmitAvatar(fileId)
          .then(() => {
            this.setState({
              error: false
            });

            onSubmitAvatarSuccess();
          })
          .catch((err: any) => {
            this.setState({
              error: true
            });

            Log.error(err, 'changeAvatar');
          });

        return null;
      };

      private onUploadFileError = (err: any) => {
        this.setState({
          error: true,
          fileLoading: false
        });

        Log.error(err, 'uploadAvatar');
      };

      private onSubmit = () => {
        const { workspaceId } = this.props;
        const {
          sendingInfo: { name, blob }
        } = this.state;

        if (!name) {
          return null;
        }

        this.setState({
          fileLoading: true
        });

        const formData = new FormData();
        formData.append('file', blob, name);

        uploadFile(
          workspaceId,
          formData,
          this.onUploadFileSuccess,
          this.onUploadFileError
        );

        return null;
      };
    }
  );
