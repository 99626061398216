import React from 'react';
import PropTypes from 'prop-types';
import DropZone from '../../../UI/DropZone';
import Cropper from '../../../UI/Cropper';
import styles from '../addGroup.module.scss';
import avatarDefault from '../../../../assets/img/groupDefault@2x.png';

export default class AddAvatarView extends React.PureComponent {
  static propTypes = {
    onDropAccepted: PropTypes.func.isRequired,
    onDropRejected: PropTypes.func.isRequired,
    onCrop: PropTypes.func.isRequired,
    avatarPreview: PropTypes.string,
    isFileValid: PropTypes.bool.isRequired
  };

  static defaultProps = {
    avatarPreview: ''
  };

  render() {
    const {
      onDropAccepted,
      onDropRejected,
      onCrop,
      avatarPreview,
      isFileValid
    } = this.props;

    const configDropZone = {
      accept: '.jpeg, .jpg, .png, .svg',
      multiple: false
    };

    const configCropper = {
      aspectRatio: 1,
      src: avatarPreview
    };

    return (
      <div>
        {!avatarPreview && (
          <React.Fragment>
            <DropZone
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
              dropZoneClassName={styles.dropZoneWrapper}
              config={configDropZone}
            >
              <div className={styles.dropZoneBox}>
                <img src={avatarDefault} alt="" className={styles.avatar} />
                <p className={styles.dropZoneHover}>Upload an image</p>
              </div>
            </DropZone>
            {!isFileValid && (
              <div className="error cntr pb10">File is not valid</div>
            )}
          </React.Fragment>
        )}

        {avatarPreview && (
          <React.Fragment>
            <Cropper
              onCrop={onCrop}
              cropperClassName={styles.cropperBox}
              config={configCropper}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
