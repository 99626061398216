import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import { debounce } from 'throttle-debounce';

import MemberItem from './MemberItem';
import Log from '../../../../Log';
import manageGroupCloseBtnImg from '../../../../assets/img/manageGroupCloseBtn.png';
import { ChangeGroupAvatar } from '../ChangeGroupAvatar';
import Loader from '../../../UI/Loader';

class ManageGroupContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchNonMembersValue: '',
      isUserSearchShown: false
    };

    this.searchNonMembersThrottled = debounce(500, this.searchNonMembers);
  }

  showUserSearch = () => {
    const { isUserSearchShown } = this.state;
    this.setState({
      isUserSearchShown: !isUserSearchShown
    });
  };

  fetchMoreMembers = membersPageInfo => {
    const { fetchMoreGroup } = this.props;

    if (!membersPageInfo.hasNextPage) {
      return null;
    }

    fetchMoreGroup({
      variables: {
        membersAfter: membersPageInfo.endCursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.group || !fetchMoreResult.group.members) {
          return prev;
        }

        return {
          group: {
            ...prev.group,
            members: {
              ...fetchMoreResult.group.members,
              edges: [
                ...prev.group.members.edges,
                ...fetchMoreResult.group.members.edges
              ]
            }
          }
        };
      }
    }).catch(err => {
      Log.error(err, 'fetchMoreMembers');
    });

    return null;
  };

  fetchMoreNonMembers = nonMembersPageInfo => {
    const { fetchMoreGroup } = this.props;
    const { searchNonMembersValue } = this.state;

    if (!nonMembersPageInfo.hasNextPage) {
      return null;
    }

    let nonMembersFilter = null;

    if (searchNonMembersValue.trim()) {
      nonMembersFilter = {
        nameFilter: {
          searchQuery: searchNonMembersValue
        }
      };
    }

    fetchMoreGroup({
      variables: {
        nonMembersFilter,
        nonMembersAfter: nonMembersPageInfo.endCursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.group || !fetchMoreResult.group.nonMembers) {
          return prev;
        }

        return {
          group: {
            ...prev.group,
            nonMembers: {
              ...fetchMoreResult.group.nonMembers,
              edges: [
                ...prev.group.nonMembers.edges,
                ...fetchMoreResult.group.nonMembers.edges
              ]
            }
          }
        };
      }
    }).catch(err => {
      Log.error(err, 'fetchMoreNonMembers');
    });

    return null;
  };

  onNonMembersSearch = e => {
    const searchNonMembersValue = e.target.value;

    this.setState({
      searchNonMembersValue
    });

    this.searchNonMembersThrottled(searchNonMembersValue);
  };

  searchNonMembers = value => {
    const { fetchMoreGroup } = this.props;

    let nonMembersFilter = null;

    if (value.trim()) {
      nonMembersFilter = {
        nameFilter: {
          searchQuery: value
        }
      };
    }

    fetchMoreGroup({
      variables: {
        nonMembersFilter
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          group: {
            ...prev.group,
            nonMembers: fetchMoreResult.group.nonMembers
          }
        };
      }
    }).catch(err => {
      Log.error(err, 'searchNonMembers');
    });

    return null;
  };

  addUserToState = user => {
    const { updateGroupQuery } = this.props;

    updateGroupQuery(data => {
      return {
        group: {
          ...data.group,
          membersCount: data.group.membersCount + 1,
          members: {
            ...data.group.members,
            edges: [...data.group.members.edges, user]
          }
        }
      };
    });
  };

  render() {
    const {
      closeModal,
      dataGroup,
      loadingFetchMore,
      refetchGroup,
      loadingRefetch
    } = this.props;
    const { isUserSearchShown, searchNonMembersValue } = this.state;

    const { group } = dataGroup;

    const membersInfo = group && group.members;
    const members = membersInfo && membersInfo.edges;
    const memberIds = members && members.map(item => item.node.id);
    const membersPageInfo = membersInfo && membersInfo.pageInfo;

    const nonMembersInfo = group && group.nonMembers;
    const nonMembers = nonMembersInfo && nonMembersInfo.edges;
    const nonMembersPageInfo = nonMembersInfo && nonMembersInfo.pageInfo;

    return (
      <div className="ManageGroup">
        <div className="header-box">
          <button type="button" className="close-btn" onClick={closeModal}>
            Back
          </button>
          <h2>Manage team</h2>
        </div>

        <div className="group-info">
          <ChangeGroupAvatar
            group={group}
            refetchGroup={refetchGroup}
            loadingRefetch={loadingRefetch}
          />
          <div className="group-name">{group.name}</div>
        </div>

        <div className="invite-block">
          <div className="members-counter">{group.membersCount} Members</div>

          {!isUserSearchShown && (
            <div className="invite-member-icon" onClick={this.showUserSearch}>
              <div className="icon-plus">+</div>
              <span>Invite New Members</span>
            </div>
          )}
          {isUserSearchShown && (
            <div className="manage-group-search">
              <input
                type="text"
                onChange={this.onNonMembersSearch}
                value={searchNonMembersValue}
                placeholder="Search user..."
                maxLength={80}
              />
              <div className="close-img" onClick={this.showUserSearch}>
                <img src={manageGroupCloseBtnImg} alt="" />
              </div>
            </div>
          )}
        </div>

        {!isUserSearchShown && (
          <div className="members-list">
            {members.length > 0 &&
              members.map(member => {
                if (!member.node) {
                  return null;
                }

                return (
                  <MemberItem
                    key={member.node.id}
                    member={member.node}
                    groupId={group.id}
                  />
                );
              })}
            {!loadingFetchMore ? (
              <Waypoint
                onEnter={() => this.fetchMoreMembers(membersPageInfo)}
              />
            ) : (
              <div className="cntr pt10">
                <Loader width="40px" />
              </div>
            )}
          </div>
        )}
        {isUserSearchShown && (
          <div className="members-list">
            {nonMembers.length > 0 &&
              nonMembers.map(nonMember => {
                if (!nonMember.node) {
                  return null;
                }

                return (
                  <MemberItem
                    key={nonMember.node.id}
                    member={nonMember.node}
                    groupId={group.id}
                    addUserToState={() => this.addUserToState(nonMember)}
                    isVisibleInvitation
                    memberIds={memberIds}
                  />
                );
              })}
            {!loadingFetchMore ? (
              <Waypoint
                onEnter={() => this.fetchMoreNonMembers(nonMembersPageInfo)}
              />
            ) : (
              <div className="cntr pt10">
                <Loader width="40px" />
              </div>
            )}
            {!loadingFetchMore && nonMembers.length === 0 && (
              <div className="cntr pt10">
                No matches found.
                <br />
                Did you spell it correctly?
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

ManageGroupContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  // eslint-disable-next-line
  dataGroup: PropTypes.object.isRequired,
  loadingFetchMore: PropTypes.bool.isRequired,
  loadingRefetch: PropTypes.bool.isRequired,
  // eslint-disable-next-line
  fetchMoreGroup: PropTypes.func.isRequired,
  updateGroupQuery: PropTypes.func.isRequired,
  refetchGroup: PropTypes.func.isRequired
};

export default ManageGroupContent;
