import { isEmailValid } from '../../Components/helpers';
import * as CONST from './Validator.constants';
import { Constraints } from './Validator.types';

// tslint:disable-next-line
const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-.\/:;<=>?@[\\\]^_`{|}~])[0-9a-zA-Z!"#$%&'()*+,\-.\/:;<=>?@[\\\]^_`{|}~]+$/;

export const ValidatorConstraints: Constraints = {
  email: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields) => isEmailValid(value)
    }
  ],
  password: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.PASSWORD_MIN_LENGTH
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields) => passwordRegexp.test(value)
    },
    {
      error: CONST.NOT_EQUAL,
      comparator: (value: string, fields) => {
        if (fields.confirmedPassword) {
          return value === fields.confirmedPassword;
        }

        return true;
      }
    }
  ],
  confirmedPassword: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.PASSWORD_MIN_LENGTH
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields) => passwordRegexp.test(value)
    },
    {
      error: CONST.NOT_EQUAL,
      comparator: (value: string, fields) => value === fields.password
    }
  ],
  name: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.USER_NAME_MIN_LENGTH
    }
  ],
  login: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.USER_NICKNAME_MIN_LENGTH
    }
  ],
  workspaceName: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.WORKSPACE_NAME_MIX_LENGTH
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields) => {
        const regexp = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;

        return regexp.test(value);
      }
    }
  ],
  postTitle: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) =>
        value.length > 0 || fields.postDescription.length > 0
    },
    {
      error: CONST.LONG_FIELD,
      comparator: (value: string, fields) =>
        value.length <= CONST.POST_TITLE_MAX_LENGTH
    }
  ],
  postDescription: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) =>
        value.length > 0 || fields.postTitle.length > 0
    },
    {
      error: CONST.LONG_FIELD,
      comparator: (value: string, fields) =>
        value.length <= CONST.POST_DESCRIPTION_MAX_LENGTH
    }
  ],
  postTo: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => Number(value) > 0
    }
  ]
};
