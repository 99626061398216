import React from 'react';
import { CreateWorkspace } from '../../Components/CreateWorkspace';
import {
  logoutFunc,
  RefreshToken as RefreshTokenComponent
} from '../../Components/helpers';
import PageHeader from '../../Components/PageHeader';
import TokenStore from '../../services';

const CreateWorkspacePage = () => {
  if (!TokenStore.getRefreshToken()) {
    return logoutFunc();
  }

  if (!TokenStore.getIdentityToken()) {
    return <RefreshTokenComponent tokenStore={TokenStore} />;
  }

  return (
    <React.Fragment>
      <PageHeader />
      <CreateWorkspace />
    </React.Fragment>
  );
};

export { CreateWorkspacePage };
