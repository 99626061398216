import { ValidatorConstraints } from './Validator.constraints';
import { Constraint, ValidatorFields } from './Validator.types';

class Validator {
  public static validate(fields: ValidatorFields) {
    const result = {
      isValid: true,
      errors: {}
    };

    Object.entries(fields).forEach(([type, value]) => {
      const validators: Constraint[] = ValidatorConstraints[type];

      for (const step of validators) {
        const isValid = step.comparator(value, fields);

        result.errors = {
          ...result.errors,
          [type]: isValid ? '' : step.error
        };

        if (!isValid) {
          result.isValid = false;
          return;
        }
      }
    });
    return result;
  }
}

export { Validator };
