import classNames from 'classnames';
import React from 'react';
import { Image } from '../../../UI/Image';
// @ts-ignore
import styles from './postImages.module.scss';
import { IPostImage } from './PostImages.types';

interface Props {
  images: IPostImage[];
  openModal(initialImgSlide: number): void;
}

const PostImagesView = ({ images, openModal }: Props) => {
  const imgViewClassName = classNames(styles.imgView, {
    [styles.two]: images.length === 2,
    [styles.three]: images.length === 3,
    [styles.more]: images.length >= 4
  });

  return (
    <div className={styles.imageBox}>
      <div className={imgViewClassName}>
        <div className={styles.imgMain} onClick={() => openModal(0)}>
          <Image fileId={images[0].fileId} key={images[0].fileId} />
        </div>
        {images.length > 1 && (
          <div className={styles.imgAll}>
            {images.slice(1, 4).map((image: IPostImage, index: any) => (
              <div
                key={image.fileId}
                className={styles.imgRest}
                onClick={() => openModal(index + 1)}
              >
                <Image fileId={image.fileId} key={image.fileId} />
              </div>
            ))}
          </div>
        )}
      </div>
      {images.length > 4 && (
        <div className={styles.labelMoreImg} onClick={() => openModal(3)}>
          {images.length - 4} more images
        </div>
      )}
    </div>
  );
};

export { PostImagesView };
