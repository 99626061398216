import classNames from 'classnames';
import React from 'react';
import { ButtonPrimary, InputField } from '../../../UI';
// @ts-ignore
import styles from './proposedAction.module.scss';

interface Props {
  fields: {
    [key: string]: string;
  };
  wrapperRef: any;
  position: string;
  isFormVisible: boolean;
  toggleButton: any;
  onChange(e: any): void;
  onAdd(): void;
}

const CreatingActionsView = ({
  fields,
  wrapperRef,
  position,
  isFormVisible,
  onChange,
  onAdd,
  toggleButton
}: Props) => (
  <div className={styles.wrapper} ref={wrapperRef} style={{ display: 'none' }}>
    {toggleButton}

    {isFormVisible && (
      <div className={classNames(styles.actionForm, styles[position])}>
        <div className={styles.formRow}>
          <InputField
            value={fields.label}
            name="label"
            onChange={onChange}
            placeholder="Action's title"
            wrapperClassName={styles.inputBox}
          />
        </div>
        <div className={styles.formRow}>
          <InputField
            value={fields.url}
            name="url"
            onChange={onChange}
            placeholder="Link"
            wrapperClassName={styles.inputBox}
          />
          <ButtonPrimary onClick={onAdd} buttonStyle="blue" size="md">
            Add
          </ButtonPrimary>
        </div>
      </div>
    )}
  </div>
);

export { CreatingActionsView };
