import React from 'react';
import Editor from '../../UI/Editor';
import PostIn from '../Components/PostIn';
import PostModalFooter from '../Components/PostModalFooter';
import PostModalHeader from '../Components/PostModalHeader';
import { SelectedContactItem } from '../CreatePost.types';
import { getErrorText } from '../getErrorText';
// @ts-ignore
import styles from './createSharedPost.module.scss';

interface Props {
  shareComment: any;
  currentWorkspaceId: string;
  selectedContacts: SelectedContactItem[];
  postView: any;
  error: string;
  isDisabled: boolean;
  contactsException: string[];
  onAddContact(contacts: any): void;
  onToggleContact(e: any, val: any): void;
  onChangeShareComment(val: any): void;
  onSubmit(e?: any): void;
}

function CreateSharedPostModalContent(props: Props) {
  const {
    shareComment,
    currentWorkspaceId,
    selectedContacts,
    onAddContact,
    onToggleContact,
    postView,
    error,
    isDisabled,
    contactsException,
    onChangeShareComment,
    onSubmit
  } = props;

  return (
    <React.Fragment>
      <div className={styles.modalContent}>
        <PostModalHeader title="Share" />
        <hr />
        <PostIn
          currentWorkspaceId={currentWorkspaceId}
          selectedContacts={selectedContacts}
          onAddContact={onAddContact}
          onToggleContact={onToggleContact}
          contactsException={contactsException}
        />
        <hr />
        <Editor
          editorState={shareComment}
          setEditorState={onChangeShareComment}
          fieldClassName={styles.shareComment}
          placeholder="Description"
          isToolbar={false}
        />
        <div className={styles.postView}>{postView}</div>

        {error && <div className="error cntr pt15">{getErrorText(error)}</div>}
      </div>

      <PostModalFooter
        btnCaption="Post"
        disabled={isDisabled}
        onClick={onSubmit}
      />
    </React.Fragment>
  );
}

export { CreateSharedPostModalContent };
