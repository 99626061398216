import { uniqBy } from 'ramda';
import React from 'react';
import { IMAGE_EXTENSIONS } from '../../../../constants';
import { createPostMutation, editPostMutation } from '../../../../graphql';
import Log from '../../../../Log';
import { Validator } from '../../../../services';
import uploadFile from '../../../helpers/uploadFile';
import {
  ContentState,
  convertToMarkdown,
  EditorState
} from '../../../UI/Editor';
import { IMG } from '../../constants';
import { IAction, IErrors, SelectedContactItem } from '../../CreatePost.types';
import { CreationFormMainScreen } from './CreationFormMainScreen';

interface Props {
  currentWorkspaceId: string;
  submitPostFunc: any;
  submitData: any;
  isEditMode?: boolean;
  savedPostTitle?: string;
  savedPostDescription?: string;
  savedTags?: string[];
  savedImages?: Array<{
    fileId: string;
    name: string;
  }>;
  savedFiles?: Array<{
    fileId: string;
    name: string;
  }>;
  postId?: string;
  closeModal(): void;
}

interface State {
  postTitle: string;
  postDescription: any;
  selectedContacts: SelectedContactItem[];
  uploadedFiles: any;
  uploadedImages: any;
  fileLoading: boolean;
  tags: string[];
  actions: IAction[];
  errors: IErrors;
}

const emptyErrorsState = {
  postTitle: '',
  postDescription: '',
  postTo: '',
  onSubmit: ''
};

class CreationForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      savedPostTitle,
      savedPostDescription,
      savedTags,
      savedImages,
      savedFiles
    } = props;

    const postDescription = savedPostDescription
      ? EditorState.createWithContent(
          ContentState.createFromText(savedPostDescription)
        )
      : EditorState.createEmpty();

    this.state = {
      postTitle: savedPostTitle || '',
      postDescription,
      selectedContacts: [],
      uploadedFiles: savedFiles || [],
      uploadedImages: savedImages || [],
      fileLoading: false,
      tags: savedTags || [],
      actions: [],
      errors: emptyErrorsState
    };
  }

  public onChangeTitle = (e: any) => {
    this.setState(
      {
        postTitle: e.target.value
      },
      this.validateTitle
    );
  };

  public onChangeDescription = (description: any) => {
    this.setState((prevState: State) => {
      const { postTitle, postDescription } = prevState;

      const prevDescription = convertToMarkdown(postDescription);
      const nextDescription = convertToMarkdown(description);

      let validationResult = {
        errors: {}
      };

      if (prevDescription !== nextDescription) {
        validationResult = Validator.validate({
          postTitle: postTitle.trim(),
          postDescription: nextDescription
        });
      }

      return {
        postDescription: description,
        errors: {
          ...emptyErrorsState,
          ...validationResult.errors
        }
      };
    });
  };

  public onAddContact = (selectedContacts: SelectedContactItem[]) => {
    const filteredContacts = selectedContacts.filter((item: any) => item.node);

    this.setState({
      selectedContacts: filteredContacts
    });
  };

  public onToggleContact = (e: any, contact: any) => {
    const { selectedContacts } = this.state;

    const filteredContacts = selectedContacts.filter(
      (item: any) => item.node.id !== contact.node.id
    );

    if (e.target.checked && selectedContacts.length < 10) {
      this.setState({
        selectedContacts: [...filteredContacts, contact]
      });
    } else {
      this.setState({
        selectedContacts: [...filteredContacts]
      });
    }
  };

  public onRemoveFile = (fileName: string, fileType?: any) => {
    const { uploadedFiles, uploadedImages } = this.state;

    if (fileType === IMG) {
      const newArrUploadedImages = uploadedImages.filter(
        (item: any) => item.name !== fileName
      );

      return this.setState({
        uploadedImages: newArrUploadedImages
      });
    }

    const newArrUploadedFiles = uploadedFiles.filter(
      (item: any) => item.name !== fileName
    );

    return this.setState({
      uploadedFiles: newArrUploadedFiles
    });
  };

  public onAddTag = (tags: any) => {
    this.setState({ tags });
  };

  public onSubmit = () => {
    if (!this.validate()) {
      return false;
    }

    const { uploadedFiles, uploadedImages } = this.state;
    const { currentWorkspaceId } = this.props;

    const allFiles = [...uploadedFiles, ...uploadedImages].reduce(
      (result: any, item: any) => {
        !item.fileId ? result.newFiles.push(item) : result.oldFiles.push(item);
        return result;
      },
      { newFiles: [], oldFiles: [] }
    );

    if (allFiles.newFiles.length === 0) {
      this.addPost(allFiles.oldFiles);

      return null;
    }

    this.setState({
      fileLoading: true
    });

    const formData = new FormData();

    allFiles.newFiles.forEach((file: any) => {
      formData.append('file', file, file.name);
    });

    uploadFile(
      currentWorkspaceId,
      formData,
      (res: any) => this.onUploadFileSuccess(res, allFiles.oldFiles),
      this.onUploadFileError
    );

    return null;
  };

  public onUploadFileSuccess = (res: any, oldFiles: any) => {
    const attachments = res.map((file: any) => {
      return {
        fileId: file.file_id,
        name: file.name
      };
    });

    this.setState({
      fileLoading: false
    });

    this.addPost([...oldFiles, ...attachments]);
  };

  public onUploadFileError = (err: any) => {
    this.setState((prevState: State) => ({
      fileLoading: false,
      errors: {
        ...prevState.errors,
        onSubmit: 'Error loading file'
      }
    }));

    Log.error(err, 'createCard onUploadFileError');
  };

  public validateTitle = () => {
    const { postTitle, postDescription } = this.state;

    const { errors } = Validator.validate({
      postTitle: postTitle.trim(),
      postDescription: convertToMarkdown(postDescription)
    });

    this.setState({
      errors: {
        ...emptyErrorsState,
        ...errors
      }
    });
  };

  public validate = () => {
    const { isEditMode } = this.props;
    const {
      selectedContacts,
      postTitle,
      postDescription,
      errors: prevErrors
    } = this.state;

    let validationResult = {
      errors: {},
      isValid: true
    };

    if (isEditMode) {
      validationResult = Validator.validate({
        postTitle: postTitle.trim(),
        postDescription: convertToMarkdown(postDescription)
      });
    } else {
      validationResult = Validator.validate({
        postTitle: postTitle.trim(),
        postDescription: convertToMarkdown(postDescription),
        postTo: String(selectedContacts.length)
      });
    }

    this.setState({
      errors: {
        ...prevErrors,
        ...validationResult.errors
      }
    });

    return validationResult.isValid;
  };

  public addPost = (attachments: any) => {
    const {
      postTitle,
      postDescription,
      selectedContacts,
      tags,
      actions
    } = this.state;

    const {
      currentWorkspaceId,
      submitPostFunc,
      isEditMode,
      postId,
      closeModal
    } = this.props;

    const title = postTitle.trim();
    const description = convertToMarkdown(postDescription);
    const tagsArray = tags.map((tag: string) => ({
      tag: tag.trim().replace(/^#/, '')
    }));

    const userIds = selectedContacts
      .filter((item: any) => item.node.__typename === 'User')
      .map((item: any) => item.node.id);
    const groupIds = selectedContacts
      .filter((item: any) => item.node.__typename === 'Group')
      .map((item: any) => item.node.id);

    const attachmentsArr = attachments.map((file: any) => ({
      fileId: file.fileId,
      name: file.name
    }));

    if (isEditMode) {
      return submitPostFunc({
        variables: {
          tags: tagsArray,
          description,
          workspaceId: currentWorkspaceId,
          title,
          attachments: attachmentsArr,
          postId
        }
      })
        .then(() => {
          closeModal();
        })
        .catch((err: any) => {
          this.setState((prevState: State) => ({
            errors: {
              ...prevState.errors,
              onSubmit: 'Error'
            }
          }));

          Log.error(`editPost: ${err}`);
        });
    }

    return submitPostFunc({
      variables: {
        origin: '',
        tags: tagsArray,
        groupIds,
        userIds,
        description,
        workspaceId: currentWorkspaceId,
        title,
        attachments: attachmentsArr,
        actions: {
          createGetUrlActionRequests: actions
        }
      }
    })
      .then(() => {
        closeModal();
      })
      .catch((err: any) => {
        this.setState((prevState: State) => ({
          errors: {
            ...prevState.errors,
            onSubmit: 'Error'
          }
        }));

        Log.error(`createPost: ${err}`);
      });
  };

  public render() {
    const {
      postTitle,
      postDescription,
      selectedContacts,
      uploadedFiles,
      uploadedImages,
      fileLoading,
      tags,
      actions,
      errors
    } = this.state;

    const { currentWorkspaceId, submitData, isEditMode = false } = this.props;

    return (
      <React.Fragment>
        <CreationFormMainScreen
          isEditMode={isEditMode}
          postTitle={postTitle}
          onChangeTitle={this.onChangeTitle}
          postDescription={postDescription}
          postDescriptionLength={convertToMarkdown(postDescription).length}
          onChangeDescription={this.onChangeDescription}
          onSubmit={this.onSubmit}
          currentWorkspaceId={currentWorkspaceId}
          selectedContacts={selectedContacts}
          onAddContact={this.onAddContact}
          onToggleContact={this.onToggleContact}
          uploadedFiles={uploadedFiles}
          uploadedImages={uploadedImages}
          loading={submitData.loading || fileLoading}
          onDropAccepted={this.onDropAccepted}
          onRemoveFile={this.onRemoveFile}
          tags={tags}
          onAddTag={this.onAddTag}
          actions={actions}
          onAddAction={this.onAddAction}
          onRemoveAction={this.onRemoveAction}
          errors={errors}
        />
      </React.Fragment>
    );
  }

  private onAddAction = (action: IAction) => {
    this.setState(prevState => ({
      actions: [...prevState.actions, action]
    }));
  };

  private onRemoveAction = (action: IAction) => {
    this.setState(prevState => ({
      actions: prevState.actions.filter(
        (item: any) => item.label !== action.label
      )
    }));
  };

  private onDropAccepted = (attachments: any) => {
    const { uploadedFiles, uploadedImages } = this.state;

    const attached = attachments.reduce(
      (result: any, attachment: any) => {
        const attachmentExtension = this.getExtension(attachment.name);
        const isImage = IMAGE_EXTENSIONS.includes(attachmentExtension);

        if (isImage) {
          result.images.push(attachment);
        } else {
          result.files.push(attachment);
        }

        return result;
      },
      { files: [], images: [] }
    );

    this.setState({
      uploadedImages: uniqBy((item: any) => item.name, [
        ...uploadedImages,
        ...attached.images
      ]),
      uploadedFiles: uniqBy((item: any) => item.name, [
        ...uploadedFiles,
        ...attached.files
      ])
    });
  };

  private getExtension = (fileName: string) => {
    const extension = fileName.slice(fileName.lastIndexOf('.') + 1);

    if (extension === fileName) {
      return '';
    }

    return extension;
  };
}

export { CreationForm };
