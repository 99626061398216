import React from 'react';
import PropTypes from 'prop-types';

import {Mutation} from "react-apollo";
import {
    doRefreshTokenMutation
} from '../../../graphql'
import { TokenStore } from '../../../services';
import RefreshToken from './RefreshToken';

const RefreshTokenContainer = (props) => {
    const {
        tokenStore
    } = props;

    return (
        <Mutation mutation={doRefreshTokenMutation}>
            {(refreshToken) => {
                return <RefreshToken refreshToken={refreshToken} tokenStore={tokenStore} />                    
                }}
        </Mutation>
    )
};

RefreshTokenContainer.propTypes = {
    tokenStore: PropTypes.shape({})
};

RefreshTokenContainer.defaultProps = {
    tokenStore: TokenStore
};

export default RefreshTokenContainer;