import React from 'react';
import { AllReactions, ReactionList, ReactionType } from '../../Reactions';
import { Emoji } from '../../UI/Icons';
// @ts-ignore
import styles from './postReactions.module.scss';

interface Props {
  reactions: ReactionType[];
  isReactionBoxVisible: boolean;
  showReactionBox(): void;
  hideReactionBox(): void;
  onSelectReaction(reactionName: string): void;
}

const PostReactionsView = ({
  reactions,
  isReactionBoxVisible,
  showReactionBox,
  hideReactionBox,
  onSelectReaction
}: Props) => (
  <div className={styles.box}>
    <button type="button" className={styles.btn} onClick={showReactionBox}>
      <Emoji />
    </button>

    {isReactionBoxVisible && (
      <div className={styles.allReactionsBox} onMouseLeave={hideReactionBox}>
        <AllReactions onClick={onSelectReaction} />
      </div>
    )}

    <div className={styles.reactionList}>
      <ReactionList reactions={reactions} onSelectReaction={onSelectReaction} />
    </div>
  </div>
);

export { PostReactionsView };
