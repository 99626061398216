import TokenStore from '../../services/TokenStore';
import { resetIdentityToken } from '../../apollo';
import { ApiPath } from '../../services';

function uploadFunc(workspaceId, formData, onSuccess, onError) {
  window
    .fetch(`${ApiPath.url}/v1/workspaces/${workspaceId}/files`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'multipart/form-data', - FormData uses the same format a form would use if the encoding type were set to "multipart/form-data".
        Authorization: `Bearer ${TokenStore.getIdentityToken()}`
      },
      body: formData
    })
    .then(response => response.json())
    .then(res => {
      onSuccess(res);
    })
    .catch(err => {
      onError(err);
    });
}

function uploadFile(workspaceId, formData, onSuccess, onError) {
  if (!TokenStore.getIdentityToken()) {
    resetIdentityToken().then(() => {
      uploadFunc(workspaceId, formData, onSuccess, onError);
    });
  } else {
    uploadFunc(workspaceId, formData, onSuccess, onError);
  }
}

export default uploadFile;
