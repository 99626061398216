import { withQuery } from 'react-apollo';
import { getWorkspaceAndUser } from '../../graphql/local';
interface IWorkspaceAndUser {
  workspaceId: string;
  userId: string;
}
// eslint-disable-next-line import/prefer-default-export
export const withWorkspaceAndUser = withQuery<any, any, any, any>(
  getWorkspaceAndUser,
  {
    props: ({ data }: any): IWorkspaceAndUser => ({
      workspaceId: data.workspaceId,
      userId: data.userId
    })
  }
);
