import gql from 'graphql-tag';

export const ReactionFragment = gql`
  fragment ReactionFragment on Reaction {
    count
    imageUrl
    reactedUsers {
      id
      name
    }
    reactionName
  }
`;

export const GroupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    avatar(size: 30)
    isInstantMessage
    isMemberOfGroup
  }
`;

export const GroupMemberFragment = gql`
  fragment GroupMemberFragment on User {
    id
    name
    login
    avatar(size: 30)
  }
`;

export const CommentDataFragment = gql`
  fragment CommentDataFragment on Comment {
    id
    comment
    rawComment
    editedAt
    createdAt
    createdBy {
      __typename
      avatar(size: 30)
      ... on User {
        id
        name
      }
      ... on Group {
        id
        name
      }
      ... on Bot {
        id
        login
      }
    }
    reactions {
      ...ReactionFragment
    }
  }

  ${ReactionFragment}
`;

export const CommentsFragment = gql`
  fragment CommentsFragment on CommentConnection {
    edges {
      node {
        ...CommentDataFragment
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }

  ${CommentDataFragment}
`;

export const ThreadsFragment = gql`
  fragment ThreadsFragment on CommentThreadConnection {
    edges {
      node {
        id
        createdAt
        group {
          ...GroupFragment
          members {
            edges {
              node {
                ...GroupMemberFragment
              }
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }

  ${GroupFragment}
  ${GroupMemberFragment}
`;

export const PostFragment = gql`
  fragment PostFragment on Post {
    id
    rating
    createdBy {
      __typename
      avatar(size: 30)
      ... on User {
        id
        name
      }
      ... on Group {
        id
        name
      }
      ... on Bot {
        id
        login
      }
    }
    sharedBy {
      __typename
      avatar(size: 30)
      ... on User {
        name
      }
      ... on Group {
        name
      }
      ... on Bot {
        login
      }
    }
    createdAt
    sharedAt
    editedAt
    description
    rawDescription
    tags {
      tag
    }
    title
    commentThreads(first: 10, sortOrder: ASC) {
      ...ThreadsFragment
    }
    actions {
      ... on GetUrlAction {
        name
        label
        url
      }
    }
    attachments {
      fileId
      name
    }
    reactions {
      ...ReactionFragment
    }
  }

  ${ThreadsFragment}
  ${ReactionFragment}
`;

export const notificationsFragment = gql`
  fragment NotificationFragment on Notification {
    createdAt
    createdBy {
      login
      avatar(size: 30)
      ... on User {
        name
      }
    }
    id
    seen
    seenAt
    notificationObject {
      ... on CommentNotificationObject {
        commentId
        commentThreadId
        postId
      }
      ... on CommentThreadNotificationObject {
        commentThreadId
        postId
      }
      ... on GroupNotificationObject {
        groupId
      }
    }
    verb
  }
`;
