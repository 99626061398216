import React from 'react';
import { compose, graphql } from 'react-apollo';
import { getGroupsListQuery, getUsersListQuery } from '../../../../graphql';
import { SelectedContactItem } from '../../CreatePost.types';
import DropdownContacts from '../DropdownContacts';
import Item from '../Item';
import PlusBtn from '../PlusBtn';
import { Contacts } from './Contacts';
// @ts-ignore
import styles from './postIn.module.scss';

interface Props {
  currentWorkspaceId: string;
  selectedContacts: SelectedContactItem[];
  contactsException?: string[];

  // gql:
  usersData: any;
  groupsData: any;
  onAddContact(val: any): void;
  onToggleContact(e: any, val: any): void;
}

interface State {
  isDropdownVisible: boolean;
}

class PostInComponent extends React.Component<Props, State> {
  private wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      isDropdownVisible: false
    };

    this.wrapperRef = React.createRef();
  }

  public toggleDropdown = () => {
    const { isDropdownVisible } = this.state;

    this.setState({
      isDropdownVisible: !isDropdownVisible
    });
  };

  public handleClickOutside = (event: any) => {
    // @ts-ignore
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        isDropdownVisible: false
      });
    }
  };

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const { isDropdownVisible } = this.state;
    const {
      currentWorkspaceId,
      selectedContacts,
      onAddContact,
      onToggleContact,
      contactsException = [],
      usersData,
      groupsData
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <Item
          label="Post to:"
          content={
            <Contacts
              selectedContacts={selectedContacts}
              onAddContact={onAddContact}
              usersData={usersData}
              groupsData={groupsData}
              contactsException={contactsException}
            />
          }
          button={<PlusBtn onClick={this.toggleDropdown} />}
        />

        <div ref={this.wrapperRef}>
          {isDropdownVisible && (
            <DropdownContacts
              currentWorkspaceId={currentWorkspaceId}
              selectedContacts={selectedContacts}
              onToggleContact={onToggleContact}
              contactsException={contactsException}
              usersData={usersData}
              groupsData={groupsData}
            />
          )}
        </div>
      </div>
    );
  }
}

const withUsersAndGroups = compose(
  graphql(getUsersListQuery, {
    options: (props: Props) => {
      return {
        variables: {
          workspaceId: props.currentWorkspaceId
        },
        notifyOnNetworkStatusChange: true
      };
    },
    props: ({ data }) => ({
      usersData: data
    })
  }),
  graphql(getGroupsListQuery, {
    options: (props: Props) => {
      return {
        variables: {
          workspaceId: props.currentWorkspaceId,
          groupFilterType: 'GROUPS_USER_BELONG_TO'
        },
        notifyOnNetworkStatusChange: true
      };
    },
    props: ({ data }) => ({
      groupsData: data
    })
  })
);

const PostIn = withUsersAndGroups(PostInComponent);

export default PostIn;
