import { resetIdentityToken } from '../apollo';
import { TokenStore } from '../services';

const getIdentityTokenAsync = (() => {
  let pending: Promise<any> | null = null;
  return (): Promise<string> => {
    return new Promise(resolve => {
      const identityToken = TokenStore.getIdentityToken();
      resolve(identityToken);
    })
      .then((value: any) => {
        if (!!value) {
          return Promise.resolve(value);
        }
        if (!pending) {
          pending = resetIdentityToken();
        }
        return pending;
      })
      .then(value => {
        pending = null;
        return Promise.resolve(value);
      });
  };
})();

export { getIdentityTokenAsync };
