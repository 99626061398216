import React from 'react';

interface Props {
  width?: string;
}

const DragAndDropIcon = ({ width = '65px' }: Props) => (
  <svg width={width} viewBox="0 0 65 71" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-438.000000, -932.000000)">
        <g transform="translate(438.000000, 932.000000)">
          <g transform="translate(25.000000, 22.000000)">
            <g>
              <rect
                fill="#FFFFFF"
                opacity="0.754929315"
                x="0"
                y="0"
                width="40"
                height="49"
                rx="4"
              />
              <path
                d="M4,0 L36,0 C38.209139,-8.49901461e-16 40,1.790861 40,4 L40,45 C40,47.209139 38.209139,49 36,49 L4,49 C1.790861,49 2.705415e-16,47.209139 0,45 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z M5,2 C3.34314575,2 2,3.34314575 2,5 L2,44 C2,45.6568542 3.34314575,47 5,47 L35,47 C36.6568542,47 38,45.6568542 38,44 L38,5 C38,3.34314575 36.6568542,2 35,2 L5,2 Z"
                fill="#000000"
              />
            </g>
          </g>
          <g>
            <rect fill="#FFFFFF" x="0" y="0" width="40" height="49" rx="4" />
            <path
              d="M4,0 L36,0 C38.209139,-8.49901461e-16 40,1.790861 40,4 L40,45 C40,47.209139 38.209139,49 36,49 L4,49 C1.790861,49 2.705415e-16,47.209139 0,45 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z M5,2 C3.34314575,2 2,3.34314575 2,5 L2,44 C2,45.6568542 3.34314575,47 5,47 L35,47 C36.6568542,47 38,45.6568542 38,44 L38,5 C38,3.34314575 36.6568542,2 35,2 L5,2 Z"
              fill="#000000"
            />
          </g>
          <g
            transform="translate(7.000000, 11.000000)"
            fill="#0060D0"
            fillRule="nonzero"
          >
            <path d="M11.2648221,11.8271329 C11.6296747,11.4646154 11.6296747,10.8755245 11.2648221,10.513007 C10.8999696,10.1504895 10.3070842,10.1504895 9.94223168,10.513007 L4.99391912,15.4296503 C4.15019763,16.267972 3.69413196,17.3555245 3.69413196,18.5337063 C3.69413196,19.7118881 4.15019763,20.7994406 4.99391912,21.6377622 C6.72696868,23.3597203 9.53177258,23.3597203 11.2420189,21.6377622 L22.4156279,10.5356643 C23.5785953,9.38013986 24.2170873,7.83944056 24.2170873,6.20811189 C24.2170873,4.57678322 23.5785953,3.03608392 22.4156279,1.88055944 C19.9984798,-0.521118881 16.0991183,-0.521118881 13.6819702,1.88055944 L2.53116449,12.96 C1.02614777,14.4553846 0.205229553,16.4265734 0.205229553,18.5337063 C0.205229553,20.6408392 1.02614777,22.6346853 2.53116449,24.1074126 C4.03618121,25.6027972 6.02006689,26.4184615 8.14077227,26.4184615 C10.2614777,26.4184615 12.2681666,25.6027972 13.7503801,24.1074126 L18.6986926,19.1907692 C19.0635452,18.8282517 19.0635452,18.2391608 18.6986926,17.8766434 C18.3338401,17.5141259 17.7409547,17.5141259 17.3761022,17.8766434 L12.4277896,22.7932867 C11.2876254,23.9261538 9.75980541,24.5605594 8.14077227,24.5605594 C6.52173913,24.5605594 4.99391912,23.9261538 3.85375494,22.7932867 C2.71359076,21.6604196 2.07509881,20.1423776 2.07509881,18.5337063 C2.07509881,16.925035 2.71359076,15.406993 3.85375494,14.2741259 L15.0045607,3.19468531 C15.8254789,2.37902098 16.8972332,1.94853147 18.0373974,1.94853147 C19.1775616,1.94853147 20.2721192,2.40167832 21.0702341,3.19468531 C21.8911523,4.01034965 22.3244147,5.07524476 22.3244147,6.20811189 C22.3244147,7.34097902 21.868349,8.42853147 21.0702341,9.22153846 L9.89662511,20.3236364 C8.91608392,21.2979021 7.29705078,21.2979021 6.29370629,20.3236364 C5.81483734,19.8478322 5.54119793,19.2134266 5.54119793,18.5337063 C5.54119793,17.853986 5.81483734,17.2195804 6.29370629,16.7437762 L11.2648221,11.8271329 Z" />
          </g>
          <g transform="translate(33.000000, 39.000000)">
            <polygon
              fill="#71B77C"
              points="7.94031507e-13 19.4226348 5.63762285 11.4781314 9.54130687
               16.5383521 15.466814 8.65909091 23.195246 19.4226348"
            />
            <circle
              fill="#DBA704"
              cx="15.1193182"
              cy="2.18181818"
              r="2.18181818"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { DragAndDropIcon };
