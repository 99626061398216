import React from 'react';
import TextStylingInfo from '../../../TextStylingInfo';
import { Loader } from '../../../UI';
import Editor from '../../../UI/Editor/index';
import { COMMENT_MAX_LENGTH, LONG_COMMENT } from '../constants';
// @ts-ignore
import styles from './commentEditingForm.module.scss';

interface Props {
  commentText: string;
  loading: boolean;
  onSubmit: () => void;
  hasCommentText: boolean;
  error: string;
  mentions: Array<{}>;
  fetchMentions(): void;
  setCommentState(commentText: any): void;
  hideEditForm(): null;
}

function CommentEditingFormView(props: Props) {
  const {
    onSubmit,
    setCommentState,
    commentText,
    loading,
    hideEditForm,
    hasCommentText,
    error,
    mentions,
    fetchMentions
  } = props;

  return (
    <form>
      <Editor
        editorState={commentText}
        setEditorState={setCommentState}
        fieldClassName={styles.field}
        placeholder="Write comment..."
        isToolbar={false}
        onEnterFunc={onSubmit}
        mentions={mentions}
        fetchMentions={fetchMentions}
      />
      <div className={styles.additionalInfo}>
        {hasCommentText && <TextStylingInfo />}
        {error && error === LONG_COMMENT && (
          <div className={`error ${styles.errorBox}`}>
            Comment can not be longer than {COMMENT_MAX_LENGTH} symbols
          </div>
        )}
      </div>
      <div className={styles.buttonsBox}>
        <button
          type="button"
          onClick={hideEditForm}
          disabled={loading}
          className={styles.closeBtn}
        >
          Close
        </button>
        <button
          type="button"
          onClick={onSubmit}
          disabled={loading}
          className={styles.sendBtn}
        >
          Save changes
        </button>
      </div>
    </form>
  );
}

export default CommentEditingFormView;
