import React from 'react';
// @ts-ignore
import styles from './postIn.module.scss';

interface PropsItem {
  tag: {
    node: {
      avatar: string;
      name: string;
    };
  };
  key?: any;
  onRemove(key: any): void;
}

function ContactItem(props: PropsItem) {
  const { tag, key, onRemove } = props;

  if (!tag.node) {
    return null;
  }

  return (
    <span key={key} className={styles.contactItem}>
      <img src={tag.node.avatar} alt="" className={styles.contactAvatar} />
      <span className={styles.contactName}>{tag.node.name}</span>
      <button
        type="button"
        onClick={() => onRemove(key)}
        className={styles.contactRemoveBtn}
      />
    </span>
  );
}

export { ContactItem };
