import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, split, Observable } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from 'apollo-link-ws';

import { TokenStore, ApiPath } from '../services';
import Log from '../Log';
import { doRefreshTokenMutation } from '../graphql';
import { fragmentMatcher } from './fragmentMatcher';
import { createLocalState } from './localState';
import { logoutFunc } from '../Components/helpers';
import defaults from './defaults';
import { resolvers } from '../graphql/local';

const createAuthHeader = () => {
  const identityToken = TokenStore.getIdentityToken();
  if (identityToken) {
    return {
      Authorization: `Bearer ${identityToken}`
    };
  }
  return {};
};

const fetchIdentityToken = async () => {
  const { data } = await unboundClientInstance.mutate({
    mutation: doRefreshTokenMutation,
    variables: {
      refreshToken: TokenStore.getRefreshToken()
    }
  });

  if (!data || data.refreshToken.error) {
    logoutFunc();
    throw new Error(data.refreshToken.error);
  }
  return data.refreshToken.identityToken;
};

const resetIdentityToken = async () => {
  const identityToke = await fetchIdentityToken();
  Log.info(
    'Received a refreshed identity token, saving to the store',
    'thenRefreshToken'
  );
  TokenStore.storeIdentityToken(identityToke);
  return identityToke;
};

const cache = new InMemoryCache({ fragmentMatcher });
cache.writeData({
  data: {
    ...defaults
  }
});

const httpLink = new HttpLink({
  uri: ApiPath.gql
});

const wsLink = new WebSocketLink({
  uri: ApiPath.ws,
  options: {
    lazy: true,
    timeout: 30000,
    reconnect: true,
    connectionParams: async () => {
      let identityToken = TokenStore.getIdentityToken();
      if (!identityToken) {
        identityToken = await fetchIdentityToken();
      }

      return {
        Authorization: `Bearer ${identityToken}`
      };
    }
  }
});

// This client is just meant to be used internally for refreshing operations, it only has access to the HttpLink
const unboundClientInstance = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink
});

// Split link between WebSocket and HttpLink
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

// Token refresh
const thenRefreshToken = (operation, forward) => {
  return new Observable(observer => {
    resetIdentityToken()
      .then(() => {
        Log.trace('Retrying last failed request');
        operation.setContext({ headers: createAuthHeader() });

        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        };

        // Retry last failed request
        forward(operation).subscribe(subscriber);
      })
      .catch(error => {
        Log.error(
          'No refresh or client token available, we force user to login',
          error
        );
        // No refresh or client token available, we force user to login
        observer.error(error);
      });
  });
};

const onErrorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // For some reason the HTTP error codes are currently not traveling down from the server,
    // so for thhe time being if there's any error and we don't have an active identity token
    // we will refreshh
    if (graphQLErrors || networkError) {
      if (TokenStore.getRefreshToken() && !TokenStore.getIdentityToken()) {
        return thenRefreshToken(operation, forward);
      }
    }

    if (graphQLErrors) {
      Log.error('GraphQL errors received');
      graphQLErrors.forEach(err => {
        Log.error(`[GraphQL error]: ${err}`);
      });

      Log.trace('Refreshing connection');
      client.restartWebsocketConnection();
    }

    if (networkError) {
      Log.error(`[Network error]: ${networkError}`);
    }

    return null;
  }
);

const setTokenMiddleware = new ApolloLink((operation, forward) => {
  const token = TokenStore.getIdentityToken();
  if (token) {
    Log.trace(
      'Found a valid token in store, adding to operation',
      'setTokenMiddleware'
    );
    operation.setContext({ headers: createAuthHeader() });
  } else {
    Log.trace('Found no valid identity token', 'setTokenMiddleware');
    if (TokenStore.getRefreshToken()) {
      Log.trace('Requesting token refresh', 'setTokenMiddleware');
      return thenRefreshToken(operation, forward);
    }
  }
  return forward(operation);
});

const httpLinkWithMiddleware = ApolloLink.from([
  setTokenMiddleware,
  onErrorLink,
  createLocalState(cache),
  link
]);

const client = new ApolloClient({
  cache,
  link: httpLinkWithMiddleware,
  resolvers
});

client.restartWebsocketConnection = () => {
  if (wsLink) {
    Log.trace('restartWebsocketConnection begin');
    wsLink.subscriptionClient.close(true);
    Log.trace('restartWebsocketConnection after disconnect');
    wsLink.subscriptionClient.tryReconnect();
    Log.trace('restartWebsocketConnection end');
  }
};

client.reconnectWebsocket = () => {
  // reconnect ws and resubscribe
  wsLink.subscriptionClient.close(false, false);
};

export default client;
export { resetIdentityToken };
