import React from 'react';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import Modal from '../../UI/Modal/Modal';
import { InviteUserToWorkspaceWrap } from '../InviteUserToWorkspaceWrap';

interface Props {
  // gql:
  workspaceId: string;
}

interface State {
  isModalOpen: boolean;
}

class InviteToWorkspaceComponent extends React.Component<Props & any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isModalOpen: false
    };
  }

  public openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  public closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  public render() {
    const { workspaceId } = this.props;
    const { isModalOpen } = this.state;

    return (
      <React.Fragment>
        <button type="button" onClick={this.openModal}>
          Invite people
        </button>

        <Modal
          isModalOpen={isModalOpen}
          modalWidth="md"
          onBtnClose={this.closeModal}
        >
          <InviteUserToWorkspaceWrap
            workspaceId={workspaceId}
            onSuccessfulInvitation={this.closeModal}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const InnerInviteToWorkspace = withWorkspaceAndUser(InviteToWorkspaceComponent);

export { InnerInviteToWorkspace };
