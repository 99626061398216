import React, { Component } from 'react';
import { debounce } from 'throttle-debounce';
import Waypoint from 'react-waypoint';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { Loader, DropdownUserOrGroupItem as DropdownItem } from '../../../UI';

import { getUsersListQuery } from '../../../../graphql';
import Log from '../../../../Log';
import './GroupAddMembers.scss';
import fetchMoreUsersHelper from '../../../helpers/fetchMore/fetchMoreUsersHelper';

class GroupAddMembers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchEmpty: false,
      searchedUsers: [],
      searchValue: ''
    };

    this.searchMoreThrottled = debounce(500, this.searchMore);
  }

  searchMore = (value, fetchMore) => {
    if (!value) {
      return null;
    }

    fetchMore({
      variables: {
        userFilter: {
          nameFilter: {
            searchQuery: value
          }
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const searchedUsers = fetchMoreResult.users.edges;

        this.setState({
          isSearchEmpty: searchedUsers.length === 0,
          searchedUsers
        });
      }
    }).catch(err => {
      Log.error(
        `Error while fetching more users in group: ${err}`,
        'GroupAddMembers'
      );
    });

    return null;
  };

  handleMemberSearch(e, fetchMore) {
    this.setState({
      isSearchEmpty: false,
      searchValue: e.target.value
    });

    this.searchMoreThrottled(e.target.value, fetchMore);
  }

  render() {
    const {
      createGroupLoading,
      onCheckMember,
      memberIds,
      showNextScreen,
      currentUserId,
      currentWorkspaceId,
      onSubmit,
      fileLoading,
      onChangeGroupType,
      isGroupPublic
    } = this.props;

    const { searchValue, searchedUsers, isSearchEmpty } = this.state;

    return (
      <Query
        query={getUsersListQuery}
        variables={{
          workspaceId: currentWorkspaceId
        }}
        notifyOnNetworkStatusChange
      >
        {({ loading, error, data, fetchMore }) => {
          const usersPageInfo =
            (data && data.users && data.users.pageInfo) || {};
          const users = (data.users && data.users.edges) || {};

          const usersList = searchValue ? searchedUsers : users;
          const isSubmitBtnDisabled =
            createGroupLoading || memberIds.length === 0 || fileLoading;
          const isBackBtnDisabled = createGroupLoading || fileLoading;

          return (
            <div className="GroupAddMembers">
              <h2>Add members</h2>

              <div className="add-group-checkbox">
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={isGroupPublic}
                    onChange={onChangeGroupType}
                  />
                  <div className="checkbox-view">
                    <span className="text-checked">Public</span>
                    <span className="text-unchecked">Private</span>
                    <span className="slider">
                      <span />
                      <span />
                      <span />
                    </span>
                  </div>
                </div>
              </div>

              <input
                type="text"
                placeholder="Search for a member"
                className="new-group-search"
                onChange={e => this.handleMemberSearch(e, fetchMore)}
                disabled={createGroupLoading}
                value={searchValue}
                maxLength={80}
              />

              <div className="add-group-dropdown">
                {!error &&
                  usersList &&
                  usersList.map(item => {
                    if (!item.node) {
                      return null;
                    }

                    if (item.node.id === currentUserId) {
                      return null;
                    }

                    return (
                      <DropdownItem
                        onCheck={e => onCheckMember(e, item.node.id)}
                        item={item.node}
                        key={item.node.id}
                        isSelected={!!memberIds.find(id => id === item.node.id)}
                        disabled={false}
                      />
                    );
                  })}

                {!loading && !error && isSearchEmpty && (
                  <div className="cntr pt10">No matches found</div>
                )}
                {loading && (
                  <div className="cntr">
                    <Loader width="40px" />
                  </div>
                )}
                {error && <div className="error cntr">Error</div>}
                {!searchValue && (
                  <Waypoint
                    onEnter={() =>
                      fetchMoreUsersHelper(loading, fetchMore, usersPageInfo)
                    }
                  />
                )}
              </div>

              <div className="add-member-buttons">
                <button onClick={showNextScreen} disabled={isBackBtnDisabled}>
                  Back
                </button>
                <button
                  type="button"
                  onClick={onSubmit}
                  disabled={isSubmitBtnDisabled}
                >
                  Create Team
                </button>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

GroupAddMembers.propTypes = {
  showNextScreen: PropTypes.func.isRequired,
  onCheckMember: PropTypes.func.isRequired,
  createGroupLoading: PropTypes.bool.isRequired,
  memberIds: PropTypes.arrayOf(PropTypes.string),
  currentUserId: PropTypes.string.isRequired,
  currentWorkspaceId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fileLoading: PropTypes.bool.isRequired,
  onChangeGroupType: PropTypes.func.isRequired,
  isGroupPublic: PropTypes.bool.isRequired
};

GroupAddMembers.defaultProps = {
  memberIds: []
};

export default GroupAddMembers;
