import React from 'react';

const ArrowRight = () => (
  <svg width="12px" viewBox="0 0 12 19" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1047.000000, -598.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <polygon
          points="1048.91167 598 1047.00019 599.79998 1055.17453
          607.499991 1047 615.20002 1048.91147 617 1059 607.499909"
        />
      </g>
    </g>
  </svg>
);

export { ArrowRight };
