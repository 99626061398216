import React from 'react';
import './style.scss';
import notFoundImg from '../../../assets/img/notFound.svg';

const EmptySearchList = () => {
  return (
    <div className="sidebar-empty-search-list">
      <h3>
        No matches found.
        <br />
        Maybe it&apos;s a typo?
      </h3>
      <img src={notFoundImg} alt="" className="not-found-img" />
    </div>
  );
};
export default EmptySearchList;
