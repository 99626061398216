import React from 'react';
// @ts-ignore
import styles from './postModalFooter.module.scss';

interface Props {
  btnCaption: string;
  additionalBlock?: any;
  disabled?: boolean;
  onClick(): void;
}

const PostModalFooter = ({
  btnCaption,
  additionalBlock = null,
  disabled = false,
  onClick
}: Props) => (
  <div className={styles.footer}>
    <div>{additionalBlock}</div>
    <div>
      <button
        type="button"
        disabled={disabled}
        className={styles.btn}
        onClick={onClick}
      >
        {btnCaption}
      </button>
    </div>
  </div>
);

export { PostModalFooter };
