import React from 'react';

const SendArrow = () => (
  <svg
    width="20px"
    height="19px"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1098.000000, -899.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g transform="translate(306.000000, 610.000000)">
          <g transform="translate(22.000000, 281.000000)">
            <path
              d="M775.265285,18.8431568 L786.701754,17.5
             L775.266362,16.1569697 L770.143332,9.16797087
              C769.946194,8.90049044 769.953337,8.53069508
               770.160475,8.27052285 C770.366184,8.01035062
                770.719033,7.92849868 771.01474,8.06589301
                 L789.585724,16.8357434 C789.838575,16.955598
                  790,17.2143086 790,17.4993287 C790,17.7843489
                   789.838575,18.0430595 789.584296,18.1629141
                    L771.013312,26.9327645 C770.9176,26.9780754
                     770.816173,27 770.714747,27 C770.504752,27
                      770.297614,26.9049933 770.159046,26.7281346
                       C769.953337,26.4694241 769.946194,26.0981671
                        770.143332,25.8306866 L775.265285,18.8431568 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SendArrow;
