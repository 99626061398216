import React from 'react';
import {
  logoutFunc,
  RefreshToken as RefreshTokenComponent
} from '../../Components/helpers';
import PageHeader from '../../Components/PageHeader';
import { Workspaces } from '../../Components/Workspaces';
import TokenStore from '../../services';
// @ts-ignore
import styles from '../pages.module.scss';

const WorkspacesPage = () => {
  if (!TokenStore.getRefreshToken()) {
    return logoutFunc();
  }

  if (!TokenStore.getIdentityToken()) {
    return <RefreshTokenComponent tokenStore={TokenStore} />;
  }

  return (
    <div className={styles.wrapper}>
      <PageHeader />

      <div className={styles.containerFlex}>
        <Workspaces />
      </div>
    </div>
  );
};

export { WorkspacesPage };
