import gql from 'graphql-tag';
import {
  CommentsFragment,
  CommentDataFragment,
  ThreadsFragment,
  ReactionFragment
} from './fragments';

export const commentsQuery = gql`
  query Comments($after: String, $workspaceId: UUID!, $commentThreadId: UUID!) {
    comments(
      first: 10
      after: $after
      workspaceId: $workspaceId
      commentThreadId: $commentThreadId
      sortOrder: DESC
    ) {
      ...CommentsFragment
    }
  }

  ${CommentsFragment}
`;

export const addCommentMutation = gql`
  mutation AddComment(
    $comment: String!
    $workspaceId: UUID!
    $commentThreadId: UUID!
  ) {
    createCommentOnPost(
      createCommentOnPostRequest: {
        comment: $comment
        workspaceId: $workspaceId
        commentThreadId: $commentThreadId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const editCommentMutation = gql`
  mutation EditComment(
    $workspaceId: UUID!
    $commentId: UUID!
    $userId: UUID!
    $commentThreadId: UUID!
    $comment: String!
  ) {
    editCommentOnPost(
      editCommentOnPostRequest: {
        comment: $comment
        workspaceId: $workspaceId
        commentThreadId: $commentThreadId
        commentId: $commentId
        userId: $userId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const deleteCommentMutation = gql`
  mutation DeleteCommentOnPost(
    $workspaceId: UUID!
    $commentThreadId: UUID!
    $commentId: UUID!
    $userId: UUID!
  ) {
    deleteCommentOnPost(
      deleteCommentOnPostRequest: {
        workspaceId: $workspaceId
        commentThreadId: $commentThreadId
        commentId: $commentId
        userId: $userId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const commentsSubscription = gql`
  subscription Comments(
    $workspaceId: UUID!
    $postId: UUID!
    $commentThreadId: UUID!
  ) {
    comments(
      workspaceId: $workspaceId
      postId: $postId
      commentThreadId: $commentThreadId
    ) {
      ... on Comment {
        ...CommentDataFragment
      }
      ... on DeletedComment {
        id
      }
      ... on CommentReactionsUpdatedSubscription {
        id
        reactions {
          ...ReactionFragment
        }
      }
    }
  }

  ${CommentDataFragment}
  ${ReactionFragment}
`;

export const commentThreadsQuery = gql`
  query CommentThreads($workspaceId: UUID!, $postId: UUID!, $after: String) {
    commentThreads(
      workspaceId: $workspaceId
      postId: $postId
      after: $after
      first: 10
    ) {
      ...ThreadsFragment
    }
  }

  ${ThreadsFragment}
`;
