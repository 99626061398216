import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';
import checkBoxImg from '../../../assets/img/check_i.png';

const DropdownUserOrGroupItem = props => {
  const { item, isSelected, disabled, onCheck } = props;

  const itemClassName = classNames('dropdown-post', {
    disabled: disabled && !isSelected
  });

  return (
    <div className={itemClassName}>
      <input
        type="checkbox"
        checked={isSelected}
        className="input-checkbox"
        onChange={onCheck}
      />
      <img alt="" src={item.avatar} className="avatar" />
      <span className="name">{item.name}</span>

      {isSelected && (
        <img className="checkbox-view" src={checkBoxImg} alt="img" />
      )}
    </div>
  );
};

DropdownUserOrGroupItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  onCheck: PropTypes.func
};

DropdownUserOrGroupItem.defaultProps = {
  isSelected: false,
  disabled: false,
  onCheck: () => {}
};

export default DropdownUserOrGroupItem;
