import React from 'react';
import { Mutation } from 'react-apollo';
import { createPostMutation } from '../../../graphql';
import { Pencil } from '../../UI/Icons';
import Modal from '../../UI/Modal/Modal';
import CreationForm from '../Components/CreationForm';
// @ts-ignore
import styles from './createNewPost.module.scss';

interface Props {
  currentWorkspaceId: string;
}

interface State {
  isModalOpen: boolean;
}

class CreateNewPost extends React.Component<Props, State> {
  public state = {
    isModalOpen: false
  };

  public closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  public openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  public render() {
    const { currentWorkspaceId } = this.props;
    const { isModalOpen } = this.state;

    return (
      <React.Fragment>
        <button
          type="button"
          className={styles.openModalBtn}
          onClick={this.openModal}
        >
          <Pencil />
          New Post
        </button>

        <Modal
          isModalOpen={isModalOpen}
          onBtnClose={this.closeModal}
          modalWidth="md"
          content={
            <Mutation mutation={createPostMutation}>
              {(submitPostFunc: any, submitData: any) => (
                <CreationForm
                  currentWorkspaceId={currentWorkspaceId}
                  submitPostFunc={submitPostFunc}
                  submitData={submitData}
                  closeModal={this.closeModal}
                />
              )}
            </Mutation>
          }
        />
      </React.Fragment>
    );
  }
}

export { CreateNewPost };
