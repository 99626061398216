import React from 'react';
import { Nav, NavItem, Tab } from 'react-bootstrap';
import Waypoint from 'react-waypoint';

import { GROUPS_TAB, USERS_TAB } from '../../constants';
import AddGroup from './AddGroup';
import EmptySearchList from './EmptySearchList';
import Groups from './Groups';
import './Sidebar.scss';
import { FoundGroupTypes, GroupTypes, UserTypes } from './types';
import Users from './Users';

interface Props {
  searchValue: string;
  activeTab: string;
  groups: GroupTypes[];
  foundGroups: FoundGroupTypes;
  usersList: UserTypes[];
  loadingGroups: boolean;
  loadingUsers: boolean;
  activeId: string;
  workspaceId: string;
  isCreateGroupModalShown: boolean;
  isNotFoundUsersBlockShown: boolean;
  isNotFoundGroupsBlockShown: boolean;
  onSearch(e: any): void;
  onClearSearch(): void;
  onChangeTab(activeTab: string): void;
  toggleModal(): void;
  setActiveId(activeId: string): void;
  fetchMoreGroups(): void;
  fetchMoreFoundGroups(): void;
  fetchMoreUsers(): void;
}

const SidebarView = ({
  searchValue,
  activeTab,
  groups,
  foundGroups,
  usersList,
  loadingGroups,
  loadingUsers,
  activeId,
  workspaceId,
  isCreateGroupModalShown,
  isNotFoundUsersBlockShown,
  isNotFoundGroupsBlockShown,

  onSearch,
  onClearSearch,
  onChangeTab,
  toggleModal,
  setActiveId,
  fetchMoreGroups,
  fetchMoreFoundGroups,
  fetchMoreUsers
}: Props) => (
  <div className="sidebar">
    <div className="work-spaces-search">
      <div className="sidebar-search">
        <input
          className="channels-search"
          type="text"
          value={searchValue}
          placeholder="Search"
          onChange={onSearch}
          maxLength={80}
        />
        {!!searchValue && (
          <button type="button" className="clear-btn" onClick={onClearSearch} />
        )}
      </div>
    </div>

    <Tab.Container
      id="sidebar-tabs"
      activeKey={activeTab}
      // @ts-ignore
      onSelect={onChangeTab}
    >
      <React.Fragment>
        <Nav className="sidebar-tab-nav">
          <NavItem eventKey={GROUPS_TAB}>
            <span className="tab-nav-content tab-nav-content-groups">
              <span className="icon-groups" />
              Teams
            </span>
            <button
              type="button"
              className="add-workspace-btn"
              onClick={toggleModal}
            />
          </NavItem>
          <NavItem eventKey={USERS_TAB}>
            <span className="tab-nav-content">
              <span className="icon-users" />
              Users
            </span>
          </NavItem>
        </Nav>

        <Tab.Content animation={false}>
          <Tab.Pane eventKey={GROUPS_TAB}>
            <div className="work-spaces-overlay">
              {isNotFoundGroupsBlockShown && !!searchValue && !loadingGroups ? (
                <EmptySearchList />
              ) : (
                <React.Fragment>
                  <Groups
                    groups={groups}
                    foundGroups={foundGroups}
                    loadingGroups={loadingGroups}
                    activeId={activeId}
                    setActiveId={setActiveId}
                    workspaceId={workspaceId}
                    isSearchMode={!!searchValue}
                  />
                  {!loadingGroups && activeTab === GROUPS_TAB && (
                    <React.Fragment>
                      {!searchValue && <Waypoint onEnter={fetchMoreGroups} />}
                      {searchValue && (
                        <Waypoint onEnter={fetchMoreFoundGroups} />
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey={USERS_TAB}>
            <div className="work-spaces-overlay">
              {isNotFoundUsersBlockShown && !!searchValue && !loadingUsers ? (
                <EmptySearchList />
              ) : (
                <React.Fragment>
                  <Users
                    users={usersList}
                    loadingUsers={loadingUsers}
                    activeId={activeId}
                    setActiveId={setActiveId}
                  />
                  {!searchValue && !loadingUsers && activeTab === USERS_TAB && (
                    <Waypoint onEnter={fetchMoreUsers} />
                  )}
                </React.Fragment>
              )}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </React.Fragment>
    </Tab.Container>

    <AddGroup
      isCreateGroupModalShown={isCreateGroupModalShown}
      toggleModal={toggleModal}
      currentWorkspaceId={workspaceId}
    />
  </div>
);

export { SidebarView };
