import React from 'react';
import { Register } from '../../Components/Register';

interface Props {
  changeLoggedState(isLogged: boolean): void;
}

const RegisterPage = (props: Props) => <Register {...props} />;

export { RegisterPage };
