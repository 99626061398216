import React from 'react';
import { logoutFunc } from '../helpers';
import { Logo } from '../UI/Icons';
// @ts-ignore
import styles from './pageHeader.module.scss';

const PageHeader = () => (
  <div className={styles.wrapper}>
    <button type="button" onClick={logoutFunc}>
      <Logo width="66px" />
    </button>
  </div>
);

export { PageHeader };
