import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExpandedCard from '../ExpandedCard';
import CompressedCard from '../CompressedCard';
import { CreateSharedPost } from '../../CreatePost';
import { IMAGE_EXTENSIONS } from '../../../constants';

class IndexCard extends Component {
  static contextTypes = {
    currentUserData: PropTypes.shape({
      id: PropTypes.string
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      showSharedCardModal: false
    };
  }

  componentDidMount() {
    const { subscribeToPost, item } = this.props;

    subscribeToPost(item.id);
  }

  toggleShareModal = () => {
    const { showSharedCardModal } = this.state;
    this.setState({
      showSharedCardModal: !showSharedCardModal
    });
  };

  getExtension = fileName => {
    const extension = fileName.slice(fileName.lastIndexOf('.') + 1);

    if (extension === fileName) {
      return '';
    }

    return extension;
  };

  render() {
    const { currentUserData } = this.context;
    const {
      item,
      isCardsViewExpanded,
      fetchMoreThreads,
      currentWorkspaceId,
      showErrorOnSubscription,
      selectedTabId
    } = this.props;
    const { showSharedCardModal } = this.state;

    const srcCreatorAvatar =
      (item.sharedBy && item.sharedBy.avatar) ||
      //   (item.post.origin &&
      //     `${AVATAR_URL_PART}/avatar/service/${item.post.origin.toLowerCase()}.png`) ||
      (item.createdBy && item.createdBy.avatar) ||
      '';
    const creatorName =
      (item.sharedBy && item.sharedBy.name) ||
      (item.createdBy && item.createdBy.name) ||
      '';
    const starRating = item.rating;

    const attached = item.attachments.reduce(
      (cache, attachment) => {
        const attachmentExtension = this.getExtension(attachment.name);
        const isImage = IMAGE_EXTENSIONS.includes(attachmentExtension);

        if (isImage) {
          cache.images.push(attachment);
        } else {
          cache.files.push(attachment);
        }

        return cache;
      },
      { files: [], images: [] }
    );

    const userId = currentUserData && currentUserData.id;

    return (
      <div
        className={isCardsViewExpanded ? 'IndexCard expanded' : 'IndexCard'}
        data-post-id={item.id}
      >
        <CreateSharedPost
          post={item}
          isSharedPostModalOpen={showSharedCardModal}
          toggleShareModal={this.toggleShareModal}
          currentUserId={userId}
          currentWorkspaceId={currentWorkspaceId}
          postView={
            <ExpandedCard
              inSharedModal
              item={item}
              toggleShareModal={this.toggleShareModal}
              srcCreatorAvatar={srcCreatorAvatar}
              fetchMoreThreads={() => {}}
              starRating={starRating}
              showErrorOnSubscription={() => {}}
              currentWorkspaceId={currentWorkspaceId}
              attachedFiles={attached.files}
              attachedImages={attached.images}
              userId={userId}
            />
          }
        />

        <ExpandedCard
          item={item}
          toggleShareModal={this.toggleShareModal}
          srcCreatorAvatar={srcCreatorAvatar}
          fetchMoreThreads={fetchMoreThreads}
          starRating={starRating}
          showErrorOnSubscription={showErrorOnSubscription}
          currentWorkspaceId={currentWorkspaceId}
          attachedFiles={attached.files}
          attachedImages={attached.images}
          selectedTabId={selectedTabId}
          userId={userId}
        />

        <CompressedCard
          item={item}
          srcCreatorAvatar={srcCreatorAvatar}
          creatorName={creatorName}
          starRating={starRating}
          attachedFilesLength={attached.files.length}
          attachedImagesLength={attached.images.length}
        />
      </div>
    );
  }
}

IndexCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  isCardsViewExpanded: PropTypes.bool,
  fetchMoreThreads: PropTypes.func.isRequired,
  currentWorkspaceId: PropTypes.string.isRequired,
  showErrorOnSubscription: PropTypes.func.isRequired
};

IndexCard.defaultProps = {
  isCardsViewExpanded: false
};

export default IndexCard;
