import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import { getDayOrDate } from '../../../utils/dateUtils';
import Loader from '../../UI/Loader';
import { NotificationItem } from '../NotificationItem';
import './NotificationList.scss';

interface Props {
  onNotificationClick: (v: string) => void;
  fetchMoreNotifications: () => void;
  notSeenNotificationAmount: number;
  notifications: any[];
  onMarkAsRedAll: () => void;
  hasNextPage: boolean;
  notificationsLoading: boolean;
}

const mapNotificationByDate = (notifications: any[]) =>
  notifications.reduce(
    (cache: any, n: any) => {
      const date = getDayOrDate(n.node.createdAt);
      if (!cache.grouped[date]) {
        cache.grouped[date] = true;
        cache.ordered.push({
          node: {
            id: `${n.node.id}-${n.node.createdAt}`,
            text: date,
            type: 'date'
          }
        });
      }
      cache.ordered.push(n);
      return cache;
    },
    { grouped: {}, ordered: [] }
  ).ordered;

class NotificationList extends Component<Props> {
  public render() {
    const {
      onNotificationClick,
      notifications,
      onMarkAsRedAll,
      notSeenNotificationAmount,
      fetchMoreNotifications,
      notificationsLoading,
      hasNextPage
    } = this.props;

    const notificationsWithDates = mapNotificationByDate(notifications);

    return (
      <div className="notifications-dropdown">
        <div className="body-block">
          <div className="notification-body-overlay" />
          <div className="title-separator">
            {notSeenNotificationAmount > 0 ? (
              <button
                type="button"
                className="read-all"
                onClick={onMarkAsRedAll}
              >
                Mark all as read
              </button>
            ) : (
              <div className="no-unread-notifications">
                No Unread Notifications.
              </div>
            )}
          </div>
          <div className="body-block-all-n">
            {notificationsWithDates.map((item: any) => (
              <NotificationItem
                key={item.node.id}
                item={item.node}
                onNotificationClick={() => onNotificationClick(item.node)}
              />
            ))}
            {notificationsLoading && (
              <div className="notifications-loader">
                <Loader width="35px" />
              </div>
            )}
            {!notificationsLoading && hasNextPage && (
              <Waypoint onEnter={() => fetchMoreNotifications()} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export { NotificationList };
