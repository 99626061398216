import React from 'react';

interface Props {
  width?: string;
}

const Checkmark = ({ width = '22px' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 38 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-593.000000, -1265.000000)" fill="currentColor">
        <polygon
          points="626.717609 1265 605.393468 1285.58925 597.220921
         1277.84509 593 1282.10843 605.267601 1294 631 1269.17639"
        />
      </g>
    </g>
  </svg>
);

export { Checkmark };
