import React, { Component } from 'react';
import { debounce } from 'throttle-debounce';

import { pathOr } from 'ramda';
import { compose, graphql } from 'react-apollo';
import {
  withNetworkStateQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { GROUPS_TAB, USERS_TAB } from '../../constants';
import {
  getGroupsListQuery,
  getUsersListQuery,
  groupsSubscription
} from '../../graphql';
import Log from '../../Log';
import { FeedApi, getFilters } from '../../services';
import fetchMoreGroupsHelper from '../helpers/fetchMore/fetchMoreGroupsHelper';
import fetchMoreUsersHelper from '../helpers/fetchMore/fetchMoreUsersHelper';
import {
  GROUPS_USER_BELONG_TO,
  PUBLIC_GROUP_USER_NOT_BELONG
} from './constants';
import { SidebarView } from './SidebarView';
import { FoundGroupDataTypes, UserTypes } from './types';

interface Props {
  groupsData: any;
  usersData: any;
  workspaceId: string;
  userId: string;
  isOnline: boolean;
}

interface State {
  foundUsers: UserTypes[];
  foundGroupsData: FoundGroupDataTypes;
  searchValue: string;
  searchType: string;
  isCreateGroupModalShown: boolean;
  activeId: string;
  activeTab: string;
  isNotFoundGroupsBlockShown: boolean;
  isNotFoundUsersBlockShown: boolean;
}

const emptySearchState = {
  foundUsers: [],
  foundGroupsData: {
    own: {
      edges: [],
      pageInfo: {}
    },
    public: {
      edges: [],
      pageInfo: {}
    }
  },
  searchValue: '',
  searchType: GROUPS_USER_BELONG_TO,
  isNotFoundGroupsBlockShown: false,
  isNotFoundUsersBlockShown: false
};

class SidebarComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { filterByGroupState, filterByUserState } = getFilters();

    const activeId = filterByGroupState || filterByUserState || '';
    const activeTab = filterByUserState ? USERS_TAB : GROUPS_TAB;

    this.state = {
      ...emptySearchState,
      isCreateGroupModalShown: false,
      activeId,
      activeTab
    };

    this.onSearch = this.onSearch.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);

    this.searchMore = debounce(500, this.searchMore);
  }

  public componentDidMount() {
    this.subscribeToMoreGroups();

    FeedApi.subscribe('GROUP', 'Groups', v => {
      this.setState({ activeId: v });
    });
  }

  public componentDidUpdate(prevProps: Props) {
    const { isOnline, groupsData } = this.props;

    if (isOnline && prevProps.isOnline !== isOnline) {
      groupsData.refetch();
    }
  }

  public componentWillUnmount() {
    FeedApi.unsubscribe('GROUP', 'Groups');
  }

  public subscribeToMoreGroups = () => {
    const { workspaceId, userId: currentUserId, groupsData } = this.props;

    groupsData.subscribeToMore({
      document: groupsSubscription,
      variables: {
        workspaceId
      },
      updateQuery: (prev: any, { subscriptionData }: any) => {
        if (
          !subscriptionData.data ||
          !subscriptionData.data.groups ||
          !subscriptionData.data.groups.__typename
        ) {
          return prev;
        }

        const { groupId, userId } = subscriptionData.data.groups;

        if (userId !== currentUserId) {
          return prev;
        }

        if (subscriptionData.data.groups.__typename === 'RemoveUserFromGroup') {
          this.refetchFoundGroups();
          this.fetchCorrectFeed(groupId);

          const groupEdges = prev.groups.edges.filter(
            (item: any) => item.node.id !== groupId
          );

          return {
            groups: {
              ...prev.groups,
              edges: groupEdges
            }
          };
        }

        if (subscriptionData.data.groups.__typename === 'AddUserToGroup') {
          this.refetchFoundGroups();
        }

        groupsData.refetch();

        return prev;
      },
      onError: (err: any) => {
        Log.error(`Error retrieving subscription: ${err}`, 'Groups');
      }
    });

    return null;
  };

  public render() {
    const { groupsData, usersData, workspaceId } = this.props;

    const {
      activeId,
      foundUsers,
      foundGroupsData,
      searchValue,
      isCreateGroupModalShown,
      activeTab,
      isNotFoundGroupsBlockShown,
      isNotFoundUsersBlockShown
    } = this.state;

    if (groupsData.error) {
      Log.graphQLError(groupsData.error);
    }

    if (usersData.error) {
      Log.graphQLError(usersData.error);
    }

    const groups = pathOr([], ['groups', 'edges'], groupsData);
    const usersAll = pathOr([], ['users', 'edges'], usersData);

    const foundGroups = {
      own: foundGroupsData.own.edges,
      public: foundGroupsData.public.edges
    };

    const usersList = searchValue ? foundUsers : usersAll;

    return (
      <SidebarView
        searchValue={searchValue}
        activeTab={activeTab}
        groups={groups}
        foundGroups={foundGroups}
        usersList={usersList}
        loadingGroups={groupsData.loading}
        loadingUsers={usersData.loading}
        activeId={activeId}
        workspaceId={workspaceId}
        isCreateGroupModalShown={isCreateGroupModalShown}
        isNotFoundUsersBlockShown={isNotFoundUsersBlockShown}
        isNotFoundGroupsBlockShown={isNotFoundGroupsBlockShown}
        onSearch={this.onSearch}
        onClearSearch={this.onClearSearch}
        onChangeTab={this.onChangeTab}
        toggleModal={this.toggleModal}
        setActiveId={this.setActiveId}
        fetchMoreGroups={this.fetchMoreGroups}
        fetchMoreFoundGroups={this.fetchMoreFoundGroups}
        fetchMoreUsers={this.fetchMoreUsers}
      />
    );
  }

  private onSearch(e: any) {
    const { value } = e.target;
    const prevValue = this.state.searchValue;

    if (!value) {
      return this.setState({
        ...emptySearchState
      });
    }

    this.setState(
      {
        searchValue: value,
        searchType: GROUPS_USER_BELONG_TO
      },
      () => this.searchMore(prevValue)
    );
  }

  private onClearSearch() {
    this.setState({
      ...emptySearchState
    });
  }

  private toggleModal = () => {
    const { isCreateGroupModalShown } = this.state;
    this.setState({
      isCreateGroupModalShown: !isCreateGroupModalShown
    });
  };

  private searchMore = (prevValue: string) => {
    const {
      searchValue,
      isNotFoundGroupsBlockShown,
      isNotFoundUsersBlockShown
    } = this.state;

    if (!searchValue) {
      return null;
    }

    const isFetchGroupsNeeded = !(
      isNotFoundGroupsBlockShown && prevValue.length < searchValue.length
    );
    const isFetchUsersNeeded = !(
      isNotFoundUsersBlockShown && prevValue.length < searchValue.length
    );

    if (isFetchUsersNeeded) {
      this.searchUsers();
    }

    if (isFetchGroupsNeeded) {
      this.searchGroups();
    }
  };

  private searchUsers = () => {
    const { searchValue } = this.state;
    const { usersData } = this.props;

    usersData.fetchMore({
      variables: {
        userFilter: {
          nameFilter: {
            searchQuery: searchValue
          }
        }
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        const foundUsers = fetchMoreResult.users.edges;
        const isNotFoundUsersBlockShown = foundUsers.length === 0;

        this.setState({
          foundUsers,
          isNotFoundUsersBlockShown
        });
      }
    });
  };

  private searchGroups = () => {
    const { groupsData } = this.props;
    const { searchValue, searchType, foundGroupsData } = this.state;

    groupsData.fetchMore({
      variables: {
        groupNameFilter: {
          name: searchValue
        },
        groupFilterType: searchType
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        const groupsResult = fetchMoreResult.groups;

        if (searchType === PUBLIC_GROUP_USER_NOT_BELONG) {
          const isFoundOwnGroupsEmpty = foundGroupsData.own.edges.length === 0;
          const isFoundPublicGroupsEmpty = groupsResult.edges.length === 0;
          const isNotFoundGroupsBlockShown =
            isFoundPublicGroupsEmpty && isFoundOwnGroupsEmpty;

          return this.setState({
            foundGroupsData: {
              ...foundGroupsData,
              public: groupsResult
            },
            isNotFoundGroupsBlockShown
          });
        }

        this.setState({
          foundGroupsData: {
            public: {
              edges: [],
              pageInfo: {}
            },
            own: groupsResult
          }
        });

        if (groupsResult.edges.length < 10) {
          this.setState(
            {
              searchType: PUBLIC_GROUP_USER_NOT_BELONG
            },
            this.searchGroups
          );
        }
      }
    });
  };

  private fetchMoreGroups = () => {
    const {
      groupsData,
      groupsData: { loading, fetchMore }
    } = this.props;

    const groupsPageInfo = pathOr({}, ['groups', 'pageInfo'], groupsData);
    return fetchMoreGroupsHelper(loading, fetchMore, groupsPageInfo);
  };

  private fetchMoreFoundGroups = () => {
    const {
      groupsData: { loading, fetchMore }
    } = this.props;
    const { searchValue, searchType, foundGroupsData } = this.state;

    const foundGroupsPageInfo =
      searchType === GROUPS_USER_BELONG_TO
        ? foundGroupsData.own.pageInfo
        : foundGroupsData.public.pageInfo;

    return fetchMoreGroupsHelper(
      loading,
      fetchMore,
      foundGroupsPageInfo,
      {
        groupNameFilter: {
          name: searchValue
        },
        groupFilterType: searchType
      },
      this.onSuccessFetchMoreSearchedGroups
    );
  };

  private onSuccessFetchMoreSearchedGroups = (groupResult: any) => {
    const { searchType, foundGroupsData } = this.state;

    if (searchType === PUBLIC_GROUP_USER_NOT_BELONG) {
      return this.setState({
        foundGroupsData: {
          ...foundGroupsData,
          public: {
            ...groupResult.groups,
            edges: [
              ...foundGroupsData.public.edges,
              ...groupResult.groups.edges
            ]
          }
        }
      });
    }

    this.setState({
      foundGroupsData: {
        public: {
          edges: [],
          pageInfo: {}
        },
        own: {
          ...groupResult.groups,
          edges: [...foundGroupsData.own.edges, ...groupResult.groups.edges]
        }
      }
    });

    if (!groupResult.groups.pageInfo.hasNextPage) {
      this.setState(
        {
          searchType: PUBLIC_GROUP_USER_NOT_BELONG
        },
        this.searchGroups
      );
    }
  };

  private fetchMoreUsers = () => {
    const { usersData } = this.props;

    const usersPageInfo = pathOr({}, ['users', 'pageInfo'], usersData);

    fetchMoreUsersHelper(usersData.loading, usersData.fetchMore, usersPageInfo);
  };

  private setActiveId = (activeId: string) => {
    this.setState({
      activeId
    });
  };

  private onChangeTab = (activeTab: string) => {
    this.setState({
      activeTab
    });
  };

  private refetchFoundGroups = () => {
    const { searchValue } = this.state;

    if (!searchValue) {
      return null;
    }

    this.setState(
      {
        foundUsers: [],
        foundGroupsData: {
          own: {
            edges: [],
            pageInfo: {}
          },
          public: {
            edges: [],
            pageInfo: {}
          }
        },
        searchType: GROUPS_USER_BELONG_TO
      },
      this.searchGroups
    );
  };

  private fetchCorrectFeed = (groupId: string) => {
    const { filterByGroupState } = getFilters();

    if (filterByGroupState === groupId) {
      FeedApi.onAllFeedClick();
      this.setActiveId('');
    }
  };
}

const withUsersAndGroups = compose(
  withWorkspaceAndUser,
  graphql(getUsersListQuery, {
    options: (props: Props) => {
      return {
        variables: {
          workspaceId: props.workspaceId
        },
        notifyOnNetworkStatusChange: true
      };
    },
    props: ({ data }) => ({
      usersData: data
    })
  }),
  graphql(getGroupsListQuery, {
    options: (props: Props) => {
      return {
        variables: {
          workspaceId: props.workspaceId,
          groupFilterType: GROUPS_USER_BELONG_TO
        },
        notifyOnNetworkStatusChange: true
      };
    },
    props: ({ data }) => ({
      groupsData: data
    })
  }),
  withNetworkStateQuery
);

const Sidebar = withUsersAndGroups(SidebarComponent);

export default Sidebar;
