import React from 'react';
// @ts-ignore
import workspaceFrame from '../../assets/img/workspace_frame.png';
import { getErrorText, WORKSPACE_NAME_MAX_LENGTH } from '../../services';
import { ButtonPrimary, InputField } from '../UI';
// @ts-ignore
import styles from '../UI/styles/externalPages.module.scss';

interface Props {
  fields: {
    workspaceName: string;
  };
  errors: {
    workspaceName: string;
  };
  loading: boolean;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const CreateWorkspaceView = ({
  fields,
  errors,
  loading,
  onChange,
  onSubmit
}: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.containerCol}>
        <div className={styles.content}>
          <p className={styles.title}>
            What's the name of your company or team?
          </p>
          <form onSubmit={onSubmit} className={styles.form}>
            <fieldset disabled={loading}>
              <InputField
                name="workspaceName"
                placeholder="Workspace name"
                value={fields.workspaceName}
                error={errors.workspaceName}
                onChange={onChange}
                maxLength={WORKSPACE_NAME_MAX_LENGTH}
              />

              <div className="error cntr">{getErrorText(errors)}</div>

              <div className={styles.submitBtnBox}>
                <ButtonPrimary type="submit">Next</ButtonPrimary>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      <div className={styles.containerCol}>
        <div className={styles.imageBox}>
          <img src={workspaceFrame} alt="" />
        </div>
      </div>
    </div>
  </div>
);

export { CreateWorkspaceView };
