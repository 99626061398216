import React from 'react';
import { Loader } from '../../UI';
// @ts-ignore
import style from './allReactions.module.scss';
import { CategoryType } from './AllReactions.types';

interface Props {
  categories: CategoryType[];
  reactionLoading: boolean;
  onClick(reactionName: string): void;
}

const AllReactionsView = ({ categories, reactionLoading, onClick }: Props) => {
  if (reactionLoading) {
    return (
      <div className={style.box}>
        <div className="cntr">
          <Loader width="40px" />
        </div>
      </div>
    );
  }

  return (
    <div className={style.box}>
      {categories.map((category: any, index: any) => (
        <div key={index}>
          <div className={style.categoryName}>{category.categoryName}</div>

          <ul className={style.list}>
            {category.reactionDescriptions.map(
              (reaction: any, reactionIndex: any) => (
                <li key={reactionIndex}>
                  <button
                    type="button"
                    className={style.btn}
                    onClick={() => onClick(reaction.reactionName)}
                    data-reaction-name={reaction.reactionName}
                  >
                    <img src={reaction.imageUrl} alt="" />
                  </button>
                </li>
              )
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

export { AllReactionsView };
