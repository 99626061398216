import React from 'react';
import { Modal, Slider } from '../../../UI';
import { Image } from '../../../UI/Image';
import ExpandedCardHeader from '../ExpandedCardHeader/ExpandedCardHeader';
// @ts-ignore
import styles from './postImages.module.scss';
import { IPostImage } from './PostImages.types';

interface Props {
  images: IPostImage[];
  userAvatar: string;
  userName: string;
  postDate: string;
  initialImgSlide: number;
  isImagesModalOpen: boolean;
  closeModal(): void;
}

const PostImagesModal = ({
  images,
  initialImgSlide,
  isImagesModalOpen,
  closeModal,
  userAvatar,
  userName,
  postDate
}: Props) => (
  <Modal
    isModalOpen={isImagesModalOpen}
    modalWidth="md"
    onBtnClose={closeModal}
  >
    <div className={styles.modalBox}>
      <ExpandedCardHeader
        userAvatar={userAvatar}
        userName={userName}
        postDate={postDate}
        additionalClassName={styles.imagesModalHeader}
      />

      <Slider
        settings={{
          dots: false,
          initialSlide: initialImgSlide,
          accessibility: true,
          adaptiveHeight: true,
          className: styles.slider,
          draggable: false
        }}
      >
        {images.map((image: IPostImage) => (
          <div key={image.fileId} className={styles.slide}>
            <Image fileId={image.fileId} />
          </div>
        ))}
      </Slider>
    </div>
  </Modal>
);

export { PostImagesModal };
