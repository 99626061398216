import React from 'react';

interface Props {
  width?: string;
}

const LinkIcon = ({ width = '25px' }: Props) => (
  <svg width={width} viewBox="0 0 25 23" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-196.000000, -2025.000000)"
        fill="currentColor"
        fillRule="nonzero"
        stroke="#4C4F57"
        strokeWidth="0.5"
      >
        <g transform="translate(91.000000, 1985.000000)">
          <g transform="translate(96.000000, 31.000000)">
            <g transform="translate(10.000000, 10.000000)">
              <path d="M12.152381,9.2047619 C11.9,9.45714286 11.9,9.86190476 12.152381,10.1142857 C13.1952381,11.1571429 13.1952381,12.852381 12.152381,13.8952381 L6.67619048,19.3714286 C5.63333333,20.4142857 3.93809524,20.4142857 2.8952381,19.3714286 L2.07142857,18.547619 C1.02857143,17.5047619 1.02857143,15.8095238 2.07142857,14.7666667 L7.54761905,9.29047619 C7.8,9.03809524 7.8,8.63333333 7.54761905,8.38095238 C7.2952381,8.12857143 6.89047619,8.12857143 6.63809524,8.38095238 L1.16190476,13.8571429 C0.414285714,14.6047619 0,15.6 0,16.6571429 C0,17.7142857 0.40952381,18.7095238 1.16190476,19.4571429 L1.98571429,20.2809524 C2.75714286,21.052381 3.77142857,21.4380952 4.78571429,21.4380952 C5.8,21.4380952 6.81428571,21.052381 7.58571429,20.2809524 L13.0619048,14.8047619 C14.6047619,13.2619048 14.6047619,10.747619 13.0619048,9.2047619 C12.8142857,8.95238095 12.4047619,8.95238095 12.152381,9.2047619 Z" />
              <path d="M21.8333333,2.00952381 L21.0095238,1.18571429 C19.4666667,-0.357142857 16.952381,-0.357142857 15.4095238,1.18571429 L9.93333333,6.66190476 C8.39047619,8.2047619 8.39047619,10.7190476 9.93333333,12.2619048 C10.1857143,12.5142857 10.5904762,12.5142857 10.8428571,12.2619048 C11.0952381,12.0095238 11.0952381,11.6047619 10.8428571,11.352381 C9.8,10.3095238 9.8,8.61428571 10.8428571,7.57142857 L16.3190476,2.0952381 C17.3619048,1.05238095 19.0571429,1.05238095 20.1,2.0952381 L20.9238095,2.91904762 C21.9666667,3.96190476 21.9666667,5.65714286 20.9238095,6.7 L15.447619,12.1761905 C15.1952381,12.4285714 15.1952381,12.8333333 15.447619,13.0857143 C15.5714286,13.2095238 15.7380952,13.2761905 15.9,13.2761905 C16.0619048,13.2761905 16.2285714,13.2142857 16.352381,13.0857143 L21.8285714,7.60952381 C22.5761905,6.86190476 22.9904762,5.86666667 22.9904762,4.80952381 C22.9904762,3.75238095 22.5809524,2.75714286 21.8333333,2.00952381 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { LinkIcon };
