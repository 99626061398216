import React from 'react';
import PropTypes from 'prop-types';
import { compose, withMutation, withQuery } from 'react-apollo';

import './Header.scss';
// import FilterByRating from './FilterByRating';
import FilterByText from './FilterByText';
import { CreateNewPost } from '../CreatePost';

import { Notifications } from '../Notifications';
import Navigation from './Navigation';
import cardViewImg from '../../assets/img/card-view-img.png';
import compactViewIcon from '../../assets/img/compact_view_icon.svg';
import { getPostView, setPostView } from '../../graphql/local';

const Header = props => {
  const { currentWorkspaceId, isCardExpanded, mutatePostView } = props;

  return (
    <div className="Header">
      <div className="header-col-l">
        <CreateNewPost currentWorkspaceId={currentWorkspaceId} />

        <button
          type="button"
          className="change-card-view"
          onClick={() => {
            mutatePostView({
              variables: {
                isCardsViewExpanded: !isCardExpanded
              }
            });
          }}
        >
          {isCardExpanded ? (
            <img src={cardViewImg} alt="img" />
          ) : (
            <img src={compactViewIcon} alt="img" />
          )}
        </button>

        {/* rating is hidden according to BUJ-419 */}
        {/* <FilterByRating /> */}
      </div>

      <div>
        <FilterByText />
        <Notifications />
        <Navigation currentWorkspaceId={currentWorkspaceId} />
      </div>
    </div>
  );
};

Header.propTypes = {
  currentWorkspaceId: PropTypes.string.isRequired
};

export default compose(
  withQuery(getPostView, {
    props: props => ({
      isCardExpanded: props.data.cardsView.isCardsViewExpanded
    })
  }),
  withMutation(setPostView, {
    props: props => ({
      mutatePostView: props.mutate
    })
  })
)(Header);
