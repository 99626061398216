import React from 'react';
import { Mutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../../apollo/decorators';
import { changeGroupAvatarMutation } from '../../../../graphql';
import ChangeGroupAvatarView from './ChangeGroupAvatarView';

interface Props {
  group: {
    id: string;
    avatar: string;
  };
  loadingRefetch: boolean;

  // gql
  workspaceId: string;

  refetchGroup(): void;
}

interface State {
  isModalOpen: boolean;
}

class ChangeGroupAvatar extends React.Component<Props, State> {
  public state = {
    isModalOpen: false
  };

  public render() {
    const { group, loadingRefetch } = this.props;
    const { isModalOpen } = this.state;

    return (
      <Mutation mutation={changeGroupAvatarMutation}>
        {(changeAvatar: any, data: any) => (
          <ChangeGroupAvatarView
            avatar={group.avatar}
            loading={data.loading}
            isModalOpen={isModalOpen}
            closeModal={this.closeModal}
            onDropSuccess={this.openModal}
            onSubmitAvatar={(fileId: string) =>
              this.onSubmitAvatar(changeAvatar, fileId)
            }
            onSubmitAvatarSuccess={this.onSubmitAvatarSuccess}
            loadingRefetch={loadingRefetch}
          />
        )}
      </Mutation>
    );
  }

  private openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  private closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  private onSubmitAvatar = (changeAvatar: any, fileId: string) => {
    const { workspaceId, group } = this.props;

    return changeAvatar({
      variables: {
        groupId: group.id,
        fileId,
        workspaceId
      }
    });
  };

  private onSubmitAvatarSuccess = () => {
    const { refetchGroup } = this.props;

    refetchGroup();
    this.closeModal();
  };
}

export default withWorkspaceAndUser(ChangeGroupAvatar);
