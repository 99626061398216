import React from 'react';
import { compose, withMutation } from 'react-apollo';
import {
  addCommentReactionMutation,
  deleteCommentMutation,
  deleteCommentReactionMutation
} from '../../../../graphql';
import Log from '../../../../Log';
import { ReactedUserType, ReactionType } from '../../../Reactions';
import { CommentType } from '../Comments.types';
import './Comment.scss';
import CommentView from './CommentView';

interface State {
  isEditFormVisible: boolean;
  isReactionBoxVisible: boolean;
  positionReactionBox: {
    left: string;
    top: string;
  };
}

interface Props {
  comment: CommentType;
  threadId: string;
  currentWorkspaceId: string;
  currentUserId: string;
  mentions: Array<{}>;
  fetchMentions(): void;
}

interface MutateProps {
  addCommentReaction: any;
  deleteCommentReaction: any;
}

class CommentComponent extends React.Component<Props & MutateProps, State> {
  public state = {
    isEditFormVisible: false,
    isReactionBoxVisible: false,
    positionReactionBox: {
      left: '0',
      top: '0'
    }
  };

  public showEditForm = () => {
    this.setState({
      isEditFormVisible: true
    });

    return null;
  };

  public hideEditForm = () => {
    this.setState({
      isEditFormVisible: false
    });

    return null;
  };

  public deleteComment = (deleteCommentOnPost: any, commentId: string) => {
    const { threadId, currentWorkspaceId, currentUserId } = this.props;

    return deleteCommentOnPost({
      variables: {
        workspaceId: currentWorkspaceId,
        commentThreadId: threadId,
        commentId,
        userId: currentUserId
      }
    }).catch((err: any) => {
      Log.error(`Error while deleting comment: ${err}`);
    });
  };

  public onSelectReaction = (selectedReactionName: string) => {
    const { comment, currentUserId } = this.props;

    this.hideReactionBox();

    const reactionData = comment.reactions.find(
      (reaction: ReactionType) => reaction.reactionName === selectedReactionName
    );

    if (!reactionData) {
      return this.addReaction(selectedReactionName);
    }

    const isUserReaction = reactionData.reactedUsers.some(
      (user: ReactedUserType) => user.id === currentUserId
    );

    if (isUserReaction) {
      this.deleteReaction(selectedReactionName);
    } else {
      this.addReaction(selectedReactionName);
    }
  };

  public deleteReaction = (reactionName: string) => {
    const { comment, currentWorkspaceId, deleteCommentReaction } = this.props;

    deleteCommentReaction({
      variables: {
        workspaceId: currentWorkspaceId,
        commentId: comment.id,
        reactionName
      }
    })
      .then((res: any) => {
        Log.info('deleteReaction', res);
      })
      .catch((err: any) => {
        Log.error(`deleteReaction: ${err}`);
      });
  };

  public addReaction = (reactionName: string) => {
    const { comment, currentWorkspaceId, addCommentReaction } = this.props;

    addCommentReaction({
      variables: {
        workspaceId: currentWorkspaceId,
        commentId: comment.id,
        reactionName
      }
    })
      .then((res: any) => {
        Log.info('addReaction', res);
      })
      .catch((err: any) => {
        Log.error(`addReaction: ${err}`);
      });
  };

  public showReactionBox = (e: any) => {
    const { top, left } = e.target.getBoundingClientRect();

    this.setState({
      isReactionBoxVisible: true,
      positionReactionBox: {
        left: left + 'px',
        top: top + 'px'
      }
    });
  };

  public hideReactionBox = () => {
    this.setState({
      isReactionBoxVisible: false
    });
  };

  public render() {
    const {
      isEditFormVisible,
      isReactionBoxVisible,
      positionReactionBox
    } = this.state;
    const {
      comment,
      threadId,
      currentWorkspaceId,
      currentUserId,
      mentions,
      fetchMentions
    } = this.props;

    return (
      <CommentView
        comment={comment}
        threadId={threadId}
        currentWorkspaceId={currentWorkspaceId}
        isEditFormVisible={isEditFormVisible}
        showEditForm={this.showEditForm}
        hideEditForm={this.hideEditForm}
        deleteComment={this.deleteComment}
        currentUserId={currentUserId}
        mentions={mentions}
        fetchMentions={fetchMentions}
        isReactionBoxVisible={isReactionBoxVisible}
        positionReactionBox={positionReactionBox}
        showReactionBox={this.showReactionBox}
        hideReactionBox={this.hideReactionBox}
        onSelectReaction={this.onSelectReaction}
      />
    );
  }
}

const Comment = compose(
  withMutation<any, any, any, any>(addCommentReactionMutation, {
    props: ({ mutate }) => ({
      addCommentReaction: mutate
    })
  }),
  withMutation<any, any, any, any>(deleteCommentReactionMutation, {
    props: ({ mutate }) => ({
      deleteCommentReaction: mutate
    })
  })
)(CommentComponent);

export { Comment };
