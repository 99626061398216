import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { StarRating } from '../StarRating';
import { toFullDateString } from '../../../utils/dateUtils';
import ActionsBlock from '../PostComponents/ActionsBlock/index';
import styles from './compressedCard.module.scss';
import imageIcon from '../../../assets/img/imageIcon.svg';
import fileIcon from '../../../assets/img/fileIcon.svg';

class CompressedCard extends Component {
  addClass = e => {
    e.target.closest('.IndexCard').classList.toggle('expanded');
  };

  render() {
    const {
      item,
      srcCreatorAvatar,
      creatorName,
      // starRating,
      attachedFilesLength,
      attachedImagesLength
    } = this.props;
    const postDate = toFullDateString(item.createdAt);

    return (
      <div className={styles.cardItem}>
        <div className={styles.header}>
          <img
            className={styles.avatar}
            alt=""
            src={srcCreatorAvatar}
            onClick={e => this.addClass(e)}
          />
          {item.title ? (
            <div
              className={styles.title}
              onClick={e => this.addClass(e)}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          ) : (
            <div className={styles.userName} onClick={this.addClass}>
              {creatorName}
            </div>
          )}
          <div className={styles.time}>{postDate}</div>
        </div>

        <div className={styles.contentBox}>
          {item.description && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
        </div>

        <div className={styles.footer}>
          <div className={styles.infoBlock}>
            {attachedImagesLength > 0 && (
              <div>
                <img src={imageIcon} alt="" />
                {attachedImagesLength}
              </div>
            )}
            {attachedFilesLength > 0 && (
              <div>
                <img src={fileIcon} alt="" />
                {attachedFilesLength}
              </div>
            )}
            {/* rating is hidden according to BUJ-419 */}
            {/* <div className={styles.stars}> */}
            {/* <StarRating item={item} starRating={starRating} /> */}
            {/* </div> */}
          </div>

          {item.actions && item.actions.length > 0 && (
            <div className={styles.actionBlock}>
              <ActionsBlock actions={item.actions} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

CompressedCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  srcCreatorAvatar: PropTypes.string.isRequired,
  creatorName: PropTypes.string.isRequired,
  // starRating: PropTypes.number.isRequired,
  attachedFilesLength: PropTypes.number.isRequired,
  attachedImagesLength: PropTypes.number.isRequired
};

export default CompressedCard;
