import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { XIcon } from '../Icons';
import './styles.scss';

export default function Modal(props) {
  const {
    isModalOpen,
    onBtnClose,
    onRequestClose,
    modalWidth,
    children,
    content,
    contentClassName,
    overlayClassName
  } = props;

  const modalContentClassName =
    contentClassName || `react-modal-content ${modalWidth}`;

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={onRequestClose}
      className={modalContentClassName}
      overlayClassName={`react-modal-overlay ${overlayClassName}`}
      ariaHideApp={false}
    >
      {!!onBtnClose && (
        <button
          type="button"
          onClick={onBtnClose}
          className="react-modal-close-btn"
        >
          <XIcon width="10px" />
        </button>
      )}
      {children && children}
      {content && content}
    </ReactModal>
  );
}

Modal.propTypes = {
  isModalOpen: PropTypes.bool,
  onBtnClose: PropTypes.func,
  onRequestClose: PropTypes.func,
  modalWidth: PropTypes.string,
  contentClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
  content: PropTypes.any,
  children: PropTypes.any
};

Modal.defaultProps = {
  isModalOpen: false,
  onBtnClose: null,
  onRequestClose: () => undefined,
  modalWidth: 'sm',
  contentClassName: '',
  overlayClassName: '',
  content: null,
  children: null
};
