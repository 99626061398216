const IMG = 'img';
const LONG_SHARE_COMMENT = 'LONG_SHARE_COMMENT';
const EMPTY_SHARE_TO_FIELD = 'EMPTY_SHARE_TO_FIELD';
const ERROR_ON_SUBMIT = 'ERROR_ON_SUBMIT';

const SHARE_COMMENT_MAX_LENGTH = 4000;

export {
  IMG,
  LONG_SHARE_COMMENT,
  EMPTY_SHARE_TO_FIELD,
  ERROR_ON_SUBMIT,
  SHARE_COMMENT_MAX_LENGTH
};
