import React from 'react';
import { IPostImage } from './PostImages.types';
import { PostImagesModal } from './PostImagesModal';
import { PostImagesView } from './PostImagesView';

interface Props {
  images: IPostImage[];
  userAvatar: string;
  userName: string;
  postDate: string;
}

interface State {
  initialImgSlide: number;
  isImagesModalOpen: boolean;
}

class PostImages extends React.Component<Props, State> {
  public state = {
    initialImgSlide: 0,
    isImagesModalOpen: false
  };

  public render() {
    const { images, userAvatar, userName, postDate } = this.props;

    const { initialImgSlide, isImagesModalOpen } = this.state;

    return (
      <React.Fragment>
        <PostImagesView images={images} openModal={this.openModal} />
        <PostImagesModal
          images={images}
          initialImgSlide={initialImgSlide}
          isImagesModalOpen={isImagesModalOpen}
          userAvatar={userAvatar}
          userName={userName}
          postDate={postDate}
          closeModal={this.closeModal}
        />
      </React.Fragment>
    );
  }

  private openModal = (initialImgSlide: number = 0) => {
    this.setState({
      initialImgSlide,
      isImagesModalOpen: true
    });
  };

  private closeModal = () => {
    this.setState({
      isImagesModalOpen: false
    });
  };
}

export { PostImages };
