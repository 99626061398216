import {
  FILTER_BY_GROUP,
  FILTER_BY_RATING,
  FILTER_BY_TEXT,
  FILTER_BY_USER,
  POST_FILTER_TYPE_NAME,
  POST_VIEW
} from '../../constants';

class FilterService {
  public getFilter(type: string) {
    return localStorage.getItem(type);
  }

  public setFilter(type: string, value: any): void {
    localStorage.setItem(type, value);
  }

  public removeFilters(): void {
    localStorage.removeItem(FILTER_BY_GROUP);
    localStorage.removeItem(FILTER_BY_RATING);
    localStorage.removeItem(FILTER_BY_TEXT);
    localStorage.removeItem(FILTER_BY_USER);
    localStorage.removeItem(POST_VIEW);
    localStorage.removeItem(POST_FILTER_TYPE_NAME);
  }
}

export default new FilterService();
