import React, { PureComponent } from 'react';
import { path } from 'ramda';
import './NotificationItem.scss';
import Log from '../../../Log';
import { toFullDateString } from '../../../utils/dateUtils';
import { NotificationObject, NotificationVerb } from '../notificationTypes';

const setGroupImage = item => path(['createdBy', 'avatar'], item);

const createBodyByNotificationType = ({
  notificationObject: { __typename },
  verb
}) => {
  try {
    switch (verb) {
      case NotificationVerb.POST:
        return `Left a comment in the thread`;
      case NotificationVerb.COMMENT:
        return `Left a comment to your post`;
      case NotificationVerb.MENTION:
        return `Mentioned you in the comment`;
      case NotificationVerb.ADD:
        if (__typename === NotificationObject.COMMENT_THREAD) {
          return `Added you to new direct comments thread`;
        }
        if (__typename === NotificationObject.GROUP) {
          return `Added you to group`;
        }
        return '';
      default:
        return '';
    }
  } catch (e) {
    Log.error(e);
    return '';
  }
};

// eslint-disable-next-line react/prefer-stateless-function,import/prefer-default-export
export class NotificationItem extends PureComponent {
  render() {
    const { item, onNotificationClick } = this.props;

    if (item.type === 'date') {
      return (
        <div className="notification date-divider">
          <div className="notification-body">
            <div className="notification-date">{item.text}</div>
          </div>
        </div>
      );
    }

    const imageName = setGroupImage(item);
    const imageUrl = !!imageName && imageName;
    const title = item.createdBy.name || item.createdBy.login;
    const body = createBodyByNotificationType(item);
    const time = toFullDateString(item.createdAt);
    const classNames = item.seen
      ? 'notification'
      : 'notification notification-not-seen';

    return (
      <div className={classNames} onClick={onNotificationClick}>
        <div className="notification-img">
          <img src={imageUrl || ''} alt="img" />
        </div>

        <div className="notification-body">
          <div className="notification-title">{title}</div>
          <div className="notification-info">{body}</div>
          <div className="notification-time">{time}</div>
        </div>
      </div>
    );
  }
}
