import React from 'react';
import { Image } from '../../../UI/Image';
import { IMG } from '../../constants';
// @ts-ignore
import styles from './uploadedImages.module.scss';

interface Props {
  uploadedImages: any;
  onRemoveFile(fileName: string, fileType?: string): void;
}

function UploadedImages(props: Props) {
  const { uploadedImages, onRemoveFile } = props;

  return (
    <div className={styles.imageBox}>
      {uploadedImages.map((item: any) => (
        <div key={item.name} className={styles.imageItem}>
          <div className={styles.imagePreview}>
            {item.fileId ? (
              <Image fileId={item.fileId} />
            ) : (
              <img src={URL.createObjectURL(item)} alt="" />
            )}
          </div>
          <div className={styles.imageActions}>
            <button
              type="button"
              className={styles.removeImageBtn}
              onClick={() => onRemoveFile(item.name, IMG)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export { UploadedImages };
