export const mentionedInComment = 'mentionedInComment'; //= > redirect to post and comment
export const leftCommentToCard = 'leftCommentToCard'; //= > redirect to post
export const invitedToGroup = 'invitedToGroup'; //= > redirect to newest post
export const addedToThread = 'addedToThread';

export const NotificationVerb = {
  POST: 'POST',
  ADD: 'ADD',
  COMMENT: 'COMMENT',
  MENTION: 'MENTION'
};
export const NotificationObject = {
  COMMENT_THREAD: 'CommentThreadNotificationObject',
  GROUP: 'GroupNotificationObject'
};
