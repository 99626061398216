import React from 'react';
import DropZone from '../../../UI/DropZone';
import Item from '../Item';
import PlusBtn from '../PlusBtn';
import { UploadedFilesList } from './UploadedFilesList';

interface Props {
  uploadedFiles: any;
  onDropAccepted(files: any, fileType?: string): void;
  onRemoveFile(fileName: string, fileType?: string): void;
}

function UploadedFiles(props: Props) {
  const { uploadedFiles, onDropAccepted, onRemoveFile } = props;

  return (
    <Item
      label="Added files:"
      content={
        <UploadedFilesList
          uploadedFiles={uploadedFiles}
          onRemoveFile={onRemoveFile}
        />
      }
      button={
        <DropZone
          onDropAccepted={onDropAccepted}
          config={{
            multiple: true,
            noDrag: true
          }}
        >
          <PlusBtn />
        </DropZone>
      }
    />
  );
}

export { UploadedFiles };
