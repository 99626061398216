import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';
import MainPage from './Components/MainPage';
import {
  VerifyUserPage,
  RegisterPage,
  CreateWorkspacePage,
  InviteUsersToWorkspacePage,
  WelcomePage,
  LoginPage,
  WorkspacesPage
} from './Pages';
import { TokenStore, EnvService } from './services';
import './App.scss';
import {
  withNetworkStateMutation,
  withWakeUpStateMutation
} from './apollo/decorators';
import { listenWakeUp } from './utils/wakeUtil';

class App extends Component {
  state = {
    isLogged: !!TokenStore.getRefreshToken()
  };

  componentWillMount() {
    if (window.heap && !EnvService.isLocal) {
      window.heap.load(EnvService.heapAnalyticsId);
    }

    this.restartWebsocketConnection();
  }

  componentDidMount() {
    const { mutateWakeUpState } = this.props;
    window.addEventListener('online', this.setNetworkState);
    window.addEventListener('offline', this.setNetworkState);

    listenWakeUp(lastWokeUpTime =>
      mutateWakeUpState({ variables: { lastWokeUpTime } })
    );
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.setNetworkState);
    window.removeEventListener('offline', this.setNetworkState);
  }

  setNetworkState = ({ type }) => {
    const { mutateNetworkState } = this.props;

    mutateNetworkState({
      variables: {
        isOnline: type === 'online'
      }
    });
  };

  changeLoggedState = isLogged => {
    this.setState({
      isLogged
    });

    this.restartWebsocketConnection();
  };

  restartWebsocketConnection() {
    const { client } = this.props;

    // Guard this restart with this check because in tests this method won't exist
    if (client.restartWebsocketConnection) {
      client.restartWebsocketConnection();
    }
  }

  render() {
    const { isLogged } = this.state;

    return (
      <div className="App">
        {isLogged ? (
          <Switch>
            <Redirect from="/verification" to="/" />
            <Redirect from="/register" to="/" />
            <Route
              exact
              path="/login"
              component={() => (
                <LoginPage
                  isLogged={isLogged}
                  changeLoggedState={this.changeLoggedState}
                />
              )}
            />
            <Route
              exact
              path="/create-workspace"
              component={CreateWorkspacePage}
            />
            <Route
              exact
              path="/invite-users"
              component={InviteUsersToWorkspacePage}
            />
            <Route exact path="/welcome" component={WelcomePage} />
            <Route exact path="/workspaces" component={WorkspacesPage} />
            <Route path="*" component={MainPage} />
          </Switch>
        ) : (
          <Switch>
            <Redirect from="/create-workspace" to="/" />
            <Redirect from="/welcome" to="/" />
            <Redirect from="/invite-users" to="/" />
            <Redirect from="/workspaces" to="/" />
            <Route exact path="/verification" component={VerifyUserPage} />
            <Route
              exact
              path="/register"
              component={() => (
                <RegisterPage changeLoggedState={this.changeLoggedState} />
              )}
            />
            <Route
              exact
              path="*"
              component={() => (
                <LoginPage
                  isLogged={isLogged}
                  changeLoggedState={this.changeLoggedState}
                />
              )}
            />
          </Switch>
        )}
      </div>
    );
  }
}

export default compose(
  withApollo,
  withNetworkStateMutation,
  withWakeUpStateMutation
)(App);
