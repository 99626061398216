import React from 'react';
// @ts-ignore
import styles from './reactionList.module.scss';
import { ReactedUserType, ReactionType } from './ReactionList.types';

interface Props {
  reactions: ReactionType[];
  position: any;
  onMouseEnter(e: any): void;
  onSelectReaction?(reactionName: string): void;
}

const ReactionListView = ({
  reactions,
  position,
  onMouseEnter,
  onSelectReaction = () => null
}: Props) => {
  if (!reactions || reactions.length === 0) {
    return null;
  }

  return (
    <ul className={styles.box}>
      {reactions.map((reaction: ReactionType, index: any) => (
        <li key={index}>
          <button
            type="button"
            className={styles.btn}
            onClick={() => onSelectReaction(reaction.reactionName)}
            onMouseEnter={onMouseEnter}
            data-reaction-name={reaction.reactionName}
          >
            <img src={reaction.imageUrl} alt="" />
            <span>{reaction.count}</span>
          </button>

          {reaction.reactedUsers.length > 0 && (
            <div
              className={styles.reactedUsersBox}
              style={{ left: position.left, top: position.top }}
            >
              {reaction.reactedUsers
                .map((user: ReactedUserType) => user.name)
                .join(', ')}
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export { ReactionListView };
