import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import StarRating from '../StarRating/StarRating';
import Threads from './Threads/index';
import { toFullDateString } from '../../../utils/dateUtils';
import ActionsBlock from '../PostComponents/ActionsBlock/index';
import TokenStore from '../../../services/TokenStore';
import { PostImages } from './PostImages';
import ExpandedCardHeader from './ExpandedCardHeader/ExpandedCardHeader';
import styles from './expandedCard.module.scss';
import { ApiPath, FeedApi } from '../../../services';
import { EditPost } from '../../CreatePost';
import { PostReactions } from '../PostReactions';

class ExpandedCard extends Component {
  addClass = e => {
    e.target.closest('.IndexCard').classList.toggle('expanded');
  };

  fetchFeedByTag = hashTag => {
    const { inSharedModal } = this.props;

    if (inSharedModal) {
      return null;
    }

    return FeedApi.fetchFeedByText(hashTag);
  };

  getFileUrl = fileId => {
    const { currentWorkspaceId } = this.props;

    return `${
      ApiPath.url
    }/v1/workspaces/${currentWorkspaceId}/files/${fileId}?token=${TokenStore.getIdentityToken()}`;
  };

  render() {
    const {
      item,
      inSharedModal,
      toggleShareModal,
      srcCreatorAvatar,
      fetchMoreThreads,
      // starRating,
      showErrorOnSubscription,
      attachedFiles,
      attachedImages,
      selectedTabId,
      currentWorkspaceId,
      userId
    } = this.props;

    const actualPostDate = toFullDateString(item.createdAt);
    const actualSharedDate = item.sharedAt && toFullDateString(item.sharedAt);
    const actualEditedDate = item.editedAt && toFullDateString(item.editedAt);

    const postDate = item.sharedBy ? actualSharedDate : actualPostDate;

    const creatorName =
      (item.sharedBy && (item.sharedBy.name || item.sharedBy.login)) ||
      (item.createdBy && (item.createdBy.name || item.createdBy.login));

    const cardClassName = classNames(styles.cardItem, {
      [styles.inModal]: inSharedModal
    });
    const contentClassName = classNames(styles.contentBox, {
      [styles.isContentShared]: !!item.sharedBy
    });

    const isOwnPost = item.createdBy.id === userId;

    return (
      <div className={cardClassName}>
        <div className={styles.headerActionsBox}>
          {isOwnPost && !inSharedModal && (
            <EditPost
              post={item}
              currentWorkspaceId={currentWorkspaceId}
              attachedFiles={attachedFiles}
              attachedImages={attachedImages}
            />
          )}
        </div>

        <ExpandedCardHeader
          userAvatar={srcCreatorAvatar}
          userName={creatorName}
          postDate={postDate}
          editedDate={!item.sharedBy && actualEditedDate}
          onClick={this.addClass}
        />

        <div className={contentClassName}>
          {!!item.sharedBy && (
            <ExpandedCardHeader
              userAvatar={item.createdBy && item.createdBy.avatar}
              userName={
                item.createdBy && (item.createdBy.name || item.createdBy.login)
              }
              postDate={actualPostDate}
              additionalClassName={styles.sharedHeader}
              editedDate={actualEditedDate}
            />
          )}
          {item.title && (
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          )}
          <div className={styles.imgAndDescBox}>
            {attachedImages.length > 0 && (
              <PostImages
                images={attachedImages}
                userAvatar={item.createdBy && item.createdBy.avatar}
                userName={
                  item.createdBy &&
                  (item.createdBy.name || item.createdBy.login)
                }
                postDate={toFullDateString(item.createdAt)}
              />
            )}
            {item.description && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            )}
          </div>
          {item.tags && item.tags.length > 0 && (
            <div className={styles.hashTagsBox}>
              {item.tags.map((tagItem, index) => (
                <span
                  key={index}
                  onClick={() => this.fetchFeedByTag(tagItem.tag)}
                >
                  #{tagItem.tag}{' '}
                </span>
              ))}
            </div>
          )}
          {attachedFiles.length > 0 && (
            <div className={styles.filesBox}>
              {attachedFiles.map((file, index) => (
                <a
                  key={index}
                  className={styles.fileItem}
                  href={this.getFileUrl(file.fileId)}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={file.name}
                >
                  <span className={styles.downloadIcon} />
                  <span className={styles.fileName}>{file.name}</span>
                </a>
              ))}
            </div>
          )}
        </div>

        {!inSharedModal && (
          <React.Fragment>
            <div className={styles.footer}>
              <div className={styles.footerCol}>
                <PostReactions postId={item.id} reactions={item.reactions} />
                {/* rating is hidden according to BUJ-419 */}
                {/* <div className={styles.starsBox}> */}
                {/* <StarRating item={item} starRating={starRating} /> */}
                {/* </div> */}
              </div>
              {item.actions && item.actions.length > 0 && (
                <div className={styles.actionBox}>
                  <ActionsBlock actions={item.actions} />
                </div>
              )}
            </div>

            <Threads
              item={item}
              toggleShareModal={toggleShareModal}
              fetchMoreThreads={fetchMoreThreads}
              showErrorOnSubscription={showErrorOnSubscription}
              selectedTabId={selectedTabId}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

ExpandedCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    // eslint-disable-next-line
    tags: PropTypes.array
  }).isRequired,
  toggleShareModal: PropTypes.func.isRequired,
  inSharedModal: PropTypes.bool,
  srcCreatorAvatar: PropTypes.string.isRequired,
  fetchMoreThreads: PropTypes.func.isRequired,
  // starRating: PropTypes.number.isRequired,
  showErrorOnSubscription: PropTypes.func.isRequired,
  attachedFiles: PropTypes.array.isRequired,
  attachedImages: PropTypes.array.isRequired,
  currentWorkspaceId: PropTypes.string.isRequired,
  userId: PropTypes.string
};

ExpandedCard.defaultProps = {
  userId: '',
  inSharedModal: false
};

export default ExpandedCard;
