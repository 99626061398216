import React from 'react';
import { Redirect } from 'react-router';
import { getTokenFromUrl } from '../../Components/helpers';
import Login from '../../Components/Login';
import { INVITATION_CODE_NAME } from '../../Components/Login/constants';
import PageHeader from '../../Components/PageHeader';
// @ts-ignore
import styles from '../pages.module.scss';

interface Props {
  isLogged: boolean;
  changeLoggedState(bool: boolean): void;
}

class LoginPage extends React.Component<Props> {
  public render() {
    const { isLogged } = this.props;

    return (
      <React.Fragment>
        {isLogged && !this.hasInvitationToken() ? (
          <Redirect to={'/'} />
        ) : (
          <div className={styles.wrapper}>
            <PageHeader />
            <div className={styles.containerFlex}>
              <Login {...this.props} />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  private hasInvitationToken = () => {
    return !!getTokenFromUrl(INVITATION_CODE_NAME);
  };
}

export { LoginPage };
