import React from 'react';
import { ArrowRight } from '../UI/Icons';
// @ts-ignore
import styles from './workspaces.module.scss';
import { IWorkspace } from './Workspaces.types';

interface Props {
  workspace: IWorkspace;
  onSelectWorkspace(w: IWorkspace): void;
}

const WorkspaceItem = ({ workspace, onSelectWorkspace }: Props) => (
  <button
    type="button"
    className={styles.listItem}
    onClick={() => onSelectWorkspace(workspace)}
  >
    <div className={styles.name}>{workspace.name}</div>
    <div className={styles.arrowBox}>
      <ArrowRight />
    </div>
  </button>
);

export { WorkspaceItem };
