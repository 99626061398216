import {
  EMPTY_SHARE_TO_FIELD,
  ERROR_ON_SUBMIT,
  LONG_SHARE_COMMENT,
  SHARE_COMMENT_MAX_LENGTH
} from './constants';

const getErrorText = (error: string) => {
  switch (error) {
    case LONG_SHARE_COMMENT:
      return `Share message can not be longer than ${SHARE_COMMENT_MAX_LENGTH} symbols`;
    case EMPTY_SHARE_TO_FIELD:
      return 'Please select at least one group or user to post';
    case ERROR_ON_SUBMIT:
      return 'Error';
    default:
      return '';
  }
};

export { getErrorText };
