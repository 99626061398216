import React from 'react';
import { avatarUploaderDecorator } from '../../../avatarUploaderDecorator';
import { Cropper, DropZone, Loader, Modal } from '../../../UI';
// @ts-ignore
import styles from './changeGroupAvatar.module.scss';

interface Props {
  avatar: string;
  loading: boolean;
  isModalOpen: boolean;
  loadingRefetch: boolean;

  avatarPreview: string;
  fileLoading: boolean;
  configDropZone: {};
  configCropper: {};
  isFileValid: boolean;
  error: boolean;
  closeModal(): void;
  onDropAccepted(files: any): void;
  onDropRejected(): void;
  onCrop(cropper: any): void;
  onSubmit(): void;
}

const ChangeGroupAvatarView = ({
  avatar,
  loading,
  isModalOpen,
  loadingRefetch,
  closeModal,
  avatarPreview,
  fileLoading,
  configDropZone,
  configCropper,
  isFileValid,
  error,
  onDropAccepted,
  onDropRejected,
  onCrop,
  onSubmit
}: Props) => (
  <React.Fragment>
    <DropZone
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      dropZoneClassName={styles.dropZoneWrapper}
      config={configDropZone}
    >
      <div className={styles.dropZoneBox}>
        <img src={avatar} alt="" className={styles.avatar} />
        {loadingRefetch ? (
          <div className={styles.dropZoneLoader}>
            <Loader width="40px" />
          </div>
        ) : (
          <p className={styles.dropZoneHover}>Upload an image</p>
        )}
      </div>
    </DropZone>

    {!isFileValid && <div className="error cntr">File is not valid</div>}

    <Modal
      isModalOpen={isModalOpen && !!avatarPreview}
      onBtnClose={closeModal}
      modalWidth="sm"
    >
      <div className={styles.modalContent}>
        <Cropper
          onCrop={onCrop}
          cropperClassName={styles.cropperBox}
          config={configCropper}
        />

        {error && <div className="error cntr pt10">Error on upload</div>}

        <div className={styles.btnApplyWrapper}>
          <button
            className={styles.btnApply}
            onClick={onSubmit}
            disabled={loading || fileLoading}
          >
            Apply
          </button>
        </div>
      </div>
    </Modal>
  </React.Fragment>
);

export default avatarUploaderDecorator(ChangeGroupAvatarView);
