import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { addUserToGroupMutation } from '../../../../../graphql';
import Log from '../../../../../Log';

class MemberItem extends React.Component {
  static contextTypes = {
    currentWorkspaceId: PropTypes.string
  };

  onAddUser = addUser => {
    const { groupId, member, addUserToState } = this.props;
    const { currentWorkspaceId } = this.context;

    addUser({
      variables: {
        groupId,
        workspaceId: currentWorkspaceId,
        userId: member.id
      }
    })
      .then(() => {
        addUserToState();
      })
      .catch(err => {
        Log.error(err, 'MemberItem.onAddUser');
      });
  };

  render() {
    const { member, memberIds, isVisibleInvitation } = this.props;

    return (
      <div className="group-member">
        <div className="group-member-photo">
          <img alt="" src={member.avatar} />
        </div>
        <div className="group-member-name">
          <span>{member.name}</span>
        </div>
        {isVisibleInvitation && (
          <div className="invitation-wrapper">
            <Mutation mutation={addUserToGroupMutation}>
              {(addUser, res) => {
                const { loading, error } = res;

                const isAdded =
                  memberIds && memberIds.some(item => item === member.id);

                return (
                  <React.Fragment>
                    {!isAdded && (
                      <React.Fragment>
                        {!loading && !error && (
                          <button
                            className="invite-btn"
                            type="button"
                            onClick={() => this.onAddUser(addUser)}
                          >
                            Invite
                          </button>
                        )}
                        {loading && <div>Pending invitation</div>}
                        {error && <div className="error">Error</div>}
                      </React.Fragment>
                    )}
                    {isAdded && <div>Added</div>}
                  </React.Fragment>
                );
              }}
            </Mutation>
          </div>
        )}
      </div>
    );
  }
}

MemberItem.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  addUserToState: PropTypes.func,
  memberIds: PropTypes.arrayOf(PropTypes.string),
  isVisibleInvitation: PropTypes.bool
};

MemberItem.defaultProps = {
  isVisibleInvitation: false,
  memberIds: [],
  addUserToState: () => {}
};

export default MemberItem;
