import React from 'react';
import { ReactionType } from './ReactionList.types';
import { ReactionListView } from './ReactionListView';

interface State {
  position: {
    left: string;
    top: string;
  };
}

interface Props {
  reactions: ReactionType[];
  onSelectReaction?(reactionName: string): void;
}

class ReactionList extends React.Component<Props, State> {
  public state = {
    position: {
      left: '0px',
      top: '0px'
    }
  };

  public render() {
    const { position } = this.state;
    const { reactions, onSelectReaction } = this.props;

    return (
      <ReactionListView
        position={position}
        onMouseEnter={this.onMouseEnter}
        reactions={reactions}
        onSelectReaction={onSelectReaction}
      />
    );
  }

  private onMouseEnter = (e: any) => {
    const { top, left } = e.target.getBoundingClientRect();

    this.setState({
      position: {
        top: top + 'px',
        left: left + 'px'
      }
    });
  };
}

export { ReactionList };
