import * as CONST from './Validator.constants';

const emptyFieldText = 'Please fill out required fields';
export const incorrectPasswordText = 'Seems you put incorrect password';
export const incorrectEmailOrPasswordText =
  'Seems you put incorrect email or password';

const getErrorText = (errors: any) => {
  switch (errors.email) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.INCORRECT_FIELD:
      return 'Seems you put incorrect email';
  }

  switch (errors.name) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.SHORT_FIELD:
      return `Your name can not be less than ${
        CONST.USER_NAME_MIN_LENGTH
      } symbols`;
  }

  switch (errors.login) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.SHORT_FIELD:
      return `Your nickname can not be less than ${
        CONST.USER_NICKNAME_MIN_LENGTH
      } symbols`;
  }

  switch (errors.password || errors.confirmedPassword) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.SHORT_FIELD:
      return `Your password can not be less than ${
        CONST.PASSWORD_MIN_LENGTH
      } symbols`;
    case CONST.INCORRECT_FIELD:
      return 'Use upper- and lowercase, number and symbol';
    case CONST.NOT_EQUAL:
      return 'Passwords are not equal';
  }

  switch (errors.workspaceName) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.SHORT_FIELD:
      return `Name can not be less than ${
        CONST.WORKSPACE_NAME_MIX_LENGTH
      } symbols`;
    case CONST.INCORRECT_FIELD:
      return 'Name must contain letters or/and numbers';
  }

  switch (errors.postTitle) {
    case CONST.EMPTY_FIELD:
      return 'Please create a title or a description for your post';
    case CONST.LONG_FIELD:
      return `Title can not be longer than ${
        CONST.POST_TITLE_MAX_LENGTH
      } symbols`;
  }

  switch (errors.postDescription) {
    case CONST.EMPTY_FIELD:
      return 'Please create a title or a description for your post';
    case CONST.LONG_FIELD:
      return `Description can not be longer than ${
        CONST.POST_DESCRIPTION_MAX_LENGTH
      } symbols`;
  }

  switch (errors.postTo) {
    case CONST.EMPTY_FIELD:
      return 'Please select at least one group or user to post';
  }

  switch (errors.form) {
    case CONST.EMPTY_FIELD:
      return 'Please fill out at least one field';
  }

  if (errors.onSubmit) {
    return errors.onSubmit;
  }

  return '';
};

export { getErrorText };
