import React from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import workspaceFrame from '../../../assets/img/workspace_frame2.png';
import { ButtonPrimary, InputField } from '../../UI';
// @ts-ignore
import styles from '../../UI/styles/externalPages.module.scss';
import { ErrorTypes, FieldTypes } from '../types';

interface Props {
  inputNames: string[];
  fields: FieldTypes;
  errors: ErrorTypes;
  errorText: string;
  loading: boolean;
  onChange(e: any, inputName: string): void;
  onSubmit(e: any): void;
}

const FirstInviteToWorkspaceView = ({
  inputNames,
  fields,
  errors,
  errorText,
  loading,
  onChange,
  onSubmit
}: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.containerCol}>
        <div className={styles.content}>
          <p className={styles.title}>Invite your teammates</p>
          <form
            onSubmit={onSubmit}
            className={`${styles.form} ${styles.inviteForm}`}
          >
            <fieldset disabled={loading}>
              {inputNames.map((inputName: string) => (
                <InputField
                  key={inputName}
                  name="email"
                  placeholder="emailaddress@mail.com"
                  value={fields[inputName].email}
                  onChange={e => onChange(e, inputName)}
                  // @ts-ignore
                  error={errors[inputName].errors.email}
                />
              ))}

              <div className="error cntr">{errorText}</div>

              <div className={styles.submitBtnBox}>
                <ButtonPrimary type="submit">Invite</ButtonPrimary>
              </div>
            </fieldset>
          </form>

          <div className={styles.skipInvitationBox}>
            Or, <Link to={'/welcome'}>skip for now</Link>
          </div>
        </div>
      </div>

      <div className={styles.containerCol}>
        <div className={styles.imageBox}>
          <img src={workspaceFrame} alt="" />
        </div>
      </div>
    </div>
  </div>
);

export { FirstInviteToWorkspaceView };
