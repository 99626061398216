import {
  filters,
  loadingState,
  networkState,
  postView,
  wakeUpState
} from './operations';

export const resolvers = {
  Mutation: {
    loadingState,
    postView,
    filters,
    networkState,
    wakeUpState
  }
};
