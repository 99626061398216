import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { InviteUserToWorkspaceWrap } from '../InviteUserToWorkspaceWrap';

interface FirstInviteToWorkspaceProps extends RouteComponentProps<{}> {
  workspaceId: string;
}

const FirstInviteToWorkspace = withRouter<FirstInviteToWorkspaceProps>(
  ({ workspaceId, history }) => (
    <InviteUserToWorkspaceWrap
      onSuccessfulInvitation={() => history.push('/welcome')}
      isInvitationFirst={true}
      workspaceId={workspaceId}
    />
  )
);

export { FirstInviteToWorkspace };
