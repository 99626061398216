import classNames from 'classnames';
import React from 'react';
import { Mutation } from 'react-apollo';
import { deleteCommentMutation } from '../../../../graphql';
import { toFullDateString, toTimeString } from '../../../../utils/dateUtils';
import { wrapMention } from '../../../helpers';
import { AllReactions, ReactionList } from '../../../Reactions';
import { Emoji, Pencil, Trash } from '../../../UI/Icons';
import CommentEditingForm from '../CommentEditingForm';
import { CommentType } from '../Comments.types';
// @ts-ignore
import styles from './comment.module.scss';
import './Comment.scss';

interface Props {
  comment: CommentType;
  threadId: string;
  currentWorkspaceId: string;
  currentUserId: string;
  isEditFormVisible: boolean;
  mentions: Array<{}>;
  isReactionBoxVisible: boolean;
  positionReactionBox: {
    top: string;
    left: string;
  };
  fetchMentions(): void;
  showEditForm(): null;
  hideEditForm(): null;
  deleteComment(func: any, id: string): void;
  showReactionBox(e: any): void;
  hideReactionBox(): void;
  onSelectReaction(reactionName: string): void;
}

const CommentView = (props: Props) => {
  const {
    comment,
    threadId,
    currentWorkspaceId,
    currentUserId,
    isEditFormVisible,
    showEditForm,
    hideEditForm,
    deleteComment,
    mentions,
    isReactionBoxVisible,
    positionReactionBox,
    fetchMentions,
    showReactionBox,
    hideReactionBox,
    onSelectReaction
  } = props;

  const isOwnComment =
    (comment.createdBy && comment.createdBy.id) === currentUserId;

  const avatar = comment.createdBy && comment.createdBy.avatar;
  const commentDate = comment.showUser
    ? toFullDateString(comment.createdAt)
    : toTimeString(comment.createdAt);

  const commentClassName = classNames('comment', styles.comment, {
    'comment-with-avatar': comment.showUser,
    [styles.editingView]: isEditFormVisible
  });

  const labelEdited = comment.editedAt
    ? `<span class="${styles.labelEdited}">(edited)</span>`
    : '';

  return (
    <div className={commentClassName} id={comment.id}>
      <div className="comment-aside">
        {comment.showUser ? (
          avatar && <img src={avatar} alt="" className="c-avatar" />
        ) : (
          <span className="comment-time">{commentDate}</span>
        )}
      </div>

      <div className="comment-content">
        {comment.showUser && (
          <div className="user-info">
            <span className="c-name">
              {comment.createdBy &&
                (comment.createdBy.name || comment.createdBy.login)}
            </span>
            <span className="c-time">{commentDate}</span>
          </div>
        )}

        {!isEditFormVisible && (
          <React.Fragment>
            <div
              className="comment-body"
              dangerouslySetInnerHTML={{
                __html: wrapMention(comment.comment) + labelEdited
              }}
            />
            {comment.reactions && comment.reactions.length > 0 && (
              <div className={styles.reactionsList}>
                <ReactionList
                  reactions={comment.reactions}
                  onSelectReaction={onSelectReaction}
                />
              </div>
            )}
          </React.Fragment>
        )}

        {isEditFormVisible && (
          <CommentEditingForm
            previousComment={comment.rawComment}
            currentWorkspaceId={currentWorkspaceId}
            commentId={comment.id}
            threadId={threadId}
            hideEditForm={hideEditForm}
            currentUserId={currentUserId}
            mentions={mentions}
            fetchMentions={fetchMentions}
          />
        )}
      </div>

      {!isEditFormVisible && (
        <div className={styles.menu}>
          <div className={styles.btnsBox}>
            {isOwnComment && (
              <Mutation mutation={deleteCommentMutation}>
                {(deleteCommentOnPost: any, data: any) => (
                  <button
                    type="button"
                    className={styles.btn}
                    onClick={() =>
                      deleteComment(deleteCommentOnPost, comment.id)
                    }
                    disabled={data.loading}
                    data-action="deleteComment"
                  >
                    <Trash />
                  </button>
                )}
              </Mutation>
            )}
            {isOwnComment && (
              <button
                type="button"
                className={styles.btn}
                onClick={showEditForm}
                data-action="showEditCommentForm"
              >
                <Pencil />
              </button>
            )}
            <button
              type="button"
              className={styles.btn}
              onClick={showReactionBox}
              data-action="showCommentReactionBox"
            >
              <Emoji />
            </button>
          </div>
        </div>
      )}

      {isReactionBoxVisible && (
        <div
          className={styles.allReactionsBox}
          style={positionReactionBox}
          onMouseLeave={hideReactionBox}
        >
          <AllReactions onClick={onSelectReaction} />
        </div>
      )}
    </div>
  );
};

export default CommentView;
