import React from 'react';
import { compose, withApollo, withQuery } from 'react-apollo';
import {
  logoutFunc,
  RefreshToken as RefreshTokenComponent
} from '../../Components/helpers';
import { FirstInviteToWorkspace } from '../../Components/InviteUserToWorkspace';
import withWorkspace from '../../Components/MainPage/withWorkspace';
import PageHeader from '../../Components/PageHeader';
import { Loader } from '../../Components/UI';
import { getCurrentUser, getWorkspace } from '../../graphql';
import Log from '../../Log';
import TokenStore from '../../services';
// @ts-ignore
import styles from '../pages.module.scss';

interface Props {
  workspaceLoading: boolean;
  workspaceError: any;
  workspaceData: {
    id: string;
  };
}

const InviteUsersToWorkspaceComponent = ({
  workspaceLoading,
  workspaceError,
  workspaceData
}: Props) => {
  if (!TokenStore.getRefreshToken()) {
    return logoutFunc();
  }

  if (!TokenStore.getIdentityToken()) {
    return <RefreshTokenComponent tokenStore={TokenStore} />;
  }

  if (workspaceLoading) {
    return (
      <div className={styles.wrapper}>
        <div className="cntr">
          <Loader />
        </div>
      </div>
    );
  }

  if (workspaceError) {
    Log.graphQLError(workspaceError, 'errorWorkspace');
    return <div>Server error</div>;
  }

  return (
    <React.Fragment>
      <PageHeader />
      <FirstInviteToWorkspace workspaceId={workspaceData.id} />
    </React.Fragment>
  );
};

const InviteUsersToWorkspacePage = compose(
  withWorkspace,
  withQuery(getWorkspace, {
    options: ({ workspaceSlug }: any) => ({
      variables: {
        slug: workspaceSlug
      }
    }),
    props: ({ data }: any) => ({
      workspaceLoading: data.loading,
      workspaceError: data.error,
      workspaceData: data.workspace
    }),
    skip: ({ workspaceSlug }) => !workspaceSlug
  }),
  withApollo
)(InviteUsersToWorkspaceComponent);

export { InviteUsersToWorkspacePage };
