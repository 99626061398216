import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import styles from './inputField.module.scss';

interface Props {
  type?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  error?: string;
  maxLength?: number;
  children?: any;
  showChildrenOnFocus?: boolean;
  autoComplete?: string;
  wrapperClassName?: string;
  onChange?(e: any): void;
  onFocus?(e: any): void;
}

const InputField = ({
  type = 'text',
  error = '',
  children = null,
  showChildrenOnFocus = false,
  wrapperClassName = '',
  ...rest
}: Props) => (
  <div className={classNames(styles.box, wrapperClassName)}>
    <input
      type={type}
      className={classNames(styles.field, {
        [styles.error]: error,
        [styles.showChildrenOnFocus]: showChildrenOnFocus
      })}
      {...rest}
    />
    {children}
  </div>
);

export { InputField };
