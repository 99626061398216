import gql from 'graphql-tag';
import { PostFragment, ReactionFragment } from './fragments';

export const getFeedQuery = gql`
  query Feed(
    $workspaceId: UUID!
    $after: String
    $postFilterType: PostFilterType
    $ratingFilter: RatingFilterInput
    $groupFilter: GroupFilterInput
    $actorFilter: ActorFilterInput
    $textSearchFilter: TextSearchFilterInput
  ) {
    posts(
      sortOrder: DESC
      workspaceId: $workspaceId
      first: 10
      after: $after
      postFilter: {
        postFilterType: $postFilterType
        ratingFilter: $ratingFilter
        groupFilter: $groupFilter
        actorFilter: $actorFilter
        textSearchFilter: $textSearchFilter
      }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }

      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }

  ${PostFragment}
`;

export const getPostQuery = gql`
  query Post($workspaceId: UUID!, $postId: UUID!) {
    post(workspaceId: $workspaceId, postId: $postId) {
      ...PostFragment
    }
  }
  ${PostFragment}
`;

export const createPostMutation = gql`
  mutation CreatePost(
    $origin: String!
    $tags: [PostTagInput]!
    $groupIds: [UUID]!
    $userIds: [UUID]!
    $description: String!
    $workspaceId: UUID!
    $title: String!
    $attachments: [FileAttachmentInput]
    $actions: ActionsInput
  ) {
    createPost(
      createPostRequest: {
        origin: $origin
        tags: $tags
        audience: { groupIds: $groupIds, userIds: $userIds }
        description: $description
        workspaceId: $workspaceId
        title: $title
        attachments: $attachments
        actions: $actions
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const editPostMutation = gql`
  mutation EditPost(
    $tags: [PostTagInput]
    $description: String
    $workspaceId: UUID!
    $title: String
    $attachments: [FileAttachmentInput]
    $postId: UUID!
  ) {
    editPost(
      editPostRequest: {
        tags: $tags
        description: $description
        workspaceId: $workspaceId
        title: $title
        attachments: $attachments
        postId: $postId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const sharePostMutation = gql`
  mutation SharePost(
    $workspaceId: UUID!
    $userIds: [UUID]!
    $groupIds: [UUID]!
    $postId: UUID!
    $shareComment: String!
  ) {
    sharePost(
      sharePostRequest: {
        workspaceId: $workspaceId
        audience: { groupIds: $groupIds, userIds: $userIds }
        postId: $postId
        shareComment: $shareComment
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const ratePostMutation = gql`
  mutation RatePost($postId: UUID!, $workspaceId: UUID!, $rating: Int!) {
    ratePost(
      ratePostRequest: {
        postId: $postId
        rating: $rating
        workspaceId: $workspaceId
      }
    ) {
      __typename
      error {
        __typename
        errorCode
        errorMessage
      }
    }
  }
`;

export const updateFeedSubscription = gql`
  subscription Feed($workspaceId: UUID!) {
    posts(workspaceId: $workspaceId) {
      ... on Post {
        ...PostFragment
      }
    }
  }

  ${PostFragment}
`;

export const postSubscription = gql`
  subscription Post($postId: UUID!, $workspaceId: UUID!) {
    post(postId: $postId, workspaceId: $workspaceId) {
      ... on RatedPost {
        id
        rating
      }
      ... on PostReactionUpdatedSubscription {
        id
        reactions {
          ...ReactionFragment
        }
      }
    }
  }

  ${ReactionFragment}
`;
