import React from 'react';
import Slider from 'react-slick';
import './styles.scss';

interface Props {
  settings?: {};
  children: any;
}

const SliderComponent = ({ settings = {}, children }: Props) => (
  <Slider {...settings}>{children}</Slider>
);

export { SliderComponent };
