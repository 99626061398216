import classNames from 'classnames';
import React from 'react';
import TagsInput from 'react-tagsinput';
// @ts-ignore
import style from './tags.module.scss';

interface Props {
  tags: string[];
  boxClassName?: string;
  inputProps?: object;
  onChange(tags: any): void;
}

const TagsComponent = ({
  tags,
  boxClassName = '',
  inputProps = {},
  onChange
}: Props) => (
  <TagsInput
    className={classNames(style.wrapper, boxClassName)}
    focusedClassName="focus"
    value={tags}
    onChange={onChange}
    // @ts-ignore
    addKeys={[13, 32, ',']} // enter, space, comma
    inputProps={inputProps}
    onlyUnique={true}
    addOnPaste={false}
    addOnBlur={true}
  />
);

export { TagsComponent };
