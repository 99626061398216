import classNames from 'classnames';
import React from 'react';
import { Checkmark } from '../../../UI/Icons';
// @ts-ignore
import styles from './dropdownContacts.module.scss';

interface Props {
  item: {
    avatar: string;
    name: string;
  };
  isSelected: boolean;
  onToggleContact(e: any): void;
}

function DropdownContactsItemView(props: Props) {
  const { item, isSelected, onToggleContact } = props;

  const itemClassName = classNames(styles.itemBox, {
    [styles.selected]: isSelected
  });

  return (
    <div className={itemClassName}>
      <input
        type="checkbox"
        checked={isSelected}
        className={styles.checkbox}
        onChange={onToggleContact}
      />
      <img src={item.avatar} alt="" className={styles.itemAvatar} />
      <span className={styles.itemName}>{item.name}</span>

      {isSelected && (
        <div className={styles.checkmark}>
          <Checkmark width="16px" />
        </div>
      )}
    </div>
  );
}

export { DropdownContactsItemView };
