import React from 'react';
import PropTypes from 'prop-types';
import ItemView from '../ItemView';
import { Loader } from '../../UI';
import '../../../index.scss';
import { FeedApi } from '../../../services';

class Users extends React.Component {
  fetchFeedForUser = userId => {
    const { setActiveId } = this.props;
    setActiveId(userId);
    FeedApi.fetchFeedByUser(userId);
  };

  render() {
    const { users, activeId, loadingUsers } = this.props;

    // Commented this out because otherwise we never show the loader...
    // if (users.length === 0) {
    //     return null;
    // }

    return (
      <React.Fragment>
        {users.map(user => {
          if (!user.node) {
            return null;
          }

          return (
            <div
              key={user.node.id}
              onClick={() => this.fetchFeedForUser(user.node.id)}
            >
              <ItemView item={user.node} isActive={activeId === user.node.id} />
            </div>
          );
        })}
        {loadingUsers && (
          <div className="cntr">
            <Loader width="50px" />
          </div>
        )}
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  // eslint-disable-next-line
  users: PropTypes.array,
  activeId: PropTypes.string.isRequired,
  setActiveId: PropTypes.func.isRequired,

  loadingUsers: PropTypes.bool
};
Users.defaultProps = {
  users: [],
  loadingUsers: false
};
export default Users;
