import React from 'react';
import PropTypes from 'prop-types';
// import { graphql } from 'react-apollo';
// import { getCurrentUser } from '../../../graphql';
// import Redmine from './Redmine';
import './style.scss';
// import { Loader } from '../../UI';
import bujGroup from '../../../assets/img/buj-group.png';

// eslint-disable-next-line react/prefer-stateless-function
class ServiceConnectors extends React.Component {
  render() {
    // const {
    //   data: { loading, error, currentUser }
    // } = this.props;
    //
    // if (loading) {
    //   return (
    //     <span className="loader">
    //       <Loader width="24px" />
    //     </span>
    //   );
    // }
    //
    // if (error) {
    //   return <span>Error</span>;
    // }

    return (
      <div className="IndexCard">
        <div className="ic-expanded service-post">
          <div className="ic-expanded-left">
            <div className="ic-expanded-header">
              <img src={bujGroup} className="ic-expanded-header-img" alt="" />
              <div>
                <span className="icard-header-title">Buj</span>
              </div>
            </div>

            <div className="icard-content">
              <div className="icard-content-text">
                <div>
                  <div className="icard-content-title">
                    Congratulations on joining Buj and welcome to the pilot
                    program!
                    <br />
                    Here are some ideas to get you started.
                  </div>
                  <div className="icard-description">
                    <p>
                      To get started, create groups for your team members (or
                      use proposed) to share project updates and news, discuss
                      objectives with the team or initiate direct conversations
                      while tracking common goals.
                    </p>
                    {/* <p> */}
                    {/* Buj also supports using scripts to automatically generate */}
                    {/* posts based on relevant data gathered from other systems */}
                    {/* to ensure the distribution and visibility of updated */}
                    {/* information when it’s needed most. Now we can integrate */}
                    {/* you with Gitlab, Redmine and Microsoft services. To */}
                    {/* connect just follow related links :{' '} */}
                    {/* <a */}
                    {/* href={`${ */}
                    {/* process.env.REACT_APP_REDMINE_BASE_ENDPOINT */}
                    {/* }/bujoki-connectors/connector/oauth2/start/gitlab/${ */}
                    {/* currentUser.login */}
                    {/* }`} */}
                    {/* target="_blank" */}
                    {/* rel="noopener noreferrer" */}
                    {/* className="btn-service" */}
                    {/* > */}
                    {/* Gitlab */}
                    {/* </a> */}
                    {/* ,{' '} */}
                    {/* <a */}
                    {/* href={`${ */}
                    {/* process.env.REACT_APP_REDMINE_BASE_ENDPOINT */}
                    {/* }/bujoki-connectors/connector/oauth2/start/microsoft/${ */}
                    {/* currentUser.login */}
                    {/* }`} */}
                    {/* target="_blank" */}
                    {/* rel="noopener noreferrer" */}
                    {/* className="btn-service" */}
                    {/* > */}
                    {/* Microsoft */}
                    {/* </a> */}
                    {/* , <Redmine currentUser={currentUser} />. */}
                    {/* </p> */}
                    <p>
                      And most importantly, while you’re using Buj, be sure to
                      share your feedback with us! Tell us what you like or
                      really dislike, what we can do better or differently, a
                      wish list of features or new functionality -- you get the
                      idea.
                    </p>
                    <p>
                      You can submit feedback anywhere in the product. All
                      feedback and comments we receive will be reviewed and
                      tracked by the product team, and myself personally.
                    </p>
                    <p>We look forward to hearing from you!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ServiceConnectors.propTypes = {
  // eslint-disable-next-line
  currentWorkspaceId: PropTypes.string.isRequired
};

// const withCurrentUser = graphql(getCurrentUser, {
//   options: props => {
//     return {
//       variables: {
//         workspaceId: props.currentWorkspaceId
//       }
//     };
//   }
// });
// export default withCurrentUser(ServiceConnectors);
export default ServiceConnectors;
