import { pathOr } from 'ramda';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { getReactions } from '../../../graphql';
import { CategoryType } from './AllReactions.types';
import { AllReactionsView } from './AllReactionsView';

interface Props {
  reactionMetadata: {
    categories: CategoryType[];
  };
  reactionLoading: any;
  reactionError: any;
  onClick?(reactionName: string): void;
}

const ReactionsComponent = ({
  reactionMetadata,
  reactionLoading,
  reactionError,
  onClick = () => {}
}: Props) => {
  if (reactionError) {
    return null;
  }

  const categories = pathOr([], ['categories'], reactionMetadata);

  return (
    <AllReactionsView
      categories={categories}
      reactionLoading={reactionLoading}
      onClick={onClick}
    />
  );
};

const withReactionsData = compose(
  withWorkspaceAndUser,
  graphql(getReactions, {
    options: (props: any) => {
      return {
        variables: {
          workspaceId: props.workspaceId
        }
      };
    },
    props: ({ data }: any) => ({
      reactionMetadata: data.reactionMetadata,
      reactionLoading: data.loading,
      reactionError: data.error
    })
  })
);

const AllReactions = withReactionsData(ReactionsComponent);

export { AllReactions };
