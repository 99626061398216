import React from 'react';
import Waypoint from 'react-waypoint';
import Loader from '../../../UI/Loader';
// @ts-ignore
import styles from './dropdownContacts.module.scss';
import { DropdownContactsItemView } from './DropdownContactsItemView';

interface Props {
  value: string;
  usersList: [
    {
      node: {
        avatar: string;
        name: string;
      };
    }
  ];
  groupsList: [
    {
      node: {
        avatar: string;
        name: string;
      };
    }
  ];
  loading: boolean;
  error: boolean;
  hasUsersNextPage: boolean;
  hasGroupsNextPage: boolean;
  onInputChange(e: any): void;
  onToggleContact(e: any, contact: any): void;
  fetchMoreUsers(): void;
  fetchMoreGroups(): void;
  isContactSelected(id: any): boolean;
}

function DropdownContactsView(props: Props) {
  const {
    value,
    onInputChange,
    usersList,
    groupsList,
    loading,
    error,
    isContactSelected,
    onToggleContact,
    hasUsersNextPage,
    hasGroupsNextPage,
    fetchMoreUsers,
    fetchMoreGroups
  } = props;

  return (
    <div className={styles.box}>
      <p className={styles.title}>Proposed teams and users</p>
      <div className={styles.inputBox}>
        <input
          type="text"
          value={value}
          onChange={onInputChange}
          placeholder="Search"
          className={styles.input}
          maxLength={80}
        />
      </div>

      <div className={styles.contactsList}>
        {usersList.map((item: any) => {
          if (!item.node) {
            return null;
          }

          return (
            <DropdownContactsItemView
              key={item.node.id}
              item={item.node}
              isSelected={isContactSelected(item.node.id)}
              onToggleContact={(e: any) => onToggleContact(e, item)}
            />
          );
        })}

        {hasUsersNextPage && !value && <Waypoint onEnter={fetchMoreUsers} />}

        {groupsList.map((item: any) => {
          if (!item.node) {
            return null;
          }

          return (
            <DropdownContactsItemView
              key={item.node.id}
              item={item.node}
              isSelected={isContactSelected(item.node.id)}
              onToggleContact={(e: any) => onToggleContact(e, item)}
            />
          );
        })}

        {hasGroupsNextPage && !value && <Waypoint onEnter={fetchMoreGroups} />}

        {loading && (
          <div className="cntr">
            <Loader width="40px" />
          </div>
        )}
      </div>

      {error && <div className="error cntr">Error</div>}

      {!loading &&
        !error &&
        value &&
        usersList.length < 1 &&
        groupsList.length < 1 && (
          <div className="cntr">
            No matches found.
            <br />
            Did you spell it correctly?
          </div>
        )}
    </div>
  );
}

export { DropdownContactsView };
