import { EnvService } from '../EnvService';

export interface IPath {
  resolveHttpProtocol: (v: string) => string;
  resolveWsProtocol: (v: string) => string;
  resolveHost: (v: string[], b: boolean) => string;
}

const localApiPath = (): IPath => ({
  resolveHttpProtocol: (protocol: string) => 'https:',
  resolveWsProtocol: (protocol: string) => 'wss:',
  resolveHost: (hostChunks: string[], hasEnvName: boolean) =>
    'graphql.staging.bujapp.net'
});

const webApiPath = (): IPath => ({
  resolveHttpProtocol: (protocol: string) => protocol,
  resolveWsProtocol: (protocol: string) =>
    protocol === 'http:' ? 'ws:' : 'wss:',
  resolveHost: (hostChunks: string[], hasEnvName: boolean) => {
    const domainNameIndex = hostChunks.indexOf('bujapp');
    const decrement = hasEnvName ? 1 : 0;
    const takeFrom =
      domainNameIndex !== 0 ? domainNameIndex - decrement : domainNameIndex;

    return ['graphql'].concat(hostChunks.slice(takeFrom)).join('.');
  }
});

export class ApiPathConstructor {
  public readonly gqlPath: string;
  public readonly wsPath: string;
  public readonly siteUrl: string;
  private readonly pathResolver: IPath;

  constructor(private location: Location, private envTypes: object) {
    const { protocol, hostname } = location;
    const hostChunks = this.splitToParts(hostname);
    const hasEnvName = this.hasEnvSubDomain(hostChunks);

    this.pathResolver = EnvService.isLocal ? localApiPath() : webApiPath();
    this.gqlPath = this.createGqlPath(protocol, hostChunks, hasEnvName);
    this.wsPath = this.createWsPath(protocol, hostChunks, hasEnvName);
    this.siteUrl = this.createUrlPath(protocol, hostChunks, hasEnvName);
  }

  private splitToParts(host: string) {
    return host.split('.');
  }

  private hasEnvSubDomain(chunks: string[]): boolean {
    return chunks.some((chunk: string) => this.envTypes.hasOwnProperty(chunk));
  }

  private createGqlPath(protocol: string, host: string[], hasEnvName: boolean) {
    return `${this.pathResolver.resolveHttpProtocol(
      protocol
    )}//${this.pathResolver.resolveHost(host, hasEnvName)}/graphql`;
  }

  private createWsPath(protocol: string, host: string[], hasEnvName: boolean) {
    return `${this.pathResolver.resolveWsProtocol(
      protocol
    )}//${this.pathResolver.resolveHost(host, hasEnvName)}/graphql`;
  }

  private createUrlPath(protocol: string, host: string[], hasEnvName: boolean) {
    return `${this.pathResolver.resolveHttpProtocol(
      protocol
    )}//${this.pathResolver.resolveHost(host, hasEnvName)}`;
  }
}
