/*
 * listenWakeUp function compares browser time every two seconds to detect awake
 * cause browser cannot detect when computer goes to sleep mode / awake
 */
export const listenWakeUp = (callback: (time: number) => void): void => {
  let lastTime: number = new Date().getTime();

  setInterval(() => {
    const currentTime: number = new Date().getTime();

    if (currentTime > lastTime + 2000 * 2) {
      setTimeout(() => {
        callback(currentTime);
      }, 2000);
    }
    lastTime = currentTime;
  }, 2000);
};
