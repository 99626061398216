import {
  FILTER_BY_GROUP,
  FILTER_BY_RATING,
  FILTER_BY_TEXT,
  FILTER_BY_USER,
  POST_FILTER_TYPE_NAME,
  POST_FILTER_TYPES
} from '../../constants';
import { FilterService } from './';

function getFilters() {
  const tokenPostFilterType =
    FilterService.getFilter(POST_FILTER_TYPE_NAME) ||
    POST_FILTER_TYPES.USER_FEED;
  const tokenFilterByGroup = FilterService.getFilter(FILTER_BY_GROUP) || null;
  const tokenFilterByUser = FilterService.getFilter(FILTER_BY_USER) || null;
  // rating is hidden according to BUJ-419
  const tokenFilterByRating = 0;
  // const tokenFilterByRating = Number(FilterService.getFilter(FILTER_BY_RATING)) || 0;
  const tokenFilterByText = FilterService.getFilter(FILTER_BY_TEXT) || '';

  const filterByGroupState =
    tokenFilterByGroup && tokenFilterByGroup !== 'null'
      ? tokenFilterByGroup
      : null;
  const filterByUserState =
    tokenFilterByUser && tokenFilterByUser !== 'null'
      ? tokenFilterByUser
      : null;

  return {
    postFilterState: tokenPostFilterType,
    filterByGroupState,
    filterByUserState,
    filterByRatingState: tokenFilterByRating,
    filterByTextState: tokenFilterByText
  };
}

// eslint-disable-next-line import/prefer-default-export
export { getFilters };
