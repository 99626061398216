import React from 'react';
import { TagsComponent } from '../../../UI';
import Item from '../Item';
import PlusBtn from '../PlusBtn';
// @ts-ignore
import styles from './postTags.module.scss';

interface Props {
  tags: string[];
  onAddTag(tags: any): void;
}

function PostTags(props: Props) {
  const { tags, onAddTag } = props;

  return (
    <Item
      label="#tags:"
      content={
        <div className={styles.tagsWrapper} title="No more than 50">
          <TagsComponent
            tags={tags}
            onChange={onAddTag}
            boxClassName={styles.tagsBox}
            inputProps={{
              placeholder: '',
              disabled: tags.length === 50,
              maxLength: 50
            }}
          />
          <div className={styles.addTagBtn}>
            <PlusBtn />
          </div>
        </div>
      }
    />
  );
}

export { PostTags };
