import { path, pathOr } from 'ramda';
import { getDateDiffInMinutes } from '../../../utils/dateUtils';

export const pickCommentUserId = (comment: object) =>
  path(['node', 'createdBy', 'id'], comment);
export const pickCommentTime = (comment: object): string =>
  pathOr('', ['node', 'createdAt'], comment);

export const gatherCommentsByUserIdAndTime = (comments: any[], minutes = 5) => {
  // method enhance comment object with prop 'showUser'
  // array of comments is received in DESC order and reversed
  // method maps comments by user id and created at from last to first in the list
  return comments.map((current, index) => {
    const next = comments[index + 1];

    const isAvatarVisible =
      !!next &&
      (pickCommentUserId(next) !== pickCommentUserId(current) ||
        getDateDiffInMinutes(pickCommentTime(next), pickCommentTime(current)) >
          minutes);

    return {
      ...current.node,
      showUser: isAvatarVisible || !next
    };
  });
};
