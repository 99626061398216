import React from 'react';
import { avatarUploaderDecorator } from '../../../avatarUploaderDecorator';
import Cropper from '../../../UI/Cropper';
import DropZone from '../../../UI/DropZone';
// @ts-ignore
import styles from './changeUserAvatar.module.scss';

interface Props {
  avatar: string;
  loading: boolean;

  avatarPreview: string;
  fileLoading: boolean;
  configDropZone: {};
  configCropper: {};
  isFileValid: boolean;
  error: boolean;
  onDropAccepted(files: any): void;
  onDropRejected(): void;
  onCrop(cropper: any): void;
  onSubmit(): void;
}

const ChangeUserAvatarView = ({
  avatar,
  loading,
  avatarPreview,
  fileLoading,
  configDropZone,
  configCropper,
  isFileValid,
  error,
  onDropAccepted,
  onDropRejected,
  onCrop,
  onSubmit
}: Props) => (
  <React.Fragment>
    {!avatarPreview && (
      <React.Fragment>
        <DropZone
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          dropZoneClassName={styles.dropZoneWrapper}
          config={configDropZone}
        >
          <div className={styles.dropZoneBox}>
            <img src={avatar} alt="" className={styles.avatar} />
            <p className={styles.dropZoneHover}>Upload an image</p>
          </div>
        </DropZone>
        {!isFileValid && (
          <div className="error cntr pt10">File is not valid</div>
        )}
      </React.Fragment>
    )}

    {avatarPreview && (
      <React.Fragment>
        <Cropper
          onCrop={onCrop}
          cropperClassName={styles.cropperBox}
          config={configCropper}
        />

        <div className={styles.btnApplyWrapper}>
          <button
            className={styles.btnApply}
            onClick={onSubmit}
            disabled={loading || fileLoading}
          >
            Apply
          </button>
        </div>
      </React.Fragment>
    )}

    {error && <div className="error cntr pt10">Error on upload</div>}
  </React.Fragment>
);

export default avatarUploaderDecorator(ChangeUserAvatarView);
