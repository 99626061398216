import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import styles from '../expandedCard.module.scss';

interface Props {
  userAvatar: string;
  userName: string;
  postDate: string;
  editedDate?: string;
  additionalClassName: string;
  onClick(): void;
}

function Header(props: Props) {
  const {
    userAvatar,
    userName,
    postDate,
    editedDate,
    additionalClassName,
    onClick
  } = props;

  return (
    <div
      className={classNames(styles.header, additionalClassName)}
      onClick={onClick}
    >
      <div className={styles.avatarBox}>
        <img src={userAvatar} className={styles.avatar} alt="" />
      </div>
      <div className={styles.headerPostInfo}>
        <p className={styles.userName}>{userName}</p>
        <p className={styles.date}>
          {postDate}
          {editedDate && (
            <span className={styles.editedDate}> (edited: {editedDate})</span>
          )}
        </p>
      </div>
    </div>
  );
}

Header.defaultProps = {
  additionalClassName: '',
  userName: '',
  onClick: () => undefined
};

export default Header;
