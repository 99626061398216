export const IDENTITY_TOKEN_LOCAL_STORAGE_NAME = 'bujIdentityToken';
export const REFRESH_TOKEN_LOCAL_STORAGE_NAME = 'bujRefreshToken';
export const POST_VIEW = 'bujPostView';
export const FILTER_BY_GROUP = 'filterGroupBuj';
export const FILTER_BY_USER = 'filterUserBuj';
export const FILTER_BY_RATING = 'filterRatingBuj';
export const FILTER_BY_TEXT = 'filterTextBuj';
export const POST_FILTER_TYPE_NAME = 'postFilterTypeBuj';

export const POST_FILTER_TYPES = {
  USER_FEED: 'USER_FEED',
  PUBLIC_GROUP_POSTS: 'PUBLIC_GROUP_POSTS'
};

export const GROUPS_TAB = 'groups';
export const USERS_TAB = 'users';

export const TEMPORARY_WORKSPACE_ID = '2e7ea61b-ed25-4b49-9d73-564193c7dad2';
// export const TEMPORARY_WORKSPACE_ID = ''; // temp_ct-dev

export const AVATAR_URL_PART = `${
  process.env.REACT_APP_GRAPHQL_API_HTTP_PROTOCOL
}://${process.env.REACT_APP_GRAPHQL_API_HOST}`;

export const IMAGE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'svg', 'bmp', 'gif'];
