import React from 'react';
import { graphql } from 'react-apollo';
import { getAllWorkspaces } from '../../graphql';
import { EnvService } from '../../services';
import { Loader } from '../UI';
import { IWorkspace } from './Workspaces.types';
import { WorkspacesView } from './WorkspacesView';

interface Props {
  workspaces: IWorkspace[];
  loading: any;
  error: any;
}

class WorkspacesComponent extends React.Component<Props> {
  public onSelectWorkspace = ({ slug, uri }: IWorkspace) => {
    const { protocol, port } = window.location;

    const nextLocation = EnvService.isLocal
      ? `${protocol}//${slug}.${EnvService.type}:${port}`
      : uri;

    window.location.assign(nextLocation);
  };

  public render() {
    const { workspaces, loading, error } = this.props;

    if (loading) {
      return (
        <div className="cntr">
          <Loader />
        </div>
      );
    }

    if (error) {
      return <div className="error cntr">Error</div>;
    }

    return (
      <WorkspacesView
        workspaces={workspaces}
        onSelectWorkspace={this.onSelectWorkspace}
      />
    );
  }
}

const Workspaces = graphql(getAllWorkspaces, {
  props: ({ data }: any) => ({
    workspaces: data.workspaces,
    loading: data.loading,
    error: data.error
  })
})(WorkspacesComponent);

export { Workspaces };
