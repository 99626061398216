import React from 'react';
import {
  getErrorText,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  USER_NAME_MAX_LENGTH,
  USER_NICKNAME_MAX_LENGTH
} from '../../../services';
// @ts-ignore
import styles from './registerByInvitationView.module.scss';

interface Props {
  fields: {
    name: string;
    login: string;
    password: string;
    confirmedPassword: string;
  };
  errors: {
    name: string;
    login: string;
    password: string;
    confirmedPassword: string;
    onSubmit: string;
  };
  loading: boolean;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const RegisterByInvitationView = ({
  fields,
  errors,
  loading,
  onChange,
  onSubmit
}: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.headerTitle}>Welcome to Buj!</p>
      </div>
      <div className={styles.description}>
        From daily conversations to business discussions and teams event
        announcements: everything is in Buj. Get work done with the best
        collaboration hub for your team and data.
      </div>

      <form onSubmit={onSubmit} className={styles.formBox}>
        <fieldset disabled={loading}>
          <div className={styles.fieldsList}>
            <label>Your full name</label>
            <div className={styles.fieldBox}>
              <input
                type="text"
                name="name"
                value={fields.name}
                placeholder="Full name"
                className={errors.name ? styles.errorField : ''}
                onChange={onChange}
                maxLength={USER_NAME_MAX_LENGTH}
              />
            </div>
            <label>Your nickname</label>
            <div className={styles.fieldBox}>
              <input
                type="text"
                name="login"
                value={fields.login}
                placeholder="Nickname"
                className={errors.login ? styles.errorField : ''}
                onChange={onChange}
                maxLength={USER_NICKNAME_MAX_LENGTH}
              />
            </div>
            <label>Set up the password</label>
            <div className={styles.fieldBox}>
              <input
                type="password"
                name="password"
                value={fields.password}
                placeholder="Password"
                className={errors.password ? styles.errorField : ''}
                onChange={onChange}
                maxLength={PASSWORD_MAX_LENGTH}
                autoComplete="new-password"
              />
              <div className={styles.passwordInfo}>
                Use upper- and lowercase, number and symbol. At least{' '}
                {PASSWORD_MIN_LENGTH} characters needed
              </div>
            </div>
            <div className={styles.fieldBox}>
              <input
                type="password"
                name="confirmedPassword"
                value={fields.confirmedPassword}
                placeholder="Confirm password"
                className={errors.confirmedPassword ? styles.errorField : ''}
                onChange={onChange}
                maxLength={PASSWORD_MAX_LENGTH}
              />
            </div>
          </div>

          <div className="error cntr">{getErrorText(errors)}</div>

          <div className={styles.btnAcceptBox}>
            <button type="submit" className={styles.btnAccept}>
              Register
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
);

export { RegisterByInvitationView };
