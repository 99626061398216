import gql from 'graphql-tag';

export interface INetworkState {
  networkState: any;
}

export const networkStateDefaults = {
  networkState: {
    isOnline: true,
    __typename: 'NetworkState'
  }
};

export const getNetworkState = gql`
  query GetAuthentication {
    networkState @client {
      isOnline
    }
  }
`;

export const setNetworkState = gql`
  mutation SetAuthentication($isOnline: Boolean) {
    networkState(isOnline: $isOnline) @client
  }
`;

export const networkState = (
  _: any,
  { isOnline }: any,
  { cache, client }: any
) => {
  const prev = client.readQuery({ query: getNetworkState });

  cache.writeData({
    data: {
      networkState: {
        isOnline,
        __typename: 'NetworkState'
      }
    }
  });

  if (isOnline && prev.isOnline !== isOnline) {
    client.reconnectWebsocket();
  }

  return null;
};
