import React from 'react';
import { Mutation } from 'react-apollo';

import { logoutMutation } from '../../../../graphql';
import logoutFunc from '../../../helpers/logoutFunc/logoutFunc';
import Log from '../../../../Log';

class Logout extends React.Component {
  onLogout = logout => {
    logout().catch(err => {
      Log.error(err, 'logout');
    });

    logoutFunc();
  };

  render() {
    return (
      <Mutation mutation={logoutMutation}>
        {logout => (
          <button type="button" onClick={() => this.onLogout(logout)}>
            Logout
          </button>
        )}
      </Mutation>
    );
  }
}

export default Logout;
