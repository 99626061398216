const wrapMention = (text: string) => {
  if (!/@/.test(text)) {
    return text;
  }

  const regexp = /@[\w#$.\-_]+/g;

  return text.replace(
    regexp,
    (matchPart: string) => `<span class="user-mention">${matchPart}</span>`
  );
};

export { wrapMention };
