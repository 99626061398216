import React from 'react';
import PropTypes from 'prop-types';
import style from './actionsBlock.module.scss';

function ActionsBlock(props) {
  const { actions } = props;

  return (
    <div className={style.wrapper}>
      {actions.map((action, index) => (
        <a
          href={action.url}
          target="_blank"
          rel="noopener noreferrer"
          className={style.btn}
          key={index}
        >
          {action.label}
        </a>
      ))}
    </div>
  );
}

ActionsBlock.propTypes = {
  // eslint-disable-next-line
  actions: PropTypes.array.isRequired
};

export default ActionsBlock;
