import React from 'react';
// @ts-ignore
import styles from './item.module.scss';

interface Props {
  label: string;
  content: any;
  button?: any;
}

function Item(props: Props) {
  const { label, content, button = null } = props;

  return (
    <div className={styles.item}>
      <span className={styles.label}>{label}</span>
      <div className={styles.content}>{content}</div>
      {button && <div className={styles.btnBox}>{button}</div>}
    </div>
  );
}

export { Item };
