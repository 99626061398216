import gql from 'graphql-tag';

export interface IWakeUpState {
  wakeUpState: {
    lastWokeUpTime: number;
    __typename: string;
  };
}

export const wakeUpStateDefaults = {
  wakeUpState: {
    lastWokeUpTime: 0,
    __typename: 'WakeUpState'
  }
};

export const getWakeUpState = gql`
  query GetWakeUpState {
    wakeUpState @client {
      lastWokeUpTime
    }
  }
`;

export const setWakeUpState = gql`
  mutation SetWakeUpState($lastWokeUpTime: Number!) {
    wakeUpState(lastWokeUpTime: $lastWokeUpTime) @client
  }
`;

export const wakeUpState = (
  _: any,
  { lastWokeUpTime }: any,
  { cache, client }: any
) => {
  const prev = client.readQuery({ query: getWakeUpState });

  if (prev.wakeUpState.lastWokeUpTime !== lastWokeUpTime) {
    client.reFetchObservableQueries();
    client.reconnectWebsocket();
  }

  cache.writeData({
    data: {
      wakeUpState: {
        lastWokeUpTime,
        __typename: 'WakeUpState'
      }
    }
  });

  return null;
};
