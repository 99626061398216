import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import './ManageGroup.scss';
import { getGroupQuery } from '../../../../graphql';
import Modal from '../../../UI/Modal/Modal';
import Loader from '../../../UI/Loader';
import ManageGroupContent from './ManageGroupContent';

class ManageGroup extends React.Component {
  state = {
    isModalOpen: false
  };

  openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  render() {
    const { groupId, workspaceId } = this.props;
    const { isModalOpen } = this.state;

    return (
      <React.Fragment>
        <button type="button" onClick={this.openModal}>
          Edit team
        </button>

        <Modal
          isModalOpen={isModalOpen}
          onRequestClose={this.closeModal}
          contentClassName="manage-group-modal-content"
          overlayClassName="manage-group-modal-overlay"
        >
          <Query
            query={getGroupQuery}
            variables={{
              workspaceId,
              groupId
            }}
            fetchPolicy="network-only"
            notifyOnNetworkStatusChange
          >
            {({
              error: errorGroup,
              data: dataGroup,
              fetchMore: fetchMoreGroup,
              updateQuery: updateGroupQuery,
              refetch: refetchGroup,
              networkStatus
            }) => {
              const firstLoading = networkStatus === 1;
              const loadingFetchMore = networkStatus === 3;
              const loadingRefetch = networkStatus === 4;

              if (firstLoading) {
                return (
                  <div className="cntr pt10">
                    <Loader />
                  </div>
                );
              }

              if (errorGroup) {
                return <div>Error</div>;
              }

              return (
                <ManageGroupContent
                  closeModal={this.closeModal}
                  dataGroup={dataGroup}
                  loadingFetchMore={loadingFetchMore}
                  loadingRefetch={loadingRefetch}
                  fetchMoreGroup={fetchMoreGroup}
                  updateGroupQuery={updateGroupQuery}
                  refetchGroup={refetchGroup}
                />
              );
            }}
          </Query>
        </Modal>
      </React.Fragment>
    );
  }
}

ManageGroup.propTypes = {
  groupId: PropTypes.string,
  workspaceId: PropTypes.string.isRequired
};

ManageGroup.defaultProps = {
  groupId: null
};

export default ManageGroup;
