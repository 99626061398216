import React from 'react';
// @ts-ignore
import pagesStyles from '../../../Pages/pages.module.scss';
import {
  getErrorText,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  USER_NAME_MAX_LENGTH,
  USER_NICKNAME_MAX_LENGTH
} from '../../../services';
import PageHeader from '../../PageHeader';
import { ButtonPrimary, InputField } from '../../UI';
// @ts-ignore
import styles from './registerByVerificationView.module.scss';

interface Props {
  fields: {
    name: string;
    login: string;
    password: string;
    confirmedPassword: string;
  };
  errors: {
    name: string;
    login: string;
    password: string;
    confirmedPassword: string;
    onSubmit: string;
  };
  loading: boolean;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const RegisterByVerificationView = ({
  fields,
  errors,
  loading,
  onChange,
  onSubmit
}: Props) => (
  <div className={pagesStyles.wrapper}>
    <PageHeader />

    <div className={pagesStyles.containerFlex}>
      <div className={styles.box}>
        <div className={styles.content}>
          <p className={styles.title}>Start using Buj!</p>
          <form onSubmit={onSubmit} className={styles.formBox}>
            <fieldset disabled={loading}>
              <InputField
                name="name"
                value={fields.name}
                placeholder="Full name"
                onChange={onChange}
                error={errors.name}
                maxLength={USER_NAME_MAX_LENGTH}
              />
              <InputField
                name="login"
                value={fields.login}
                placeholder="Nickname"
                onChange={onChange}
                error={errors.login}
                maxLength={USER_NICKNAME_MAX_LENGTH}
              />
              <InputField
                name="password"
                type="password"
                value={fields.password}
                placeholder="Password"
                onChange={onChange}
                error={errors.password}
                maxLength={PASSWORD_MAX_LENGTH}
                showChildrenOnFocus={true}
                autoComplete="new-password"
              >
                <div className={styles.passwordInfo}>
                  Use upper- and lowercase, number and symbol. At least{' '}
                  {PASSWORD_MIN_LENGTH} characters needed
                </div>
              </InputField>
              <InputField
                name="confirmedPassword"
                type="password"
                value={fields.confirmedPassword}
                placeholder="Confirm password"
                onChange={onChange}
                error={errors.confirmedPassword}
                maxLength={PASSWORD_MAX_LENGTH}
              />

              <div className="error cntr">{getErrorText(errors)}</div>

              <div className={styles.submitBtnBox}>
                <ButtonPrimary type="submit">
                  Next
                  <span className={styles.btnIcon} />
                </ButtonPrimary>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
);

export { RegisterByVerificationView };
