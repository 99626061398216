import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import arrowIcon from '../../../assets/img/arrow-white.svg';

function NewPostNotification(props) {
  const { hideNewPostNotification, scrollToTop, feedWrapper } = props;

  const feedWrapperScrollTop = (feedWrapper && feedWrapper.scrollTop) || 0;

  if (feedWrapperScrollTop < 400) {
    hideNewPostNotification();

    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.notification}>
        <button type="button" className={styles.btnGoUp} onClick={scrollToTop}>
          <img src={arrowIcon} alt="" />
          New post appeared
        </button>
        <button
          type="button"
          className={styles.btnClose}
          onClick={hideNewPostNotification}
        />
      </div>
    </div>
  );
}

NewPostNotification.propTypes = {
  hideNewPostNotification: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired
};

export default NewPostNotification;
