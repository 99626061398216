import classNames from 'classnames';
import React from 'react';
import { JoinGroup } from '../../../JoinGroup';
import { LeaveGroup } from '../../../LeaveGroup';
import ManageGroup from '../ManageGroup';

interface Props {
  workspaceId: string;
  groupId: string;
  isMember: boolean;
}

interface State {
  isMenuShown: boolean;
}

class GroupSettings extends React.Component<Props, State> {
  public wrapperRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      isMenuShown: false
    };

    this.wrapperRef = React.createRef();
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const { workspaceId, groupId, isMember } = this.props;
    const { isMenuShown } = this.state;

    const settingsMenuClassName = classNames('group-settings-menu', {
      hidden: !isMenuShown
    });

    return (
      <div className="group-settings-box" ref={this.wrapperRef}>
        <button
          type="button"
          className="group-settings-btn"
          onClick={this.toggleMenu}
        />
        <div className={settingsMenuClassName} onClick={this.onMenuCLick}>
          <ul className="group-settings-list">
            {isMember ? (
              <React.Fragment>
                <li>
                  <ManageGroup groupId={groupId} workspaceId={workspaceId} />
                </li>
                <li>
                  <LeaveGroup groupId={groupId} />
                </li>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li>
                  <JoinGroup groupId={groupId} />
                </li>
              </React.Fragment>
            )}
          </ul>
        </div>
      </div>
    );
  }

  private toggleMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const { isMenuShown } = this.state;

    this.setState({
      isMenuShown: !isMenuShown
    });
  };

  private handleClickOutside = (event: any) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        isMenuShown: false
      });
    }
  };

  private onMenuCLick = (e: any) => {
    e.stopPropagation();
  };
}

export { GroupSettings };
