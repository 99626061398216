import React from 'react';
import { Mutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../apollo/decorators';
import { addUserToGroupMutation } from '../../graphql';
import Log from '../../Log';
import { FeedApi } from '../../services';

interface Props {
  groupId: string;

  // gql:
  userId: string;
  workspaceId: string;
}

class JoinGroupComponent extends React.Component<Props & any> {
  public render() {
    return (
      <Mutation mutation={addUserToGroupMutation}>
        {(addUser: any, data: any) => (
          <button
            type="button"
            onClick={() => this.onAddUser(addUser)}
            disabled={data.loading}
          >
            Join team
          </button>
        )}
      </Mutation>
    );
  }

  private onAddUser = (addUser: any) => {
    const { groupId, workspaceId, userId } = this.props;

    addUser({
      variables: {
        groupId,
        workspaceId,
        userId
      }
    })
      .then(() => {
        FeedApi.fetchFeedByGroup(groupId, true);
      })
      .catch((err: any) => {
        Log.error(err, 'JoinGroup.onAddUser');
      });
  };
}

const JoinGroup = withWorkspaceAndUser(JoinGroupComponent);

export { JoinGroup };
