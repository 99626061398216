import Log from '../../../Log';

export default function fetchMoreUsersHelper(
  loadingUsers,
  fetchMoreUsers,
  usersPageInfo
) {
  if (loadingUsers || !usersPageInfo.hasNextPage || !usersPageInfo.endCursor) {
    return null;
  }

  return fetchMoreUsers({
    variables: {
      after: usersPageInfo.endCursor
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (
        !fetchMoreResult.users ||
        !fetchMoreResult.users.edges ||
        !fetchMoreResult.users.pageInfo
      ) {
        return prev;
      }

      return {
        users: {
          edges: [...prev.users.edges, ...fetchMoreResult.users.edges],
          pageInfo: {
            ...fetchMoreResult.users.pageInfo
          },
          __typename: prev.users.__typename
        }
      };
    }
  }).catch(err => {
    Log.error(`fetchMoreUsers: ${err}`);
  });
}
