import React from 'react';
import { getErrorText, PASSWORD_MAX_LENGTH } from '../../services';
import { ButtonPrimary, InputField } from '../UI';
// @ts-ignore
import styles from './login.module.scss';

interface Props {
  fields: {
    email: string;
    password: string;
  };
  errors: {
    email: string;
    password: string;
    onSubmit: string;
  };
  loading: boolean;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const LoginByInvitationView = ({
  fields,
  errors,
  loading,
  onChange,
  onSubmit
}: Props) => (
  <div className={styles.box}>
    <div className={styles.content}>
      <p className={styles.title}>Sign in to new workspace</p>
      <p className={styles.subTitle}>Enter your Buj account password</p>
      <form onSubmit={onSubmit} className={styles.form}>
        <fieldset disabled={loading}>
          <InputField
            type="password"
            placeholder="Password"
            name="password"
            value={fields.password}
            error={errors.password}
            onChange={onChange}
            maxLength={PASSWORD_MAX_LENGTH}
          />
          <div className="error cntr">{getErrorText(errors)}</div>
          <div className={styles.btnBox}>
            <ButtonPrimary type="submit">Sign in</ButtonPrimary>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
);

export { LoginByInvitationView };
