import React from 'react';
import { ErrorTypes, FieldTypes } from '../types';
// @ts-ignore
import styles from './innerInviteToWorkspace.module.scss';

interface Props {
  inputNames: string[];
  fields: FieldTypes;
  errors: ErrorTypes;
  errorText: string;
  loading: boolean;
  onChange(e: any, inputName: string): void;
  onSubmit(e: any): void;
}

function InnerInviteToWorkspaceView(props: Props) {
  const {
    inputNames,
    fields,
    errors,
    errorText,
    loading,
    onChange,
    onSubmit
  } = props;

  return (
    <form onSubmit={onSubmit}>
      <fieldset disabled={loading}>
        <div className={styles.modalContent}>
          <p className={styles.title}>Invite more people to this workspace</p>
          <hr />
          <div className={styles.descriptionRow}>
            <p>Email Address</p>
            <p>Name (optional)</p>
          </div>
          <div className={styles.fieldsList}>
            {inputNames.map((inputName: string) => (
              <div className={styles.fieldsRow} key={inputName}>
                <div className={styles.fieldBox}>
                  <input
                    type="text"
                    name="email"
                    value={fields[inputName].email}
                    placeholder="emailaddress@mail.com"
                    onChange={e => onChange(e, inputName)}
                    className={
                      // @ts-ignore
                      errors[inputName].errors.email ? styles.errorField : ''
                    }
                  />
                </div>
                <div className={styles.fieldBox}>
                  <input
                    type="text"
                    name="name"
                    value={fields[inputName].name}
                    placeholder="Name"
                    onChange={e => onChange(e, inputName)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="error cntr pb10">{errorText}</div>
        </div>
        <div className={styles.modalFooter}>
          <button type="submit" className={styles.btnInvite}>
            Send Invitations
          </button>
        </div>
      </fieldset>
    </form>
  );
}

export { InnerInviteToWorkspaceView };
