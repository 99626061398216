import React from 'react';
import PropTypes from 'prop-types';
import ItemView from '../../ItemView';
import GroupSettings from '../GroupSettings';
import '../../../../customBootstrap.scss';
import './GroupItem.scss';

function GroupItem(props) {
  const { group, onSelectGroup, isActive, workspaceId } = props;

  return (
    <div className="sidebar-group-item" onClick={onSelectGroup}>
      <ItemView
        item={group}
        isActive={isActive}
        groupSettings={
          <GroupSettings
            workspaceId={workspaceId}
            groupId={group.id}
            isMember={group.isMemberOfGroup}
          />
        }
      />
    </div>
  );
}

GroupItem.propTypes = {
  group: PropTypes.shape({}).isRequired,
  onSelectGroup: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  workspaceId: PropTypes.string.isRequired
};

export default GroupItem;
