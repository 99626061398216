import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { leaveFeedback } from '../../../graphql';
import Log from '../../../Log';
import './style.scss';

export default class Feedback extends React.Component {
  static propTypes = {
    currentWorkspaceId: PropTypes.string.isRequired
  };

  state = {
    isFormVisible: false,
    value: ''
  };

  changeFormVisibility = () => {
    const { isFormVisible } = this.state;

    this.setState({
      isFormVisible: !isFormVisible,
      value: ''
    });
  };

  onChangeField = e => {
    this.setState({
      value: e.target.value
    });
  };

  onSubmit = (e, leaveFeedbackFunc) => {
    e.preventDefault();

    const { value } = this.state;
    const { currentWorkspaceId } = this.props;

    if (!value.trim()) {
      return null;
    }

    leaveFeedbackFunc({
      variables: {
        feedback: value,
        workspaceId: currentWorkspaceId
      }
    })
      .then(() => {
        this.changeFormVisibility();
      })
      .catch(err => {
        Log.error(`leaveFeedback: ${err}`);
      });

    return null;
  };

  render() {
    const { isFormVisible, value } = this.state;

    return (
      <Mutation mutation={leaveFeedback}>
        {(leaveFeedbackFunc, data) => (
          <div className="feedback-wrapper">
            {isFormVisible ? (
              <div className="feedback-form">
                <button
                  type="button"
                  className="close-btn"
                  onClick={this.changeFormVisibility}
                />
                <div className="description">
                  If something could be better, tell us about it so that we can
                  fix it. Otherwise, we will waste our time and money in things
                  that do not work. Positive feedback is also welcome ;)
                </div>
                <form onSubmit={e => this.onSubmit(e, leaveFeedbackFunc)}>
                  <textarea
                    value={value}
                    onChange={this.onChangeField}
                    placeholder="Share your thoughts..."
                    disabled={data.loading}
                    maxLength={4000}
                  />
                  <div className="submit-box">
                    <button
                      type="submit"
                      className="send-btn"
                      disabled={data.loading}
                    >
                      Send Feedback
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <button
                type="button"
                className="show-feedback-btn"
                onClick={this.changeFormVisibility}
              >
                Feedback
              </button>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}
