import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import { pathOr } from 'ramda';
import React from 'react';
import { Mutation, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { doLoginMutation, registerUserMutation } from '../../graphql';
import Log from '../../Log';
import TokenStore, { Validator } from '../../services';
import { getTokenFromUrl } from '../helpers';
import { INVITATION_CODE_NAME, VERIFICATION_CODE_NAME } from './constants';
import { RegisterByInvitationView } from './RegisterByInvitationView';
import { RegisterByVerificationView } from './RegisterByVerificationView';

interface Props {
  history: any;
  changeLoggedState(isLogged: boolean): void;
}

interface ApolloProps {
  client: any;
}

interface State {
  fields: {
    name: string;
    login: string;
    password: string;
    confirmedPassword: string;
  };
  errors: {
    name: string;
    login: string;
    password: string;
    confirmedPassword: string;
    onSubmit: string;
  };
}

class RegisterComponent extends React.Component<Props & ApolloProps, State> {
  constructor(props: any) {
    super(props);

    const { name } = this.getTokenData();

    this.state = {
      fields: {
        name,
        login: '',
        password: '',
        confirmedPassword: ''
      },
      errors: {
        name: '',
        login: '',
        password: '',
        confirmedPassword: '',
        onSubmit: ''
      }
    };
  }

  public getTokens = () => {
    return {
      verificationToken: getTokenFromUrl(VERIFICATION_CODE_NAME),
      invitationToken: getTokenFromUrl(INVITATION_CODE_NAME)
    };
  };

  public getTokenData = () => {
    const { verificationToken, invitationToken } = this.getTokens();

    const token = verificationToken || invitationToken;

    if (!token) {
      return {
        email: '',
        name: ''
      };
    }

    const decodedToken = jwtDecode(token, { header: false });

    return {
      // @ts-ignore
      email: decodedToken.email,
      // @ts-ignore
      name: decodedToken.name || ''
    };
  };

  public onChange = (e: any) => {
    const { fields } = this.state;
    const { name } = e.target;
    let { value } = e.target;

    if (name !== 'name') {
      value = value.trim();
    }

    this.setState({
      fields: {
        ...fields,
        [name]: value
      },
      errors: {
        name: '',
        login: '',
        password: '',
        confirmedPassword: '',
        onSubmit: ''
      }
    });
  };

  public validate = () => {
    const { fields, errors: prevErrors } = this.state;

    const { errors, isValid } = Validator.validate({
      ...fields,
      name: fields.name.trim()
    });

    this.setState({
      errors: {
        ...prevErrors,
        ...errors
      }
    });

    return isValid;
  };

  public onSubmit = (e: any, registerUser: any) => {
    e.preventDefault();

    if (!this.validate()) {
      return null;
    }

    const { verificationToken, invitationToken } = this.getTokens();
    const { fields, errors } = this.state;

    const { email } = this.getTokenData();

    return registerUser({
      variables: {
        email,
        password: fields.password,
        login: fields.login,
        name: fields.name.trim(),
        locale: 'en-US',
        zoneinfo: moment.tz.guess(),
        birthdate: '1980-01-01',
        verificationToken,
        invitationToken
      }
    })
      .then((res: any) => {
        const { error } = res.data.register;

        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: validationErrors[0].message
            }
          });
        }

        if (error) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: error.errorMessage
            }
          });
        }

        this.doLogin();
      })
      .catch((err: any) => {
        this.setState({
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });

        Log.error(`Error while registering user: ${err}`);
      });
  };

  public doLogin = () => {
    const { client, changeLoggedState } = this.props;
    const { fields, errors } = this.state;
    const { verificationToken, invitationToken } = this.getTokens();
    const { email } = this.getTokenData();

    client
      .mutate({
        mutation: doLoginMutation,
        variables: {
          email,
          password: fields.password
        }
      })
      .then((res: any) => {
        const { identityToken, refreshToken, error } = res.data.login;

        if (error) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: 'Error'
            }
          });
        }

        TokenStore.storeIdentityToken(identityToken);
        TokenStore.storeRefreshToken(refreshToken);

        if (verificationToken) {
          changeLoggedState(true);
          window.location.assign('/create-workspace');
        }

        if (invitationToken) {
          changeLoggedState(true);
        }
      })
      .catch((err: any) => {
        Log.error(err, 'Login after registration');

        this.setState({
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });
      });
  };

  public render() {
    const { verificationToken, invitationToken } = this.getTokens();

    const { history } = this.props;
    const { fields, errors } = this.state;

    if (!verificationToken && !invitationToken) {
      history.push('/verification');

      return null;
    }

    return (
      <React.Fragment>
        <Mutation mutation={registerUserMutation}>
          {(registerUser: any, data: any) => (
            <React.Fragment>
              {verificationToken && (
                <RegisterByVerificationView
                  fields={fields}
                  errors={errors}
                  loading={data.loading}
                  onChange={this.onChange}
                  onSubmit={e => this.onSubmit(e, registerUser)}
                />
              )}

              {invitationToken && (
                <RegisterByInvitationView
                  fields={fields}
                  errors={errors}
                  loading={data.loading}
                  onChange={this.onChange}
                  onSubmit={e => this.onSubmit(e, registerUser)}
                />
              )}
            </React.Fragment>
          )}
        </Mutation>
      </React.Fragment>
    );
  }
}

const Register = withRouter(withApollo<any, ApolloProps>(RegisterComponent));

export { Register };
