import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import { withQuery, compose } from 'react-apollo';

import './CardsFeed.scss';
import IndexCard from './IndexCard';
import { Loader } from '../UI';
import { SinglePost } from '../SinglePost';
import ServiceConnectors from './ServiceConnectors';
import NewPostNotification from './NewPostNotification/index';
import { getLoadingState, getPostView } from '../../graphql/local';
import { FeedApi } from '../../services/FeedApi';

class CardsFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalId: 0
    };

    this.feedWrapper = React.createRef();
  }

  componentDidMount() {
    FeedApi.subscribe('SINGLE_POST_SELECTED', 'CARDS_FEED', data => {
      this.setState({ selectedPost: data });
    });
    this.feedWrapper.current.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    FeedApi.unsubscribe('SINGLE_POST_SELECTED', 'CARDS_FEED');
    this.feedWrapper.current.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { isNewPostAppeared, hideNewPostNotification } = this.props;

    if (
      isNewPostAppeared &&
      this.feedWrapper.current &&
      this.feedWrapper.current.scrollTop < 50
    ) {
      hideNewPostNotification();
    }
  };

  scrollStep = () => {
    const { intervalId } = this.state;

    if (this.feedWrapper.current.scrollTop === 0) {
      clearInterval(intervalId);
    }

    this.feedWrapper.current.scroll(
      0,
      this.feedWrapper.current.scrollTop - 100
    );
  };

  scrollToTop = () => {
    const { hideNewPostNotification } = this.props;

    hideNewPostNotification();

    const intervalId = setInterval(this.scrollStep.bind(this), 20);
    this.setState({ intervalId });
  };

  onFetchMoreFeed = () => {
    const { loadingFeed, feedList, fetchMoreFeed } = this.props;

    if (loadingFeed) {
      return null;
    }

    if (feedList && feedList.length === 0) {
      return null;
    }

    return fetchMoreFeed();
  };

  render() {
    const {
      feedList,
      loadingFeed,
      loadAllFeed,
      isMainFeedLoaderActive,
      fetchMoreThreads,
      currentWorkspaceId,
      isNewPostAppeared,
      hideNewPostNotification,
      showErrorOnSubscription,
      subscribeToPost,
      isCardExpanded
    } = this.props;
    const { selectedPost } = this.state;

    if (isMainFeedLoaderActive) {
      return (
        <div className="feed-wrapper">
          <div className="feed-content">
            <div className="loader">
              <Loader />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="feed-wrapper" ref={this.feedWrapper}>
        {selectedPost ? (
          <SinglePost selectedPost={selectedPost} />
        ) : (
          <div className="feed-content">
            {isNewPostAppeared && (
              <NewPostNotification
                isNewPostAppeared={isNewPostAppeared}
                hideNewPostNotification={hideNewPostNotification}
                scrollToTop={this.scrollToTop}
                feedWrapper={this.feedWrapper.current}
              />
            )}

            {feedList &&
              feedList.length > 0 &&
              feedList.map(item => {
                if (!item || !item.node) {
                  return null;
                }

                return (
                  <IndexCard
                    item={item.node}
                    key={item.node.id}
                    isCardsViewExpanded={isCardExpanded}
                    fetchMoreThreads={fetchMoreThreads}
                    currentWorkspaceId={currentWorkspaceId}
                    showErrorOnSubscription={showErrorOnSubscription}
                    subscribeToPost={subscribeToPost}
                  />
                );
              })}

            {!loadingFeed && loadAllFeed && (
              <ServiceConnectors currentWorkspaceId={currentWorkspaceId} />
            )}

            {loadingFeed && (
              <div className="loader">
                <Loader />
              </div>
            )}

            <Waypoint onEnter={this.onFetchMoreFeed} />
          </div>
        )}
      </div>
    );
  }
}

CardsFeed.propTypes = {
  feedList: PropTypes.arrayOf(PropTypes.shape({})),
  loadingFeed: PropTypes.bool,
  fetchMoreFeed: PropTypes.func.isRequired,
  loadAllFeed: PropTypes.bool,
  fetchMoreThreads: PropTypes.func.isRequired,
  currentWorkspaceId: PropTypes.string.isRequired,
  isNewPostAppeared: PropTypes.bool.isRequired,
  hideNewPostNotification: PropTypes.func.isRequired,
  showErrorOnSubscription: PropTypes.func.isRequired,
  subscribeToPost: PropTypes.func.isRequired
};

CardsFeed.defaultProps = {
  feedList: [],
  loadingFeed: true,
  loadAllFeed: false
};

export default compose(
  withQuery(getLoadingState, {
    props: props => ({
      isMainFeedLoaderActive: props.data.isMainFeedLoaderActive
    })
  }),
  withQuery(getPostView, {
    props: props => ({
      isCardExpanded: props.data.cardsView.isCardsViewExpanded
    })
  })
)(CardsFeed);
