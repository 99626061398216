import React from 'react';
// @ts-ignore
import styles from './uploadedFiles.module.scss';

interface Props {
  uploadedFiles: any;
  onRemoveFile(fileName: string, fileType?: string): void;
}

const UploadedFilesList = ({ uploadedFiles, onRemoveFile }: Props) => (
  <div className={styles.fileBox}>
    {uploadedFiles.map((file: any, index: any) => (
      <div key={index} className={styles.fileItem}>
        <span className={styles.fileName}>{file.name}</span>
        <button
          type="button"
          className={styles.removeFileBtn}
          onClick={() => onRemoveFile(file.name)}
        />
      </div>
    ))}
  </div>
);

export { UploadedFilesList };
