/* eslint-disable import/prefer-default-export */ // Temporarily while there's only a single query
import gql from 'graphql-tag';

export const createWorkspaceMutation = gql`
  mutation CreateWorkspace($name: String!, $slug: String!) {
    createWorkspace(createWorkspaceRequest: { name: $name, slug: $slug }) {
      workspace {
        id
        name
        slug
        uri
      }
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const getWorkspace = gql`
  query Workspace($slug: String!) {
    workspace(slug: $slug) {
      id
      name
    }
  }
`;

export const getAllWorkspaces = gql`
  query {
    workspaces {
      id
      name
      slug
      uri
    }
  }
`;

export const inviteUsersToWorkspaceMutation = gql`
  mutation InviteUsers(
    $workspaceId: UUID!
    $userInvitations: [UserInvitationInput!]!
  ) {
    inviteUsers(
      inviteUsersRequest: {
        workspaceId: $workspaceId
        userInvitations: $userInvitations
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const claimInvitationTokenMutation = gql`
  mutation ClaimInvitationToken($token: String!) {
    claimInvitationToken(claimInvitationTokenRequest: { token: $token }) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
