const EMPTY_FIELD = 'EMPTY_FIELD';
const SHORT_FIELD = 'SHORT_FIELD';
const LONG_FIELD = 'LONG_FIELD';
const INCORRECT_FIELD = 'INCORRECT_FIELD';
const NOT_EQUAL = 'NOT_EQUAL';

const USER_NAME_MIN_LENGTH = 2;
const USER_NAME_MAX_LENGTH = 255;

const USER_NICKNAME_MIN_LENGTH = 4;
const USER_NICKNAME_MAX_LENGTH = 64;

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 64;

const WORKSPACE_NAME_MIX_LENGTH = 6;
const WORKSPACE_NAME_MAX_LENGTH = 64;

const WORKSPACE_SLUG_MIX_LENGTH = 6;
const WORKSPACE_SLUG_MAX_LENGTH = 32;

export const POST_TITLE_MAX_LENGTH = 150;
export const POST_DESCRIPTION_MAX_LENGTH = 4000;

export {
  EMPTY_FIELD,
  INCORRECT_FIELD,
  SHORT_FIELD,
  LONG_FIELD,
  NOT_EQUAL,
  USER_NAME_MIN_LENGTH,
  USER_NAME_MAX_LENGTH,
  USER_NICKNAME_MIN_LENGTH,
  USER_NICKNAME_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  WORKSPACE_NAME_MIX_LENGTH,
  WORKSPACE_NAME_MAX_LENGTH,
  WORKSPACE_SLUG_MIX_LENGTH,
  WORKSPACE_SLUG_MAX_LENGTH
};
