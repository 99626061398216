import React from 'react';
import PropTypes from 'prop-types';
import userInThreadHeader from '../../../helpers/userInThreadHeader';

function ThreadNavItem(props, context) {
  const { thread } = props;
  const { currentUserId } = context;
  const { group } = thread && thread.node;

  if (!group) {
    return null;
  }

  let avatarSrc = group.avatar;
  let threadName = group.name;

  if (group.isInstantMessage) {
    const theadUser = userInThreadHeader(group, currentUserId);

    avatarSrc = theadUser && theadUser.avatar;
    threadName = theadUser && theadUser.name;
  }

  return (
    <React.Fragment>
      {group && (
        <span className="thread-nav-item" title={threadName}>
          <img alt="" src={avatarSrc} />
          <span className="thread-group-name-wrap">
            <span className="thread-group-name">{threadName}</span>
          </span>
        </span>
      )}
    </React.Fragment>
  );
}

ThreadNavItem.propTypes = {
  thread: PropTypes.shape({
    node: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired
};

ThreadNavItem.contextTypes = {
  currentUserId: PropTypes.string
};

export default ThreadNavItem;
