import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = props => {
  const {
    selectedDate,
    onChangeDatePicker,
    dateFormat,
    placeholderText,
    maxDate,
    showYearDropdown,
    scrollableYearDropdown,
    disabled
  } = props;

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={onChangeDatePicker}
      dateFormat={dateFormat}
      placeholderText={placeholderText}
      maxDate={maxDate}
      showYearDropdown={showYearDropdown}
      scrollableYearDropdown={scrollableYearDropdown}
      disabled={disabled}
      yearDropdownItemNumber={60}
    />
  );
};

DatePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  onChangeDatePicker: PropTypes.func,
  dateFormat: PropTypes.string,
  placeholderText: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  showYearDropdown: PropTypes.bool,
  scrollableYearDropdown: PropTypes.bool,
  disabled: PropTypes.bool
};

DatePicker.defaultProps = {
  selectedDate: new Date(),
  onChangeDatePicker: () => {},
  dateFormat: 'MM/dd/yyyy',
  placeholderText: 'Select date',
  maxDate: new Date(),
  showYearDropdown: false,
  scrollableYearDropdown: false,
  disabled: false
};

export default DatePicker;
