import Log from '../../../Log';
import { arrayUniqueByNodeId } from '../arrayUnique';

export default function fetchMoreGroupsHelper(
  loadingGroups,
  fetchMoreGroups,
  groupsPageInfo,
  variables,
  onSuccess
) {
  if (
    loadingGroups ||
    !groupsPageInfo.hasNextPage ||
    !groupsPageInfo.endCursor
  ) {
    return null;
  }

  return fetchMoreGroups({
    variables: {
      ...variables,
      after: groupsPageInfo.endCursor
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (
        !fetchMoreResult.groups ||
        !fetchMoreResult.groups.edges ||
        !fetchMoreResult.groups.pageInfo
      ) {
        return prev;
      }

      if (onSuccess) {
        return onSuccess(fetchMoreResult, prev);
      }

      return {
        groups: {
          edges: arrayUniqueByNodeId(
            prev.groups.edges.concat(fetchMoreResult.groups.edges)
          ),
          pageInfo: {
            ...fetchMoreResult.groups.pageInfo
          },
          __typename: prev.groups.__typename
        }
      };
    }
  }).catch(err => {
    Log.error(`fetchMoreGroups: ${err}`);
  });
}
