import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

const ItemView = props => {
  const { item, isAllFeed, isActive, groupSettings } = props;

  const itemClassName = classNames('work-space', { active: isActive });

  return (
    <div className={itemClassName} data-actor-id={item.id}>
      {isAllFeed ? (
        <React.Fragment>
          <div className="work-spaces-all-icon">All</div>
          <div className="work-space-btn-info">
            <div className="card-info">
              <div className="work-space-btn-name">{item.name}</div>
              {/* {item.creator && */}
              {/* <div className="work-space-btn-creator">{item.creator}</div> */}
              {/* } */}
              {/* <div className="work-space-btn-title">{item.title}</div> */}
            </div>
            {/* <div className="additional-info"> */}
            {/* <div className="time">{item.time}</div> */}
            {/* {!!item.notifications && */}
            {/* <div className="notifications-box"> */}
            {/* <span className="amount">{item.notifications}</span> */}
            {/* </div> */}
            {/* } */}
            {/* </div> */}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <img alt="" src={item.avatar} className="work-space-logo" />
          <div className="work-space-btn-info">
            <div className="card-info">
              <div className="work-space-btn-name">{item.name}</div>
            </div>
          </div>
          {!!groupSettings && groupSettings}
        </React.Fragment>
      )}
    </div>
  );
};

ItemView.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }),
  isAllFeed: PropTypes.bool,
  isActive: PropTypes.bool,
  groupSettings: PropTypes.element
};

ItemView.defaultProps = {
  item: {
    avatar: null
  },
  isAllFeed: false,
  isActive: false,
  groupSettings: null
};

export default ItemView;
