import React from 'react';
import Autosuggest from 'react-autosuggest';
// @ts-ignore
import styles from './postIn.module.scss';

function SuggestionList(props: any) {
  const {
    ref,
    addTag,
    suggestions,
    fetchContacts,
    clearSuggestion,
    ...inputProps
  } = props;

  const handleOnChange = (e: any, { newValue, method }: any) => {
    if (method === 'enter') {
      e.preventDefault();
    } else {
      props.onChange(e);
    }
  };

  const renderSuggestion = (suggestion: any) => {
    return (
      <span className={styles.suggestionItem}>
        <img
          src={suggestion.node.avatar}
          alt=""
          className={styles.suggestionAvatar}
        />
        <span className={styles.suggestionName}>{suggestion.node.name}</span>
      </span>
    );
  };

  const storeInputReference = (autosuggest: any) => {
    if (autosuggest !== null) {
      ref(autosuggest.input);
    }
  };

  return (
    <Autosuggest
      ref={storeInputReference}
      suggestions={suggestions}
      // @ts-ignore
      shouldRenderSuggestions={value => value && value.length > 0}
      getSuggestionValue={suggestion => suggestion.id}
      renderSuggestion={renderSuggestion}
      inputProps={{ ...inputProps, onChange: handleOnChange }}
      onSuggestionSelected={(e, { suggestion }) => {
        addTag(suggestion);
      }}
      onSuggestionsClearRequested={clearSuggestion}
      onSuggestionsFetchRequested={value => {
        fetchContacts(value.value);
      }}
      highlightFirstSuggestion={true}
    />
  );
}

export default SuggestionList;
