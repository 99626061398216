import React from 'react';
import { compose, graphql } from 'react-apollo';
// @ts-ignore
import { throttle } from 'throttle-debounce';
import { getGroupsListQuery, getUsersListQuery } from '../../../../graphql';
import Log from '../../../../Log';
import fetchMoreGroupsHelper from '../../../helpers/fetchMore/fetchMoreGroupsHelper';
import fetchMoreUsersHelper from '../../../helpers/fetchMore/fetchMoreUsersHelper';
import { SelectedContactItem } from '../../CreatePost.types';
import { DropdownContactsView } from './DropdownContactsView';

interface Props {
  currentWorkspaceId: string;
  selectedContacts: SelectedContactItem[];
  contactsException?: string[];
  groupsData: any;
  usersData: any;
  onToggleContact(e: any, contact: any): void;
}

interface State {
  searchedUsers: any;
  searchedGroups: any;
  value: string;
}

class DropdownContacts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchedUsers: [],
      searchedGroups: [],
      value: ''
    };

    this.searchContacts = throttle(500, this.searchContacts);
  }

  public onInputChange = (e: any) => {
    const { value } = e.target;

    this.setState({
      value
    });

    this.searchContacts(value);
  };

  public searchContacts = (value: string) => {
    const { groupsData, usersData } = this.props;

    if (!value) {
      this.setState({
        searchedUsers: [],
        searchedGroups: []
      });

      return null;
    }

    usersData
      .fetchMore({
        variables: {
          userFilter: {
            nameFilter: {
              searchQuery: value
            }
          }
        },
        updateQuery: (
          prev: any,
          { fetchMoreResult }: { fetchMoreResult: any }
        ) => {
          this.setState({
            searchedUsers: fetchMoreResult.users.edges
          });
        }
      })
      .catch((err: any) => {
        Log.error(`Error fetching for users, ${err}`);
      });

    groupsData
      .fetchMore({
        variables: {
          groupNameFilter: {
            name: value
          }
        },
        updateQuery: (
          prev: any,
          { fetchMoreResult }: { fetchMoreResult: any }
        ) => {
          this.setState({
            searchedGroups: fetchMoreResult.groups.edges
          });
        }
      })
      .catch((err: any) => {
        Log.error(`Error fetching for groups, ${err}`);
      });
  };

  public fetchMoreUsers = () => {
    const { usersData } = this.props;

    fetchMoreUsersHelper(
      usersData.loading,
      usersData.fetchMore,
      usersData.users.pageInfo
    );
  };

  public fetchMoreGroups = () => {
    const { groupsData } = this.props;

    fetchMoreGroupsHelper(
      groupsData.loading,
      groupsData.fetchMore,
      groupsData.groups.pageInfo
    );
  };

  public isContactSelected = (id: any) => {
    const { selectedContacts } = this.props;

    return selectedContacts.some((contact: any) => contact.node.id === id);
  };

  public render() {
    const { value, searchedUsers, searchedGroups } = this.state;
    const {
      usersData,
      groupsData,
      onToggleContact,
      contactsException = []
    } = this.props;

    const userEdges = (usersData.users && usersData.users.edges) || [];
    const groupEdges = (groupsData.groups && groupsData.groups.edges) || [];

    const usersList = value.trim() ? searchedUsers : userEdges;
    const groupsList = value.trim() ? searchedGroups : groupEdges;

    let showedUsersList = usersList;
    let showedGroupsList = groupsList;

    if (contactsException.length > 0) {
      showedUsersList = usersList.filter(
        (user: any) =>
          user.node &&
          user.node.id &&
          contactsException.every((id: string) => id !== user.node.id)
      );
      showedGroupsList = groupsList.filter(
        (group: any) =>
          group.node &&
          group.node.id &&
          contactsException.every((id: string) => id !== group.node.id)
      );
    }

    const hasUsersNextPage =
      (usersData.users &&
        usersData.users.pageInfo &&
        usersData.users.pageInfo.hasNextPage) ||
      false;
    const hasGroupsNextPage =
      (groupsData.groups && groupsData.groups.pageInfo.hasNextPage) || false;

    return (
      <DropdownContactsView
        value={value}
        onInputChange={this.onInputChange}
        usersList={showedUsersList}
        groupsList={showedGroupsList}
        loading={usersData.loading || groupsData.loading}
        error={usersData.error || groupsData.error}
        isContactSelected={this.isContactSelected}
        onToggleContact={onToggleContact}
        hasUsersNextPage={hasUsersNextPage}
        hasGroupsNextPage={hasGroupsNextPage}
        fetchMoreUsers={this.fetchMoreUsers}
        fetchMoreGroups={this.fetchMoreGroups}
      />
    );
  }
}

export { DropdownContacts };
