import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { getFeedQuery } from '../../graphql';
import {
  groupToQuery,
  userToQuery,
  ratingToQuery,
  textToQuery
} from './feedQueryHelpers';
import { getFilters } from '../../services';
import logoutFunc from '../helpers/logoutFunc/logoutFunc';
import MainPageContent from './MainPageContent';
import Log from '../../Log';

class MainPageWithFeedQuery extends React.Component {
  static childContextTypes = {
    currentUserId: PropTypes.string,
    currentWorkspaceId: PropTypes.string,
    currentUserData: PropTypes.shape({
      id: PropTypes.string,
      avatar: PropTypes.string
    })
  };

  getChildContext() {
    const { currentWorkspaceId, currentUser } = this.props;

    return {
      currentWorkspaceId,
      currentUserId: currentUser.id,
      currentUserData: currentUser
    };
  }

  render() {
    const { currentWorkspaceId, currentUserId } = this.props;

    const filterArr = getFilters();
    const {
      postFilterState,
      filterByGroupState,
      filterByUserState,
      filterByRatingState,
      filterByTextState
    } = filterArr;

    return (
      <Query
        query={getFeedQuery}
        variables={{
          workspaceId: currentWorkspaceId,
          postFilterType: postFilterState,
          groupFilter: groupToQuery(filterByGroupState),
          actorFilter: userToQuery(filterByUserState),
          ratingFilter: ratingToQuery(filterByRatingState),
          textSearchFilter: textToQuery(filterByTextState)
        }}
        notifyOnNetworkStatusChange
        fetchPolicy="cache-and-network"
      >
        {({
          loading: loadingFeed,
          error: errorFeed,
          data: feedData,
          fetchMore: fetchFeed,
          refetch: refetchFeed,
          subscribeToMore,
          updateQuery,
          client
        }) => {
          const feedList =
            (feedData && feedData.posts && feedData.posts.edges) || [];
          const postsPageInfo =
            (feedData && feedData.posts && feedData.posts.pageInfo) || {};

          if (errorFeed) {
            Log.graphQLError(errorFeed, 'MainPage');
            return (
              <div>
                Error...
                <br />
                {currentUserId && <div>Your ID: {currentUserId}</div>}
                <button type="button" onClick={logoutFunc}>
                  Logout
                </button>
              </div>
            );
          }

          return (
            <React.Fragment>
              <MainPageContent
                feedList={feedList}
                loadingFeed={loadingFeed}
                fetchFeed={fetchFeed}
                refetchFeed={refetchFeed}
                subscribeToMore={subscribeToMore}
                updateQueryFeed={updateQuery}
                clientQuery={client.query}
                postsPageInfo={postsPageInfo}
                currentWorkspaceId={currentWorkspaceId}
              />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

MainPageWithFeedQuery.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.string
  }).isRequired,
  currentWorkspaceId: PropTypes.string.isRequired
};

export default MainPageWithFeedQuery;
