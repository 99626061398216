import React from 'react';
import { Link } from 'react-router-dom';
import { getErrorText } from '../../services';
import { ButtonPrimary, InputField } from '../UI';
// @ts-ignore
import styles from './verifyUser.module.scss';

interface Props {
  fields: {
    email: string;
  };
  errors: {
    email: string;
    onSubmit: string;
  };
  loading: boolean;
  isEmailSent: boolean;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const VerifyUserView = ({
  fields,
  errors,
  loading,
  isEmailSent,
  onChange,
  onSubmit
}: Props) => (
  <React.Fragment>
    <div className={styles.box}>
      {isEmailSent ? (
        <div className={styles.sendingInfoBox}>
          <div className={styles.title}>Email has been sent</div>
          <p className={styles.subTitle}>
            Check your email! Remember to try your spam folder.
          </p>
        </div>
      ) : (
        <div className={styles.content}>
          <p className={styles.title}>Create a new workspace</p>
          <form onSubmit={onSubmit} className={styles.form}>
            <fieldset disabled={loading}>
              <InputField
                name="email"
                value={fields.email}
                placeholder="emailaddress@mail.com"
                onChange={onChange}
                error={errors.email}
              />

              <div className="error cntr">{getErrorText(errors)}</div>

              <div className={styles.btnBox}>
                <ButtonPrimary type="submit">Get started</ButtonPrimary>
              </div>
            </fieldset>
          </form>
          <p className={styles.description}>
            Check your email! Remember to try your spam folder.
          </p>
        </div>
      )}
    </div>

    <div className={styles.loginLinkBox}>
      Already using Buj? <Link to="/login">Sign in</Link>
    </div>
  </React.Fragment>
);

export { VerifyUserView };
