import { pathOr } from 'ramda';
import React from 'react';
import TagsInput from 'react-tagsinput';
import Log from '../../../../Log';
import { SelectedContactItem } from '../../CreatePost.types';
import { ContactItem } from './ContactItem';
// @ts-ignore
import styles from './postIn.module.scss';
import SuggestionList from './SuggestionList';

interface Props {
  selectedContacts: SelectedContactItem[];
  usersData: any;
  groupsData: any;
  contactsException?: string[];
  onAddContact(val: any): void;
}

interface State {
  searchedUsers: any;
  searchedGroups: any;
}

class Contacts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchedUsers: [],
      searchedGroups: []
    };
  }

  public fetchContacts = (value: string) => {
    this.fetchMoreUsers(value);
    this.fetchMoreGroups(value);
  };

  public fetchMoreUsers = (value: string) => {
    const { usersData } = this.props;

    if (value.trim().length === 0) {
      return null;
    }

    usersData
      .fetchMore({
        variables: {
          userFilter: {
            nameFilter: {
              searchQuery: value
            }
          }
        },
        updateQuery: (
          prev: any,
          { fetchMoreResult }: { fetchMoreResult: any }
        ) => {
          this.setState({
            searchedUsers: fetchMoreResult.users.edges
          });
        }
      })
      .catch((err: any) => {
        Log.error(`Error fetching for users, ${err}`);
      });
  };

  public fetchMoreGroups = (value: string) => {
    const { groupsData } = this.props;

    if (value.trim().length === 0) {
      return null;
    }

    groupsData
      .fetchMore({
        variables: {
          groupNameFilter: {
            name: value
          }
        },
        updateQuery: (
          prev: any,
          { fetchMoreResult }: { fetchMoreResult: any }
        ) => {
          this.setState({
            searchedGroups: fetchMoreResult.groups.edges
          });
        }
      })
      .catch((err: any) => {
        Log.error(`Error fetching for groups, ${err}`);
      });
  };

  public clearSuggestion = () => {
    this.setState({
      searchedUsers: [],
      searchedGroups: []
    });
  };

  public render() {
    const { searchedUsers, searchedGroups } = this.state;
    const {
      selectedContacts,
      contactsException = [],
      onAddContact
    } = this.props;

    const concatSuggestions = [...searchedUsers, ...searchedGroups];

    const suggestions = concatSuggestions.filter((suggestionItem: any) => {
      return !(
        selectedContacts.some(
          (selectedItem: any) =>
            pathOr(null, ['node', 'id'], selectedItem) ===
            pathOr(null, ['node', 'id'], suggestionItem)
        ) ||
        contactsException.some(
          (contactId: any) =>
            contactId === pathOr(null, ['node', 'id'], suggestionItem)
        )
      );
    });

    return (
      <div className={styles.contactsWrapper} title="No more than 10">
        <TagsInput
          className={styles.tagsWrapper}
          value={selectedContacts}
          onChange={onAddContact}
          inputProps={{
            suggestions,
            fetchContacts: this.fetchContacts,
            clearSuggestion: this.clearSuggestion,
            placeholder: '',
            className: styles.tagsInput,
            disabled: selectedContacts.length === 10
          }}
          renderTag={ContactItem}
          renderInput={SuggestionList}
        />
      </div>
    );
  }
}

export { Contacts };
