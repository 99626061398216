import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab, Nav, NavItem } from 'react-bootstrap';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';
import { Comments } from '../../Comments';
import ThreadNavItem from './ThreadNavItem';
import './styles.scss';

// eslint-disable-next-line react/prefer-stateless-function
class Threads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      needAddClass: false
    };

    this.tabNavRef = React.createRef();
    this.tabNavHeaderRef = React.createRef();
  }

  // componentDidMount() {
  //   this.addClass();
  // }

  componentDidUpdate() {
    this.addClass();
  }

  addClass = () => {
    if (
      !this.tabNavRef ||
      !this.tabNavRef.current ||
      !this.tabNavHeaderRef ||
      !this.tabNavHeaderRef.current
    ) {
      return null;
    }

    const { needAddClass } = this.state;
    const navWidth = this.tabNavRef.current.offsetWidth;
    const navHeaderWidth = this.tabNavHeaderRef.current.offsetWidth - 50;

    if (!needAddClass && navWidth > navHeaderWidth) {
      this.setState({
        needAddClass: true
      });
    }

    return null;
  };

  loadMoreThreads = () => {
    const { item, fetchMoreThreads } = this.props;

    const postId = item && item.id;
    const after =
      item &&
      item.commentThreads &&
      item.commentThreads.pageInfo &&
      item.commentThreads.pageInfo.endCursor;

    if (!postId || !after) {
      return null;
    }

    fetchMoreThreads(postId, after);

    return null;
  };

  render() {
    const { needAddClass } = this.state;
    const {
      item,
      toggleShareModal,
      showErrorOnSubscription,
      selectedTabId
    } = this.props;

    const defaultActiveKey =
      selectedTabId ||
      (item.commentThreads.edges &&
        item.commentThreads.edges[0] &&
        item.commentThreads.edges[0].node &&
        item.commentThreads.edges[0].node.id);

    if (!defaultActiveKey) {
      return null;
    }

    const threadsArray = item.commentThreads.edges;
    const threadsPageInfo = item.commentThreads.pageInfo;

    const tabNavClassName = classNames('thread-tab-nav', {
      wide: needAddClass
    });

    return (
      <Tab.Container id={`tabs${item.id}`} defaultActiveKey={defaultActiveKey}>
        <React.Fragment>
          <div className="thread-tab-header" ref={this.tabNavHeaderRef}>
            <div className="thread-tab-nav-wrap" ref={this.tabNavRef}>
              <Nav className={tabNavClassName}>
                {threadsArray.map(thread => {
                  if (!(thread.node && thread.node.id)) {
                    return null;
                  }

                  return (
                    <NavItem
                      key={thread.node.id}
                      eventKey={thread.node.id}
                      data-thread-id={thread.node.id}
                    >
                      <ThreadNavItem thread={thread} />
                    </NavItem>
                  );
                })}
              </Nav>
            </div>

            {threadsPageInfo.hasNextPage && (
              <Waypoint onEnter={this.loadMoreThreads} horizontal />
            )}
            {!item.sharedBy && (
              <div className="share-post-btn-box">
                <button
                  type="button"
                  className="share-post-btn"
                  onClick={toggleShareModal}
                />
              </div>
            )}
          </div>

          <Tab.Content animation={false} className="thread-tab-content">
            {threadsArray.map(thread => {
              if (!(thread.node && thread.node.id)) {
                return null;
              }

              return (
                <Tab.Pane
                  key={`tp-${thread.node.id}`}
                  eventKey={thread.node.id}
                >
                  <Comments
                    threadId={thread.node.id}
                    postId={item.id}
                    groupId={thread.node.group.id}
                    showErrorOnSubscription={showErrorOnSubscription}
                  />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </React.Fragment>
      </Tab.Container>
    );
  }
}

Threads.propTypes = {
  // eslint-disable-next-line
  item: PropTypes.object.isRequired,
  toggleShareModal: PropTypes.func.isRequired,
  fetchMoreThreads: PropTypes.func.isRequired,
  showErrorOnSubscription: PropTypes.func.isRequired
};

export default Threads;
