import gql from 'graphql-tag';

export const getCurrentUser = gql`
  query Me($workspaceId: UUID!) {
    currentUser(workspaceId: $workspaceId) {
      name
      id
      locale
      zoneinfo
      login
      avatar(size: 30)
      __typename
      workspaces {
        __typename
        id
        name
        slug
      }
    }
  }
`;

// export const getCurrentUserOptions = {
//   props: ({ data }) => {
//     const { currentUser } = data;
//     return { currentUser };
//   }
// };

export const verifyUserMutation = gql`
  mutation VerifyUser($email: String!) {
    verifyUser(verifyUserRequest: { email: $email }) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const doLoginMutation = gql`
  mutation loginMutation($email: String!, $password: String!) {
    login(loginUserRequest: { email: $email, password: $password }) {
      refreshToken
      identityToken
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

// export const doLoginOptions = ({
//     props: ({ data : { login }}) => ({
//         login
//     })
// });

export const doRefreshTokenMutation = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshTokenRequest: { refreshToken: $refreshToken }) {
      __typename
      accessToken
      identityToken
      error {
        __typename
        errorCode
        errorMessage
      }
    }
  }
`;

export const getUsersListQuery = gql`
  query Users(
    $workspaceId: UUID!
    $after: String
    $userFilter: UserFilterInput
  ) {
    users(
      first: 10
      after: $after
      workspaceId: $workspaceId
      userFilter: $userFilter
      sortOrder: ASC
    ) {
      __typename
      edges {
        node {
          id
          name
          avatar(size: 30)
        }
      }

      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const logoutMutation = gql`
  mutation Logout {
    logout {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const registerUserMutation = gql`
  mutation Register(
    $email: String!
    $password: String!
    $login: String!
    $name: String!
    $locale: String!
    $zoneinfo: String!
    $birthdate: String!
    $verificationToken: String
    $invitationToken: String
  ) {
    register(
      registerUserRequest: {
        email: $email
        password: $password
        login: $login
        name: $name
        locale: $locale
        zoneinfo: $zoneinfo
        profile: "profile"
        birthdate: $birthdate
        verificationToken: $verificationToken
        invitationToken: $invitationToken
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const changeUserAvatar = gql`
  mutation ChangeAvatar($userId: UUID!, $fileId: UUID!, $workspaceId: UUID!) {
    changeUserAvatar(
      changeUserAvatarRequest: {
        userId: $userId
        fileId: $fileId
        workspaceId: $workspaceId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
