import React from 'react';
import TextStylingInfo from '../../../TextStylingInfo';
import { Loader } from '../../../UI';
import Editor from '../../../UI/Editor/index';
import { SendArrow } from '../../../UI/Icons';
import { COMMENT_MAX_LENGTH, LONG_COMMENT } from '../constants';
import './styles.scss';

interface Props {
  userAvatar: string;
  newCommentText: string;
  loading: boolean;
  hasCommentText: boolean;
  error: string;
  mentions: any;
  onSubmit(): void;
  onEnterFunc(): void;
  setCommentState(): void;
  fetchMentions(): void;
}

const CommentFormView = ({
  onSubmit,
  onEnterFunc,
  setCommentState,
  userAvatar,
  newCommentText,
  loading,
  hasCommentText,
  error,
  mentions,
  fetchMentions
}: Props) => (
  <div className="add-new-comment-wrapper">
    <form onSubmit={onSubmit}>
      <div className="add-new-comment">
        <img src={userAvatar} alt="" className="user-img" />
        <div className="add-comment-editor-wrapper">
          <Editor
            editorState={newCommentText}
            setEditorState={setCommentState}
            fieldClassName="add-comment-editor"
            placeholder="Write comment..."
            isToolbar={false}
            onEnterFunc={onEnterFunc}
            mentions={mentions}
            fetchMentions={fetchMentions}
          />
        </div>
        <div className="btn-box">
          {loading ? (
            <Loader width="20px" />
          ) : (
            <button
              type="submit"
              className="btn-send-comment"
              disabled={!hasCommentText}
            >
              <SendArrow />
            </button>
          )}
        </div>
      </div>
    </form>

    <div className="add-comment-additional-info">
      <div className="add-comment-error-box">
        {error && error === LONG_COMMENT && (
          <div className="error">
            Comment can not be longer than {COMMENT_MAX_LENGTH} symbols
          </div>
        )}
      </div>

      {hasCommentText && <TextStylingInfo />}
    </div>
  </div>
);

export default CommentFormView;
