import React, {Component} from 'react'
import PropTypes from 'prop-types';
import './CardPopup.scss'

class CardPopup extends Component {
    static propTypes = {
        showPopup: PropTypes.bool.isRequired    
    };
    render() {
        return (
            <div className={'CardPopup ' + (this.props.showPopup ? 'animate' : '')}>New card created</div>
        )
    }
}

export default CardPopup;

