import React from 'react';
import { XIcon } from '../../../UI/Icons';
import { IAction } from '../../CreatePost.types';
import Item from '../Item';
import PlusBtn from '../PlusBtn';
import { CreatingActions } from './CreatingActions';
// @ts-ignore
import styles from './proposedAction.module.scss';

interface Props {
  actions: IAction[];
  isActionBtnDisabled: boolean;
  onAddAction(action: IAction): void;
  onRemoveAction(action: IAction): void;
}

const ProposedActions = ({
  actions,
  isActionBtnDisabled,
  onAddAction,
  onRemoveAction
}: Props) => (
  <div>
    <Item
      label="Proposed Actions:"
      content={
        <div className={styles.actionsBox} title="No more than 10">
          {actions.map((item: IAction, index) => (
            <div key={index} className={styles.action}>
              {item.label}
              <button type="button" onClick={() => onRemoveAction(item)}>
                <XIcon />
              </button>
            </div>
          ))}
        </div>
      }
      button={
        <CreatingActions
          isActionBtnDisabled={isActionBtnDisabled}
          onAddAction={onAddAction}
          toggleButton={(buttonProps: any) => <PlusBtn {...buttonProps} />}
          position="left"
        />
      }
    />
  </div>
);

export { ProposedActions };
