import React, { Component } from 'react';
import Favico from 'react-favico-js';
import { Animation, Position } from 'react-favico-js/dist/types';
import { notificationsQuery, notificationsSubscription } from '../../graphql';
import { FeedApi } from '../../services';
import { NotificationInfo } from './NotificationInfo';
import { NotificationList } from './NotificationList';
import { NotificationObject, NotificationVerb } from './notificationTypes';
import { withNotifications } from './withNotifications';

interface IUser {
  login: string;
  avatar?: string;
}

interface INotification {
  id: string;
  seen: boolean;
  seenAt: string;
  createdAt: string;
  createdBy: IUser;
  group: {
    name: string;
  };
}

interface Props {
  workspaceId: string;
  notifications: any;
  notificationsMore: (v: any) => any;
  notificationsSubscribe: (v: any) => any;
  notificationsLoading: boolean;
  mutate: (v: any) => any;
  notificationsCount: number;
  notificationsCountRefetch: () => void;
  isOnline: boolean;
  notificationsRefetch: () => void;
}

interface PageInfo {
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  startCursor?: string;
  endCursor?: string;
}

interface State {
  notifications: INotification[];
  pageInfo: PageInfo;
  notificationsCount: number;
  isDropdownVisible: boolean;
}

class Notifications extends Component<Props, State, any> {
  public state = {
    notifications: [],
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      endCursor: '',
      startCursor: ''
    },
    notificationsCount: 0,
    isDropdownVisible: false
  };
  public wrapperRef: any;

  constructor(props: Props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  public componentDidMount() {
    const { notifications } = this.props;

    if (notifications && notifications.edges) {
      this.setState({
        notifications: notifications.edges,
        pageInfo: notifications.pageInfo
      });
    }

    this.subscribeToNotifications();
    document.addEventListener('mousedown', this.onClickOutside);
  }

  public componentWillReceiveProps(nextProps: Readonly<Props>): void {
    const { notificationsCount } = this.state;
    if (nextProps.notifications && nextProps.notifications.edges) {
      this.setState({
        notifications: nextProps.notifications.edges,
        pageInfo: nextProps.notifications.pageInfo
      });
    }

    if (notificationsCount !== nextProps.notificationsCount) {
      this.setState({ notificationsCount: nextProps.notificationsCount });
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const { isOnline, notificationsRefetch } = this.props;

    if (isOnline && prevProps.isOnline !== isOnline) {
      notificationsRefetch();
    }
  }

  public subscribeToNotifications = () => {
    const {
      workspaceId,
      notificationsSubscribe,
      notificationsCountRefetch
    } = this.props;

    notificationsSubscribe({
      document: notificationsSubscription,
      variables: { workspaceId },
      updateQuery: (prev: any, { subscriptionData }: any) => {
        if (!subscriptionData && !subscriptionData.data) {
          return prev;
        }

        notificationsCountRefetch();
        return {
          ...prev,
          notifications: {
            ...prev.notifications,
            edges: [
              {
                node: { seenAt: null, ...subscriptionData.data.notifications },
                cursor: '',
                __typename: 'NotificationEdge'
              },
              ...prev.notifications.edges
            ]
          }
        };
      }
    });
  };

  public toggleNotificationDropdown = () => {
    const isDropdownVisible = !this.state.isDropdownVisible;
    this.setState({ isDropdownVisible });
  };

  public onMarkAsRedAll = () => {
    this.markAsSeen({ allAsSeen: true });
  };

  public onNotificationClick = async (node: any) => {
    if (!node.seen) {
      this.markAsSeen({ ids: [node.id] });
    }

    this.toggleNotificationDropdown();

    const {
      verb,
      notificationObject: { __typename }
    } = node;

    switch (verb) {
      case NotificationVerb.ADD:
        if (__typename === NotificationObject.GROUP) {
          FeedApi.fetchFeedByGroup(node.notificationObject.groupId, true);
        }
        if (__typename === NotificationObject.COMMENT_THREAD) {
          FeedApi.openPost(node.notificationObject);
        }
        break;
      case NotificationVerb.POST:
      case NotificationVerb.MENTION:
      case NotificationVerb.COMMENT:
        return FeedApi.openPost(node.notificationObject);
      default:
        break;
    }
  };

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  public onClickOutside = (event: any) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.isDropdownVisible
    ) {
      this.toggleNotificationDropdown();
    }
  };

  public render() {
    const {
      notifications,
      isDropdownVisible,
      notificationsCount,
      pageInfo
    } = this.state;
    const { notificationsLoading } = this.props;
    const hasNextPage = pageInfo ? pageInfo.hasNextPage : false;

    return (
      <div ref={this.wrapperRef}>
        <Favico
          counter={notificationsCount}
          position={Position.Up}
          animation={Animation.PopFade}
        />
        <NotificationInfo
          isDropdownVisible={this.state.isDropdownVisible}
          onToggleNotificationDropdown={this.toggleNotificationDropdown}
          notSeenNotificationAmount={notificationsCount}
        />
        {isDropdownVisible && (
          <NotificationList
            notifications={notifications}
            notSeenNotificationAmount={notificationsCount}
            onNotificationClick={this.onNotificationClick}
            onMarkAsRedAll={this.onMarkAsRedAll}
            hasNextPage={hasNextPage}
            fetchMoreNotifications={this.fetchMoreNotifications}
            notificationsLoading={notificationsLoading}
          />
        )}
      </div>
    );
  }

  private fetchMoreNotifications = () => {
    const { notificationsMore } = this.props;
    const { pageInfo } = this.state;

    notificationsMore({
      variables: {
        after: pageInfo.endCursor
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult && !fetchMoreResult.notifications) {
          return prev;
        }

        return {
          ...fetchMoreResult,
          notifications: {
            ...fetchMoreResult.notifications,
            edges: [
              ...prev.notifications.edges,
              ...fetchMoreResult.notifications.edges
            ]
          }
        };
      }
    });
  };

  private async markAsSeen({
    ids = [],
    allAsSeen = false
  }: {
    ids?: string[];
    allAsSeen?: boolean;
  }) {
    const { workspaceId, mutate, notificationsCountRefetch } = this.props;
    await mutate({
      variables: {
        workspaceId,
        notificationIds: ids,
        markAllAsSeen: allAsSeen
      },
      optimisticResponse: {
        markNotificationAsSeen: {
          __typename: 'Notification',
          error: null
        }
      },
      update: (proxy: any, {}) => {
        const data = proxy.readQuery({
          query: notificationsQuery,
          variables: { workspaceId }
        });
        proxy.writeQuery({
          query: notificationsQuery,
          variables: { workspaceId },
          data: {
            ...data,
            notifications: {
              ...data.notifications,
              edges: data.notifications.edges.map((edge: any) => {
                return allAsSeen || ids.indexOf(edge.node.id) !== -1
                  ? {
                      ...edge,
                      node: {
                        ...edge.node,
                        seen: true
                      }
                    }
                  : edge;
              })
            }
          }
        });
      }
    });
    notificationsCountRefetch();
  }
}

export default withNotifications(Notifications);
