import React from 'react';
import PropTypes from 'prop-types';
import { compose, withApollo, withQuery } from 'react-apollo';

import { getCurrentUser, getWorkspace } from '../../graphql';
import Loader from '../UI/Loader';
import { RefreshToken as RefreshTokenComponent } from '../helpers';
import logoutFunc from '../helpers/logoutFunc/logoutFunc';
import { TokenStore, EnvService } from '../../services';
import MainPageWithFeedQuery from './MainPageWithFeedQuery';
import Log from '../../Log';
import withWorkspace from './withWorkspace';
import { RootContext } from './RootContext';
import './MainPage.scss';

const MainPage = props => {
  const {
    workspaceLoading,
    workspaceError,
    workspaceData,
    tokenStore,
    userLoading,
    userError,
    userData,
    client
  } = props;

  if (!tokenStore.getRefreshToken()) {
    return logoutFunc();
  }

  if (!tokenStore.getIdentityToken()) {
    return <RefreshTokenComponent tokenStore={tokenStore} />;
  }

  if (workspaceLoading || userLoading) {
    return (
      <div className="main-page-loader">
        <Loader />
      </div>
    );
  }

  if (workspaceError) {
    Log.graphQLError(workspaceError, 'errorWorkspace');
    return <ErrorMessage message="Server error" />;
  }

  if (userError || !workspaceData) {
    window.location.assign('/workspaces');

    return null;
  }

  Log.info(workspaceData);
  Log.info(userData);

  if (userData && !EnvService.isLocal) {
    window.heap.identify(userData.id);
    window.heap.addUserProperties({
      name: userData.name
    });
  }

  client.cache.writeData({
    data: {
      workspaceId: workspaceData.id,
      userId: userData.id
    }
  });

  return (
    <RootContext.Provider value={{ workspace: workspaceData, user: userData }}>
      <MainPageWithFeedQuery
        currentUser={userData}
        currentWorkspaceId={workspaceData.id}
      />
    </RootContext.Provider>
  );
};

const ErrorMessage = ({ message, classNames = '' }) => (
  <div className={classNames}>
    <br />
    <p>{message}</p>
    <br />
    <button type="button" onClick={logoutFunc}>
      Logout
    </button>
  </div>
);

MainPage.propTypes = {
  workspaceLoading: PropTypes.bool.isRequired,
  workspaceData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  tokenStore: PropTypes.shape({
    getRefreshToken: PropTypes.func.isRequired,
    getIdentityToken: PropTypes.func.isRequired
  })
};

MainPage.defaultProps = {
  tokenStore: TokenStore,
  workspaceData: null
};

export default compose(
  withWorkspace,
  withQuery(getWorkspace, {
    options: ({ workspaceSlug }) => ({
      variables: {
        slug: workspaceSlug
      }
    }),
    props: ({ data }) => ({
      workspaceLoading: data.loading,
      workspaceError: data.error,
      workspaceData: data.workspace
    }),
    skip: ({ workspaceSlug }) => !workspaceSlug
  }),
  withQuery(getCurrentUser, {
    options: ({ workspaceData }) => ({
      variables: {
        workspaceId: workspaceData.id
      }
    }),
    props: ({ data }) => ({
      userLoading: data.loading,
      userError: data.error,
      userData: data.currentUser
    }),
    skip: ({ workspaceData }) => !workspaceData
  }),
  withApollo
)(MainPage);
