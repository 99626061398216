import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, graphql } from 'react-apollo';
import { getCurrentUser, addCommentMutation } from '../../../../graphql';

import { Loader } from '../../../UI';
import Log from '../../../../Log';
import { EditorState, convertToMarkdown } from '../../../UI/Editor/index';
import CommentFormView from './CommentFormView';
import { LONG_COMMENT, COMMENT_MAX_LENGTH } from '../constants';

class CommentForm extends Component {
  static propTypes = {
    threadId: PropTypes.string.isRequired,
    currentUser: PropTypes.shape({
      avatar: PropTypes.string.isRequired
    }),
    currentWorkspaceId: PropTypes.string,
    mentions: PropTypes.array.isRequired
  };

  static defaultProps = {
    currentUser: null,
    currentWorkspaceId: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      newCommentText: EditorState.createEmpty(),
      error: ''
    };
  }

  setCommentState = newCommentText => {
    this.setState({
      newCommentText
    });

    this.validate();
  };

  validate = () => {
    const { newCommentText } = this.state;

    const comment = convertToMarkdown(newCommentText);

    if (comment.length > COMMENT_MAX_LENGTH) {
      this.setState({
        error: LONG_COMMENT
      });

      return false;
    }

    if (!comment.trim()) {
      this.setState({
        error: ''
      });

      return null;
    }

    this.setState({
      error: ''
    });

    return true;
  };

  onSubmit = (e, createCommentOnPost, loading) => {
    if (e) {
      e.preventDefault();
    }

    if (!this.validate()) {
      return null;
    }

    if (loading) {
      return null;
    }

    const { newCommentText } = this.state;
    const { threadId, currentWorkspaceId } = this.props;

    const comment = convertToMarkdown(newCommentText);

    return createCommentOnPost({
      variables: {
        comment,
        workspaceId: currentWorkspaceId,
        commentThreadId: threadId
      }
    })
      .then(() => {
        this.setState({
          newCommentText: EditorState.createEmpty()
        });
      })
      .catch(err => {
        Log.error(`Error while adding comment: ${err}`);
      });
  };

  render() {
    const {
      data: { loading, error: currentUserError, currentUser },
      mentions,
      fetchMentions
    } = this.props;

    if (loading) {
      return (
        <span className="loader">
          <Loader width="24px" />
        </span>
      );
    }
    if (currentUserError) {
      return <span>Error</span>;
    }

    const { newCommentText, error } = this.state;

    return (
      <React.Fragment>
        <Mutation mutation={addCommentMutation}>
          {(createCommentOnPost, data) => (
            <div>
              <CommentFormView
                onSubmit={e =>
                  this.onSubmit(e, createCommentOnPost, data.loading)
                }
                onEnterFunc={() =>
                  this.onSubmit(null, createCommentOnPost, data.loading)
                }
                userAvatar={currentUser.avatar}
                newCommentText={newCommentText}
                setCommentState={this.setCommentState}
                loading={data.loading}
                hasCommentText={!!convertToMarkdown(newCommentText).trim()}
                error={error}
                mentions={mentions}
                fetchMentions={fetchMentions}
              />
            </div>
          )}
        </Mutation>
      </React.Fragment>
    );
  }
}

const withCurrentUser = graphql(getCurrentUser, {
  options: props => {
    return {
      variables: {
        workspaceId: props.currentWorkspaceId
      }
    };
  }
});
export default withCurrentUser(CommentForm);
