import React from 'react';
import { JoinGroup } from '../JoinGroup';
import Modal from '../UI/Modal/Modal';
// @ts-ignore
import styles from './joinGroupModal.module.scss';

interface Props {
  group: {
    id: string;
    avatar: string;
    name: string;
  };
}

const JoinGroupModal = ({ group }: Props) => (
  <Modal
    isModalOpen={true}
    contentClassName={styles.content}
    overlayClassName={styles.overlay}
  >
    <div className={styles.box}>
      <img src={group.avatar} alt="" className={styles.avatar} />
      <span className={styles.name}>{group.name}</span>
      <JoinGroup groupId={group.id} />
    </div>
  </Modal>
);

export { JoinGroupModal };
