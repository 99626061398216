import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import styles from './buttonPrimary.module.scss';

interface Props {
  children: any;
  type?: 'button' | 'submit';
  disabled?: boolean;
  buttonStyle?: 'blue' | 'yellow';
  size?: 'md' | 'lg';
  onClick?(): void;
}

const ButtonPrimary = ({
  children,
  type = 'button',
  disabled = false,
  buttonStyle = 'yellow',
  size = 'lg',
  onClick = () => {}
}: Props) => {
  const className = classNames(styles.btn, styles[buttonStyle], styles[size]);

  return (
    <button
      type={type}
      disabled={disabled}
      className={className}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export { ButtonPrimary };
