import React from 'react';
import { Link } from 'react-router-dom';
import { logoutFunc } from '../helpers';
import { WorkspaceItem } from './WorkspaceItem';
// @ts-ignore
import styles from './workspaces.module.scss';
import { IWorkspace } from './Workspaces.types';

interface Props {
  workspaces: IWorkspace[];
  onSelectWorkspace(w: IWorkspace): void;
}

const WorkspacesView = ({ workspaces, onSelectWorkspace }: Props) => (
  <React.Fragment>
    <div className={styles.box}>
      <p className={styles.title}>Your workspaces</p>
      {workspaces.length > 0 ? (
        <div className={styles.list}>
          {workspaces.map((workspace: any) => (
            <WorkspaceItem
              key={workspace.id}
              workspace={workspace}
              onSelectWorkspace={onSelectWorkspace}
            />
          ))}
        </div>
      ) : (
        <div className={styles.emptyList}>
          You don't have a workspace.
          <br />
          <Link to={'/create-workspace'}>Create workspace</Link>
        </div>
      )}
    </div>

    <div className={styles.logoutBox}>
      <button type="button" className={styles.btnLogout} onClick={logoutFunc}>
        Sign in to another Buj account
      </button>
    </div>
  </React.Fragment>
);

export { WorkspacesView };
