import {
  filtersDefaults,
  IFilters,
  ILoadingState,
  INetworkState,
  IPostView,
  IWakeUpState,
  loadingStateDefaults,
  networkStateDefaults,
  postViewDefaults,
  wakeUpStateDefaults
} from './operations';

interface LocalState
  extends IFilters,
    INetworkState,
    IPostView,
    ILoadingState,
    IWakeUpState {}

export const defaults: LocalState = {
  ...loadingStateDefaults,
  ...postViewDefaults,
  ...filtersDefaults,
  ...networkStateDefaults,
  ...wakeUpStateDefaults
};
